import React, { useEffect } from 'react'
import '../../style/scholarship_bursary_2022.css'

import AOS from 'aos'
import 'aos/dist/aos.css'

import PageTop from '../../components/pageTop'
import Header from '../../components/header.js'
import Footer from '../../components/footer'

import ScholarshipBursaryImg from '../../assets/img/scholarship_bursary.jpg'
import SBBackPackImg from '../../assets/img/scholarship.png'
import EducationSteps from '../../assets/img/education-steps.png'
import BookImg from '../../assets/img/book.png'

import capImg from '../../assets/img/cap.png'

export default function Scholarship_Bursary(){

    useEffect(() => {
        AOS.init({ duration: 1000 });
        window.scrollTo(0, 0);
    }, []);

   return(
       <div class="promotions">
          <Header class="promotions"/>
          <div class="promo">
              {/* <div style={{width: 400, height: 400}}></div> */}
              <img data-aos="fade-up" class="mainImage" src={EducationSteps}/>
              <label data-aos="fade-up" class="h1">Scholarships & Bursaries</label><br/>
              {/* <img data-aos="fade-up" class="mainImage" src={BookImg}/> */}

              <span data-aos="fade-up" class="span" style={{textAlign: 'left', fontSize: 30, textTransform: 'none', textAlign: 'center'}}>Passionately Involved in Youth Development & Education</span><br/>
              <span data-aos="fade-up" class="span question-line"><span style={{textTransform: 'capitalize'}}>How</span> can we finance your next step?</span><br/><br/>
            
              <span class="span" data-aos="fade-left" style={{color: 'white', fontSize: 40}}>Applicant Eligibility:</span>
              <ol class="rules">
                  <li data-aos="fade-left" style={{fontSize: 25}}>Applicant must have an active Pennysaver® account with us.</li>
                  <li data-aos="fade-left" style={{fontSize: 25}}>Applicants must be year one (1) school entrants.</li>
                  <li data-aos="fade-left" style={{fontSize: 25}}>All scholarship/bursary requests must be made on the relevant form.</li>
                  <li data-aos="fade-left" style={{fontSize: 25}}>All applicants must meet ALL stipulated requirements.</li>
                  <li data-aos="fade-left" style={{fontSize: 25}}>All successful applicants will be required to sign a contract which further outlines the terms and conditions for continuance of the scholarship.</li>
              </ol>

              <span data-aos="fade-up" class="span block">
                <span class="span" style={{color: 'red'}}>Application Deadlines</span><br/>
                <span class="span">Secondary: August 12th</span><br/>
                <span class="span">Tertiary/ A-Level: September 15th</span><br/>
              </span><br/><br/><br/>

              <span class="span instructions">Application should be sent to <a style={{textTransform: 'none', color: '#ffe800', textDecoration: 'none'}} href="mailto:promo@svcbl.com">promo@svcbl.com</a></span>
              <br/><br/><br/>

              <img data-aos="fade-up" style={{marginTop: "-40px"}} class="bookImage" src={BookImg}/>
              <br/>

              {/* <img class="capImg" data-aos="fade-up" data-aos-duration="3000" src={capImg}/> */}
              <br/><br/>
              <a href="https://svcbl.com/pdf/SVCB-Scholarship-Bursary-Form-2021-2022.docx" class="link" data-aos="fade-left">Download Application</a><br/><br/>
              <span data-aos="fade-up" class="span" style={{color: '#33ccff', textTransform: 'uppercase'}}>The Penny Bank and so much more</span>
              <span data-aos="fade-up" class="span" style={{color: '#fff', textTransform: 'uppercase', fontSize: '20px'}}>SVCBL Terms and Conditions Apply</span>
          </div>
          <Footer/>
       </div>
   )
}