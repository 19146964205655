import React, { useEffect } from 'react';
import '../style/services.css';

import AOS from 'aos';
import 'aos/dist/aos.css';

import Button from '@material-ui/core/Button';

import Note from '../components/note.js';
import Header from '../components/header.js';
import Footer from '../components/footer';

import Slider from '../components/about_slider';

import cardImg from '../assets/card.png';

import img1 from '../assets/slider/image1.jpeg';

import infoIcon from '../assets/icons/info.svg';

import PageTop from '../components/pageTop';

import PennySavers from '../assets/img/penny_savers.jpg';

import RegularSavings from '../assets/img/regular_savings.jpg';

import FixedDeposit from '../assets/img/fixed_deposit.jpg';


import { Link } from 'react-router-dom';

export default function Savings(){
    
    useEffect(() => {
        AOS.init({ duration: 1000 });
        window.scrollTo(0, 0);
        const hash = window.location.hash.substr(1);
        try{
            const yOffset = -120; 
            const element = document.getElementById(hash);
            const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;

            window.scrollTo({top: y, behavior: 'smooth'});
        }
        catch(e){}

     }, []);

    return(
        <div>
            {/* <Note/> */}
            <Header/>
            <PageTop/>
            <div className="page_title">
               <label className='fancy'>Savings</label>
            </div>
            <div class="service_body" style={{marginTop: '-150px'}}>
            <div class="service" id="penny">
                   <div data-aos="fade-right" class="image">
                       <img src={PennySavers}/>
                   </div>
                   <div data-aos="fade-left" class="content">
                        <div class="icon">
                             <img src={infoIcon}/>
                        </div><br/>
                        <label class="label">Penny Savers</label>

                        <p>
                            Designed to meet the needs of junior savers 0 – 17 years, this product was launched on December 16th 2010. This service offers children the opportunity to accumulate their own wealth.  Through the provision of tools like a piggy bank we encourage the youngsters to save every penny then deposit to their account.
                        </p>
                        <p>
                            Remember they are never too young to learn the value of money or the need to exercise discipline in the way it is spent. A Ten Dollar gift will be given when each account is opened and accounts cannot be closed within 2 years. Balance must not at any time fall below $10.00.
                        </p>
                        <b>Requirements for child</b>
                        <ul>
                            <li>Child’s Birth Certificate</li>
                        </ul>
                        <b>General Requirements:</b>
                        <ul>
                            <li>Two valid pieces of identification (e.g. National ID Card, Passport or Drivers License)</li>
                            <li>Proof of Address i.e. a utility bill, a credit card Statement, an existing Bank account statement from another financial institution, or hire purchase receipt bearing the address (These must not be in excess of 2 months old)</li>
                            <li>Proof of Income / Job Letter (must not be in excess of 2 months old)</li>
                        </ul>
                        <p>
                            In addition to the above, overseas customers are required to provide three (3) copies of photo identification. All documents from overseas customers must be notarized.
                        </p><br/>
                        <Link data-aos="zoom-in-left" to="/apply/savings" className="link">
                            Get Started
                        </Link>
                   </div>
                </div>
               <div class="service" id="regular">
                   <div data-aos="fade-right" class="content">
                       <div class="icon">
                            <img src={infoIcon}/>
                        </div><br/>
                        <label class="label">Regular Savings</label>

                        <p>
                            The St. Vincent Co-operative Bank Limited offers regular savings accounts at competitive rates. We invite you to open a savings account with us and experience the best customer service available. Our friendly, efficient staff is always ready to serve you, our valued customers. A savings account is the beginning of a long relationship with SVCB where you’re treated like family.
                        </p>
                        <b>General Requirements:</b>
                        <ul>
                            <li>Two valid pieces of identification (e.g. National ID Card, Passport or Drivers License)</li>
                            <li>Proof of Address i.e. a utility bill, a credit card Statement, an existing Bank account statement from another financial institution, or hire purchase receipt bearing the address (These must not be in excess of 2 months old)</li>
                            <li>Constitutional Documents (For Clubs, Companies & Organizations)</li>
                            <li>Proof of Income / Job Letter (must not be in excess of 2 months old)</li>
                        </ul>
                        <p>
                        In addition to the above, overseas customers are required to provide three (3) copies of photo identification. All documents from overseas customers must be notarized.
                        </p>
                        <br/>
                        <Link data-aos="zoom-in-left" to="/apply/savings" className="link">
                            Get Started
                        </Link>
                   </div>
                   <div data-aos="fade-left" class="image">
                       <img src={RegularSavings}/>
                   </div> 
               </div>
 
               <div class="service left" id="fixed">
                   <div data-aos="fade-right" class="image">
                       <img src={FixedDeposit}/>
                   </div>
                   <div data-aos="fade-left" class="content">
                        <div class="icon">
                            <img src={infoIcon}/>
                        </div><br/>
                        <label class="label">Fixed Deposit</label>

                        <p>
                            The St. Vincent Co-operative Bank Limited offers Fixed Deposits at competitive rates.  Unless otherwise instructed, your deposit is rolled over annually to ensure continued returns on your investment.
                        </p>
                        <b>General Requirements:</b>
                        <ul>
                            <li>Two valid pieces of identification (e.g. National ID Card, Passport or Drivers License)</li>
                            <li>Proof of Address i.e. a utility bill, a credit card Statement, an existing Bank account statement from another financial institution, or hire purchase receipt bearing the address (These must not be in excess of 2 months old)</li>
                            <li>Constitutional Documents (For Clubs, Companies & Organizations)</li>
                            <li>Proof of Income / Job Letter (must not be in excess of 2 months old)</li>
                        </ul>
                        <p>
                            In addition to the above, overseas customers are required to provide three (3) copies of photo identification. All documents from overseas customers must be notarized.
                        </p><br/>
                        <Link data-aos="zoom-in-left" to="/apply/savings" className="link">
                            Get Started
                        </Link>
                   </div>
               </div>

            </div>
            <Footer/>
        </div>
    )
}
