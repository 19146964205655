import '../style/note.css';
import InfoIcon from '@material-ui/icons/Info';
import Button from '@material-ui/core/Button';

function Note(){
    return(
     <div class="note">
        <div class="wrap">
            <InfoIcon/>
            <label><b>Face mask required for entry: </b>The St Vincent Co-operative bank will like to advise customer that...</label>
            <Button color="primary">Read</Button>
        </div>
      </div>
    )
}

export default Note;