import React from 'react';
import '../style/services.css';

import Button from '@material-ui/core/Button';

import Note from '../components/note.js';
import Header from '../components/header.js';
import Footer from '../components/footer';

import Slider from '../components/about_slider';

import cardImg from '../assets/card.png';

import SuccessGif from '../assets/animated/success.gif';

import PageTop from '../components/pageTop';

export default function Success(){
    return(
        <div>
            <Note/>
            <Header/>

            <PageTop/>
            <div className="page_title">
               <label>Application Submitted</label>
            </div>
            <div class="service_body" style={{marginTop: '-150px'}}>
                <div class="service" style={{display: 'flex', alignContent: 'center', justifyContent: 'center'}}>
                    <img src={SuccessGif} style={{width: '200px'}}/><br/>
                    <div class="service" style={{display: 'flex', flexDirection: 'column', alignContent: 'center', justifyContent: 'center', lineHeight: 2}}>
                        <label style={{fontSize: 30, fontWeight: 'bold'}}>You will be contacted within the next 48 hours</label>
                        <span style={{fontSize: 20}}>Call: (784) 456-1894</span>
                        <span style={{fontSize: 20}}>Email: info@svcbl.com</span>
                        <span style={{fontSize: 25}}>For more information</span>
                    </div>
                </div>
                
            </div>
            <Footer/>
        </div>
    )
}
