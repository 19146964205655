import React from 'react';
import '../style/services.css';

import Button from '@material-ui/core/Button';

import Note from '../components/note.js';
import Header from '../components/header.js';
import Footer from '../components/footer';

import Slider from '../components/about_slider';

import cardImg from '../assets/card.png';

export default function NotFound(){
    return(
        <div>
            <Note/>
            <Header/>
            <Slider/>
            <div className="page_title">
               <label>404 Error</label>
             </div>
            <div class="service_body">
               
            </div>
            <Footer/>
        </div>
    )
}
