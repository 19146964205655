import React from 'react';

import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';


import '../../style/about.css';

import Note from '../../components/note.js';
import Header from '../../components/header.js';
import Footer from '../../components/footer';

import PageTop from '../../components/pageTop';

import Slider from '../../components/about_slider';

import Alert from '../../components/alert';

// Images
// import RAImage1 from '../../assets/img/news/renaldo_andrews_feature.jpeg';
// import RAImage2 from '../../assets/img/news/renaldo_andrews_feature2.jpeg';
// import RAImage3 from '../../assets/img/news/renaldo_andrews_feature3.jpeg';

import RAImage1 from '../../assets/img/news/Chato_Fun_Day/CFD143.jpg';
import RAImage2 from '../../assets/img/news/Chato_Fun_Day/CFD169.jpg';
import RAImage3 from '../../assets/img/news/Chato_Fun_Day/CFD214.jpg';
import RAImage4 from '../../assets/img/news/Chato_Fun_Day/CFD222.jpg';

import RAImage5 from '../../assets/img/news/Chato_Fun_Day/CFD229.jpg';
import RAImage6 from '../../assets/img/news/Chato_Fun_Day/CFD299.jpg';
import RAImage7 from '../../assets/img/news/Chato_Fun_Day/CFD334.jpg';


function NewsPage(){
    window.scrollTo(0, 0);

    const images = [RAImage1, RAImage2, RAImage3, RAImage4, RAImage5, RAImage6, RAImage7]

    const imageList = images.map((images) => 
         <div>
            <img src={images} style={{borderRadius: 30}} />
         </div>
    )

    return(
        <div>
            {/* <Note/> */}
            <Alert/>
            <Header/>
            <PageTop/>
            <div className="page_title">
               <label>Article</label>
            </div>
            <div class="about_body" style={{lineHeight: '40px', marginTop: '-200px'}}>

                <Carousel>
                    {
                        imageList
                    }
                </Carousel>

                <div class="">
                     <div>
                        <div class="title">
                            <label>Children Fun Day</label>
                            <div></div>
                        </div>
                        <p>
                             On Sunday April 17th, 2022, children in the community of Chateaubelair were treated to a fun day dubbed “Rising from the ashes.”  This initiative was organized by Mrs. Jean Audain-Hinds, entrepreneur, and former resident of Cheteaubelair and funded by The St. Vincent Co-operative Bank Ltd.                         
                        </p>
                        <p>
                            After the eruption of the La Soufriere Volcano on April 9th, 2021, many families in the Chateaubelair and surrounding areas had to abandon their homes and seek alternative shelter. The children were most affected as this event interrupted their normal way of life and daily routine. Children had to adapt to a new way of survival; online learning in particular was something novel to them, with limited access to internet and little or no access to devices. This left the children frustrated as well as their parents. In April 2021, the families who were displaced due to the eruptions moved back to their respective homes. However, things were not the same for most and for the past 12 months families have been trying to achieve some form of normalcy which were at time difficult for the children.
                        </p>
                        <p>
                            The fun day, dubbed “Rising from the ashes.” was done to offer some form excitement back into the lives of the children. The day was filled with lots of activities that engaged children of all ages. Activities such as face painting, dips, punch board, ring games and bouncing castles saw a visible sigh of relief from parents as they looked to see their children happy faces and heard the laughter again in their voices.  
                        </p>
                        <p>
                            Executive Director of The St. Vincent Co-operative Bank Ltd, Mr. Albert Porter along with other members of staff journeyed to Chateaubelair to assist with this initiative. Mr. Porter expressed satisfaction in the turn out and participation of the children. He added that the Bank likes to lend its support to these activities as it benefits the community as a whole and the Bank through its annual scholarships and bursaries awards is actively supporting the development of the youths of this nation.  
                        </p>
                        <b>End-</b>
                        <p>
                            The St. Vincent Co-operative Bank Ltd thrives on its indigeneity with a vision to develop its talents and to invest in future nation builders. The Bank opened it’s doors on February 1st, 1945 accepting just a penny to open accounts and have continued to accommodate all Vincentians – including the less fortunate in the last 77 years. The SVCBL is embarking on a new chapter towards full commercialization and digitalization of its operation. This will enhance and broaden products and services offered to its customers. In keeping with our move towards embracing technology, SVCBL is now one of two financial institutions who will be offering the ewallet” DCash.” With initiative (Dcash) the bank has adopted two of the seventeen UN sustainable goals, goal nine and goal 15.   
                        </p>

                     </div>         
                </div>

            </div>
            <Footer/>
        </div>
    )
}

export default NewsPage;