import React, { useEffect } from 'react';
import '../style/about.css';

import AOS from 'aos';
import 'aos/dist/aos.css';

import Note from '../components/note.js';
import Header from '../components/header.js';
import Footer from '../components/footer';

import Slider from '../components/about_slider';

import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';

import PageTop from '../components/pageTop';


export default function Contact(){
     useEffect(() => {
        AOS.init({ duration: 1000 });
     }, []);

     return(
         <div>
             {/* <Note/> */}
             <Header/>
             {/* <PageTop/> */}
            
             <div className="page_title spacer">
               <label className="fancy">Contact Us</label>
            </div>
             <div className="about_body" style={{marginTop: '-120px'}}>
               <div className="row">
                   {/* <div data-aos="fade-right">
                     <div className="title">
                        <label>Get In Touch With Us</label>
                        <div></div>
                     </div>
                     <form className="contact-form" noValidate autoComplete="off">
                         <TextField className="input" label="Full Name" name="fname" variant="filled" />
                         <div className="fields">
                            <TextField className="input" label="Email address" name="email" variant="filled" />
                            <TextField className="input" label="Phone number" name="number" variant="filled" />
                         </div>

                         <TextField className="input" label="Subject" name="subject" variant="filled" />

                         <textarea rows="4" cols="50" name="desc" placeholder="Description"></textarea>

                         <Button class="btn" size="large" variant="contained" color="primary">
                           Submit
                        </Button>
                    </form>
                   </div> */}
                   <div data-aos="fade-left" style={{flex: 1}}>
                    
                     <div className="title">
                        <label>Contact Info</label>
                        <div></div>
                     </div>
                     <iframe className="maps" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3885.1524049446225!2d-61.22601268538593!3d13.152784990737146!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8c4751503b365465%3A0x5884a348d53560b6!2sThe%20St.Vincent%20Co-Operative%20Bank!5e0!3m2!1sen!2svc!4v1616944248722!5m2!1sen!2svc"></iframe>
                     <p>
                         St. Vincent Co-operative Bank Ltd.
                         Corner Heritage Square & Bay Street
                         P.O. Box 886, Kingstown <br/>
                         St. Vincent and the Grenadines
                     </p>
                     <ul style={{listStyle: 'none', paddingLeft: 0}}>
                       <li>Tel: 784-456-1894</li>
                       {/* <li>Net Speak: 718 393-5455</li> */}
                       <li>Email: customerservice@svcbl.com</li>
                     </ul>

                     <ul style={{listStyle: 'none', paddingLeft: 0}}>
                       <li>
                           <b>Business Hours:</b>
                        </li>
                       <li>Monday to Friday: 8:00am – 2:30pm</li>
                       <li>Saturday: 8:30am – 11:00am</li>
                     </ul>
                   </div>
               </div>
             </div>
             <Footer/>
         </div>
     )
}