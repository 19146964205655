import { LaptopWindows } from "@material-ui/icons";
import React from "react";

export default function DownloadMobile(){
    let isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;

    let ua = navigator.userAgent.toLowerCase();
    let isAndroid = ua.indexOf("android") > -1; 

    function detectBrowser(){
        if(isIOS){
            window.location.replace("https://apps.apple.com/us/app/svcb/id1514230676");
            console.log("ios");
 
        }else if(isAndroid){
            console.log("android");
            window.location.replace("https://play.google.com/store/apps/details?id=com.mobilearth.svcb.mobibanking&hl=en_US&gl=US");

        }else{
            console.log("Not iOS or Android");
            window.location.replace("https://svcbl.com");

        }
    }

    detectBrowser();

    return(
        <>
        </>
    )
}