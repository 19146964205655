import React, { useState, useEffect } from 'react';
import '../style/application.css';

import Loader from '../components/loader';
import { Alert, AlertTitle } from '@material-ui/lab';

import Button from '@material-ui/core/Button';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';

import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import TextField from '@material-ui/core/TextField';
import FormHelperText from '@material-ui/core/FormHelperText';

import Note from '../components/note.js';
import Header from '../components/header.js';
import Footer from '../components/footer';

import Slider from '../components/about_slider';

import cardImg from '../assets/card.png';

import PageTop from '../components/pageTop';

import { Link } from 'react-router-dom';

export default function ATM(){
    const [loader, setLoader] = useState(false);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    function Data(){
        return React.useState({'value': '', 'error': false, 'helper': ''});
    }

    function post(){
       if(personFirstName.value == "" || personLastName.value == "" || personAccountNumber.value == "" || personEmail.value == "" || personPhone.value == ""){
            personFirstName_handler('check');
            personLastName_handler('check');
            personAccountNumber_handler('check');
            personEmail_handler('check');
            personPhone_handler('check');
            setIsError('block');
            document.getElementById("infoError").scrollIntoView();
       }else{
        //    document.getElementById("ATMForm").submit();
          if(!agreeWithTerms){
            setTermsAgreementError('You must agree with the terms of service');
          }else{
            setLoader(true);
            document.getElementById("ATMForm").submit();
          }
       }
    }

    const [personStatus, setPersonStatus] = Data();
    const personStatus_handler = (val) => {
        const value = val == 'check' ? personStatus.value : val;

        const newPersonalStatus = [...personStatus];

        if(value == ""){ newPersonalStatus = {'value': value, 'error': true, 'helper': 'Please select Marital status'}}
        else { newPersonalStatus = {'value': value, 'error': false, 'helper': ''}} 

        setPersonStatus(newPersonalStatus);
    }

    const [personTitle, setPersonTitle] = Data();
    const personTitle_handler = (val) => {
        const value = val == 'check' ? personTitle.value : val;

        const newPersonalStatus = [...personTitle];

        if(value == ""){ newPersonalStatus = {'value':value, 'error': true, 'helper': 'Please select title'}}
        else { newPersonalStatus = {'value': value, 'error': false, 'helper': ''}} 

        setPersonTitle(newPersonalStatus);
    }

    const [personFirstName, setPersonFirstName] = Data();
    const personFirstName_handler = (val) => {
        const value = val == 'check' ? personFirstName.value : val;
        if(value === ""){ setPersonFirstName({'value':value, 'error': true, 'helper': 'First name is required'})} 
        else { setPersonFirstName({'value': value, 'error': false, 'helper': ''})}
    }

    const [personMiddleName, setPersonMiddleName] = Data();
    const personMiddleName_handler = (val) => {
        const value = val == 'check' ? personMiddleName.value : val;
        if(value === ""){ setPersonMiddleName({'value':value, 'error': true, 'helper': 'Middle name is required'})} 
        else { setPersonMiddleName({'value': value, 'error': false, 'helper': ''})}
    }

    const [personLastName, setPersonLastName] = Data();
    const personLastName_handler = (val) => {
        const value = val == 'check' ? personLastName.value : val;
        if(value === ""){ setPersonLastName({'value':value, 'error': true, 'helper': 'Last name is required'})} 
        else { setPersonLastName({'value': value, 'error': false, 'helper': ''})}
    }


    const [personAccountNumber, setPersonAccountNumber] = Data();
    const personAccountNumber_handler = (val) => {
        const value = val == 'check' ? personAccountNumber.value : val;
        if(value === ""){ setPersonAccountNumber({'value':value, 'error': true, 'helper': 'Account number is required'})} 
        else { setPersonAccountNumber({'value': value, 'error': false, 'helper': ''})}
    }

    const [personId, setPersonId] = Data();
    const personId_handler = (event) => {
        console.log(event.target.files[0]);
        const file = event == 'check' ? personId.value : event.target.files[0];
        if(file === ""){ setPersonId({'value':file, 'error': true, 'helper': 'Identification (ID) is required'})}
        else { setPersonId({'value': file, 'error': false, 'helper': ''}) }
    }

    const [personPhone, setPersonPhone] = Data();
    const personPhone_handler = (val) => {
        const value = val == 'check' ? personPhone.value : val;
        if(value === ""){ setPersonPhone({'value':value, 'error': true, 'helper': 'Phone number is required'})} 
        else { setPersonPhone({'value': value, 'error': false, 'helper': ''})}
    }

    const [personEmail, setPersonEmail] = Data();
    const personEmail_handler = (val) => {
        const value = val == 'check' ? personEmail.value : val;
        if(value === ""){ setPersonEmail({'value':value, 'error': true, 'helper': 'Last name is required'})} 
        else if(!validateEmail(value)){ setPersonEmail({'value':value, 'error': true, 'helper': 'Invalid email address... valid email format example@gmail.com'})} 
        else { setPersonEmail({'value': value, 'error': false, 'helper': ''})}
    }
    
    const [existingAccount, setExistingAccount] = Data();
    const existingAccount_handler = (event) => {
        setExistingAccount({'value': event.target.value})
    }

    const [feedback, setFeedback] = React.useState();


    // ##########################################################################################################################
    // ##########################################################################################################################
    function validateEmail(email) {
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }

    const isNumericInput = (event) => {
        const key = event.keyCode;
        return ((key >= 48 && key <= 57) || // Allow number line
            (key >= 96 && key <= 105) // Allow number pad
        );
    };
    
    const isModifierKey = (event) => {
        const key = event.keyCode;
        return (event.shiftKey === true || key === 35 || key === 36) || // Allow Shift, Home, End
            (key === 8 || key === 9 || key === 13 || key === 46) || // Allow Backspace, Tab, Enter, Delete
            (key > 36 && key < 41) || // Allow left, up, right, down
            (
                // Allow Ctrl/Command + A,C,V,X,Z
                (event.ctrlKey === true || event.metaKey === true) &&
                (key === 65 || key === 67 || key === 86 || key === 88 || key === 90)
            )
    };

    const enforceFormat = (event) => {
        // Input must be of a valid number format or a modifier key, and not longer than ten digits
        if(!isNumericInput(event) && !isModifierKey(event)){
            event.preventDefault();
        }
    };
    
    const formatToPhone = (event) => {
        if(isModifierKey(event)) {return;}
    
        const input = event.target.value.replace(/\D/g,'').substring(0,10); // First ten digits of input only
        const areaCode = input.substring(0,3);
        const middle = input.substring(3,6);
        const last = input.substring(6,10);
    
        if(input.length > 6){event.target.value = `(${areaCode}) ${middle} - ${last}`;}
        else if(input.length > 3){event.target.value = `(${areaCode}) ${middle}`;}
        else if(input.length > 0){event.target.value = `(${areaCode}`;}
    };

    const [termsView, setTermsView] = React.useState('none');

    const [isError, setIsError] = React.useState('none');

    const [agreeWithTerms, setAgreeWithTerms] = React.useState();
    const [termsAgreementError, setTermsAgreementError] = React.useState('');

    const agreeWithTerms_handler = (event) => {
        setAgreeWithTerms(event.target.checked);
     }

    const personInfo = () => {
        return(
            <>

            <form id="ATMForm" action="https://svcbl.com/api/ATM.php" method="post" enctype="multipart/form-data">
                <Alert severity="error" id="infoError" style={{display: isError}}>
                    <AlertTitle>Error</AlertTitle>
                    Please correct the following errors 
                </Alert><br/>

                <fieldset class="person">
                    <legend>
                        Account Information
                        ({personFirstName.value} {personMiddleName.value} { personLastName.value})
                    
                    </legend>

                    <div className="row">
                        <TextField 
                            error={personFirstName.error}
                            value={personFirstName.value}
                            label="First Name"
                            name="fname"
                            helperText={personFirstName.helper}
                            onChange={e => {personFirstName_handler(e.target.value)}}
                            variant="filled"
                            className="input"/>
                        
                        <TextField 
                            error={personMiddleName.error}
                            value={personMiddleName.value}
                            label="Middle Name"
                            name="mname"
                            helperText={personMiddleName.helper}
                            onChange={e => {personMiddleName_handler(e.target.value)}}
                            variant="filled"
                            className="input"/>

                        <TextField 
                            error={personLastName.error}
                            value={personLastName.value}
                            label="Last Name"
                            name="lname"
                            helperText={personLastName.helper}
                            onChange={e => {personLastName_handler(e.target.value)}}
                            variant="filled"
                            className="input"/>
                    </div>
                    <div className="row">
                        <TextField 
                            error={personAccountNumber.error}
                            value={personAccountNumber.value}
                            label="Account number"
                            name="account"
                            helperText={personAccountNumber.helper}
                            onChange={e => {personAccountNumber_handler(e.target.value)}}
                            variant="filled"
                            className="input"/>
                    </div>
                    <div className="row">
                        <TextField 
                            error={personEmail.error}
                            value={personEmail.value}
                            label="Email address"
                            name="email"
                            helperText={personEmail.helper}
                            onChange={e => {personEmail_handler(e.target.value)}}
                            variant="filled"
                            className="input"/>

                        <TextField
                            id="outlined-secondary"
                            error={personPhone.error}
                            value={personPhone.value}
                            helperText={personPhone.helper}
                            label="Phone number"
                            name="phone"
                            onChange={e => {personPhone_handler(e.target.value)}}
                            onKeyDown={enforceFormat}
                            onKeyUp={formatToPhone}
                            variant="filled"
                            className="input"/>
                    </div>
                </fieldset>
                <br/>
                <fieldset class="person">
                    <legend>
                        Identification - National ID, Passport or Drivers License (Optional) 
                    </legend>
                    {/* <label style={{fontSize: '20px'}} className="label">
                        For NON residents ONLY 
                    </label><br/> */}
                    <TextField
                            error={personId.error}
                            // value={personId.value}
                            id="fileID"
                            helperText={personId.helper}
                            label="Upload copy of ID"
                            onChange={personId_handler}
                            variant="filled"
                            type="file"
                            accept="image/png, image/jpeg"
                            className="input"
                            name="ID"
                            InputLabelProps={{
                                shrink: true,
                            }}/>

                </fieldset>
                <br/>
                {
                   terms()
                }
                <div class="action">
                    <Button size="large" variant="contained" className="nextBtn"  onClick={() => post()}>
                            Next
                            <KeyboardArrowRight /> 
                    </Button>
                </div>
            </form>
          </>
        )
    }

    function terms(){
        return(
            <>
              <fieldset class="person">
                <legend>
                     Terms and conditions                
                </legend>
                <b>
                    In consideration of the issuance and use of the ATM Card and all services provided by The St. Vincent Cooperative Bank Limited, hereinafter called “the Bank”, the customer agrees to be bound by the following
                    terms and conditions:
                </b>
                <ol>
                    <li>This ATM Card is supplied free of cost.</li>
                    <li>
                        The customer agrees to collect his/her ATM Card within sixty days of submitting the application form.
                        Failure to do so will result in the ATM Card being destroyed and the customer’s account being debited the cost
                        of embossing said ATM Card. Any future attempt to acquire an ATM card will be deemed a replacement and
                        a cost of $20.00 charged upon collection.
                    </li>
                    <li>
                        The ATM Card must be signed promptly upon receipt by the customer and can only be used after it has been
                        signed. The customer is responsible for ensuring the safety of the ATM Card and will not disclose the PIN
                        number to any person who is not its Authorised user.
                    </li>
                    <li>
                        The Bank is authorized to charge the customer’s account for any and all funds obtained by the customer
                        through the use of the ATM Card.
                    </li>
                    <li>
                        The ATM Card cannot be used to transfer money in or out of the account until the funds have been validated
                        by the Bank.
                    </li>
                    <li>
                        The ATM Card is the property of the Bank and the Bank can at any time terminate its use without notice. The
                        card is not transferable. The customer agrees to surrender the card upon its demand from the Bank.
                    </li>
                    <li>
                        The ATM Card cannot be used for illegal transaction(s) in contravention with the <b>Proceeds of Crime Act 2013 </b> 
                         and the <b>Anti-money Laundering and Terrorist Financing Regulation 2014.</b>
                    </li>
                    <li>
                        The customer agrees to notify the Bank immediately if the ATM Card is lost or stolen. If a new ATM Card is
                        issued the customer agrees to pay a replacement fee for such replacement card and that their account is
                        debited for such fee.
                    </li>
                    <li>
                        The customer agrees to pay all legal expenses if for any reason the Bank has to use legal means to collect
                        amounts that may become outstanding under this ATM Card Holder Agreement.
                    </li>
                    <li>
                        If the account is a joint account, two (2) ATM Cards may be issued upon the signing of the agreement by both
                        parties. Each account holder may exercise all rights under this agreement individually and shall be jointly and
                        severally responsible for any obligation incurred by such exercise. Each account holder may use the ATM
                        Card(s) and agree to or terminate the agreement or close any such accounts. Each card holder agrees that
                        any action taken by one party will be binding upon each account holder.
                    </li>
                    <li>
                        The St. Vincent Co-operative Bank Limited makes no claims or warranties with respect to the system or any
                        equipment to be used in this process and shall not be liable for any failure or malfunction of same.
                    </li>
                    <li>
                        The Bank reserves the right to make amendments to this agreement at any time and also to make immediate
                        changes in order to maintain or restore security. The Bank will also notify the customer of any changes that
                        will affect the customer’s use of the ATM Card.
                    </li>
                    <li>
                        All correspondence to the customer shall be sent to the address given in the application and the customer
                        agrees to notify the Bank whenever there is a change in same.
                    </li>
                    <li>
                        If an investigation is required from use of a BOSVG machine, this bank charges $80.00 that must be paid
                        before the investigation commences.
                    </li>
                </ol>
                <div class="options">
                    
                     <FormControlLabel 
                        control={<Checkbox checked={agreeWithTerms} color="primary" onChange={agreeWithTerms_handler} />} label="I agree with the terms and services" />
                </div>
                <span id="termsOfServiceError" class="error">{termsAgreementError}</span>
              </fieldset>
            </>
        )
    }

    return(
        <div>
            <Note/>
            <Header/>
            {
                loader
                ? <Loader/>
                : ''
            }
            {/* <PageTop/> */}
            <div className="page_title spacer">
               <label className='fancy'>ATM Application</label>
            </div>
            <div class="application" style={{marginTop: '-150px'}}>
                <div class="content">
                    <label className="label" style={{fontSize: 40}}>
                        Convenience at your finger tips
                    </label><br/>
                    <p>
                        Conduct withdrawal 24/7 at any of 19 ATM locations islandwide 
                    </p><br/>
                    
                    {
                        feedback == "Success" 
                        ? <> 
                             <h1>Request Posted Successfully</h1>
                          </>
                        : <>
                             <label className="question">
                                Do you have a SVCB savings account?
                            </label><br/>

                            <RadioGroup row aria-label="position" name="position" defaultValue={existingAccount.value} onChange={existingAccount_handler}>
                                <FormControlLabel
                                    value="yes"
                                    control={<Radio color="primary" />}
                                    label="Yes"
                                    labelPlacement="end"/>
                                <FormControlLabel 
                                    value="no" 
                                    control={<Radio color="primary" />} 
                                    label="No" />
                            </RadioGroup><br/>

                            {
                                existingAccount.value == "no" 
                                ? <>
                                    <h1>A SVCB account is required to sign up for an ATM card</h1>
                                    <Link to="/apply/savings" className="link">
                                    Savings Application
                                    </Link>
                                </>
                                : existingAccount.value == "yes"
                                ? personInfo() 
                                : <><h1>Please answer question above</h1></>
                            }
                          </>
                    }
                    
                </div>
                <br/><br/>
            </div>
            <Footer/>
        </div>
    )
}
