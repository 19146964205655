import React, { useEffect } from 'react';
import Button from '@material-ui/core/Button';

import '../style/footer.css';
import '../style/wave.css';

import CopyrightIcon from '@material-ui/icons/Copyright';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import whiteLogoImg from '../assets/svcb-logo_white.png';

import fbImg from '../assets/icons/facebook.png';
import instaImg from '../assets/icons/instagram.png';
import youTubeImg from '../assets/icons/youtube.png';
import twImg from '../assets/icons/twitter.png';


import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import { Link }from 'react-router-dom';

export default function Footer(){
    useEffect(() => {
        const hash = window.location.hash.substr(1);
        try{
            if(hash === "financial-statements"){
                setFinancialStatementView(true);
            }else if(hash === "abandoned-properties"){
                setAbandonedPropertiesView(true);
            }else if(hash === "annual-reports"){
                setAGMView(true);
            }else if(hash === "calculator"){
                setOpen(true);
            }
        }
        catch(e){}

     }, []);


    const [open, setOpen] = React.useState(false);
    const [abandonedPropertiesView, setAbandonedPropertiesView] = React.useState(false);
    const [financialStatementView, setFinancialStatementView] = React.useState(false);
    const [AGMView, setAGMView] = React.useState(false);

    const [state, setState] = React.useState({
        total: "",
        downPayment: "",
        interest: 5,
        period: "",
    });

    const [result, setResult] = React.useState({
        monthly: 0,
        repay: 0
    });

    const [error, setError] = React.useState();

    const handleChange = (evt) => {
        const value = evt.target.value;
        setState({
          ...state,
          [evt.target.name]: value
        });
      }
    
    const calculate = () => {
        if(state.total === "" || state.downPayment === "" || state.interest === "" || state.period === ""){
            setError("Please fill out the calculator");
        }else{
            const principal = state.total,
                  interest = parseFloat(state.interest) / 100 / 12,
                  payments = parseFloat(state.period) * 12,
                  x = Math.pow(1 + interest, payments),

                  monthly = (principal * x * interest) / (x - 1),
                  repay = (monthly * 12) * state.period;

            setResult({
                monthly: Number((monthly).toFixed(1)).toLocaleString(),
                repay: Number((repay).toFixed(1)).toLocaleString(),
            });
        }
    }

    const handleClickOpen = () => {
        setOpen(true);
    };

    const abandonedPropertiesView_OpenHandler = () => {
        setAbandonedPropertiesView(true);
    }

    const AGM_OpenHandler = () => {
        setAGMView(true);
    }

    const financialStatementView_OpenHandler = () => {
        setFinancialStatementView(true);
    }

    const handleClose = () => {
        setOpen(false);
    };

    const abandonedPropertiesView_CloseHandler = () => {
        setAbandonedPropertiesView(false);
    }

    const AGM_CloseHandler = () => {
        setAGMView(false);
    }

    const financialStatementView_CloseHandler = () => {
        setFinancialStatementView(false);
    }

    return(
        <>
            <div>
                <div>
                    <svg class="waves" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink"
                    viewBox="0 24 150 28" preserveAspectRatio="none" shape-rendering="auto">
                    <defs>
                        <path fill="rgba(21, 21, 21, .7)" id="gentle-wave" d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z" />
                    </defs>
                        <g class="parallax">
                        <use xlinkHref="#gentle-wave" x="48" y="0" fill="rgba(0,0,0,0.7)" />
                        <use xlinkHref="#gentle-wave" x="48" y="3" fill="rgba(0,0,0,0.5)" />
                        <use xlinkHref="#gentle-wave" x="48" y="5" fill="rgba(0,0,0,0.3)" />
                        <use xlinkHref="#gentle-wave" x="48" y="7" fill="#fff" />
                        </g>
                    </svg>
                </div>
            </div>
            <nav>
              <div class="wrap">
                  <div class="links">
                    <div class="cont">
                        <img src={whiteLogoImg}/>
                        <ul>
                            <li>Corner Bay Street & Heritage Square</li><br/>
                            <li>Phone: 784-456-1894</li>
                            {/* <li>Net Speak: 718-393-5455</li> */}
                            <li>Email: customerservice@svcbl.com</li>
                        </ul>
                    </div>
                    <div class="cont">
                        <div class="title">
                            <label>Services</label>
                            <hr/>
                        </div>
                        <ul>
                            <Link to="/services/loans">
                                <NavigateNextIcon/>
                                Loans
                            </Link>
                            <Link to="/services/savings">
                                <NavigateNextIcon/>
                                Savings
                            </Link>
                            <Link to="/services/atm">
                                <NavigateNextIcon/>
                                ATM Services
                            </Link>
                        </ul>
                    </div>
                    <div class="cont">
                        <div class="title">
                            <label>Information</label>
                            <hr/>
                        </div>
                        <ul>
                            {/* <li onClick={handleClickOpen}>
                                <NavigateNextIcon/>
                                Loan calculator
                            </li> */} 
                            <a href="https://svcbl.com/pdf/svcb-fee-schedule-1-april-2023.pdf" target="_blank">
                                <NavigateNextIcon/>
                                SVCB FEE Schedule
                            </a>
                            <li onClick={abandonedPropertiesView_OpenHandler}>
                                <NavigateNextIcon/>
                                Abandoned Properties
                            </li>
                            <li onClick={financialStatementView_OpenHandler}>
                                <NavigateNextIcon/>
                                Financial Statements
                            </li>
                            <li onClick={AGM_OpenHandler}>
                                <NavigateNextIcon/>
                                Annual Reports
                            </li>
                            <Link to="/fatca">
                                <NavigateNextIcon/>
                                FATCA
                            </Link>
                            <Link to="/guidelines">
                                    <NavigateNextIcon/>
                                    Guidelines
                            </Link>
                            <a href="https://svcbl.com/pdf/SVCB-Privacy-Policy.pdf" target="_blank">
                                <NavigateNextIcon/>
                                Privacy Policy
                            </a>
                            <a href="https://svcbl.com/pdf/Bank-License.pdf" target="_blank">
                                <NavigateNextIcon/>
                                Bank License
                            </a>
                        </ul>
                    </div>
                    <div class="cont">
                        <div class="title">
                            <label>Tools</label>
                            <hr/>
                        </div>
                        <ul>
                            <li onClick={handleClickOpen}>
                                <NavigateNextIcon/>
                                Loan Calculator
                            </li>
                        </ul>
                    </div>
                  </div>
                  <hr/>
                  <div class="social">
                    <a href="https://www.facebook.com/svcooperativebank" target="_blank">
                        <img src={fbImg} />
                    </a>
                    <a href="https://www.instagram.com/svcb.pennybank/" target="_blank">
                        <img src={instaImg} />
                    </a>
                    <a href="https://twitter.com/svcb_" target="_blank">
                        <img src={twImg} />
                    </a>
                    <a href="https://www.youtube.com/channel/UCUjiDVEbaX8AnhrHrjv8h9g" target="_blank">
                        <img src={youTubeImg} />
                    </a>
                  </div>
              </div>
            </nav>
            <footer>
                <CopyrightIcon/>
                <label>The St Vincent Co-operative Bank Ltd - All Rights Reserved</label>
            </footer>
 
            <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">Loan Calculator</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Loan monthly payment calculator
                    </DialogContentText>
                    <TextField
                        name="total"
                        value={state.total}
                        onChange={handleChange}
                        margin="dense" label="Total Amount $" type="number" variant="filled" fullWidth/>
                    
                    <TextField
                        name="downPayment"
                        value={state.downPayment}
                        onChange={handleChange}
                        margin="dense" label="Down Payment $" type="number" variant="filled" fullWidth/>
                     
                    <TextField
                        name="interest"
                        value={state.interest}
                        onChange={handleChange}
                        margin="dense" label="Interest Rate %" type="number" variant="filled" fullWidth/>
                    
                    <TextField
                        name="period"
                        value={state.period}
                        onChange={handleChange}
                        margin="dense" label="Amortization Period (Years)" type="number" variant="filled" fullWidth/>
                    
                    <br/><br/>
                    {
                        result.monthly
                        ? <>
                             <h3>Monthly Payment: {result.monthly}</h3>
                             <h3>Repayment: {result.repay}</h3>
                          </>
                        : ""
                    }
                    <h2 style={{color: 'red'}}>{error}</h2>
                    <Button variant="contained" class="btn" onClick={() => calculate()} color="primary">
                      Calculate
                    </Button>

                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="secondary">
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog open={financialStatementView} onClose={financialStatementView_CloseHandler} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">Financial Statements</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                       Review our Financial Statements from previous years to present.
                    </DialogContentText>
                    
                    <ul class="list">
						<a href="https://svcbl.com/pdf/Financial-Statement/2024.pdf" target="_blank">
                            <li>2024 Audited Financial Statements</li>
                        </a>
                       <a href="https://svcbl.com/pdf/Financial-Statement/2023.pdf" target="_blank">
                            <li>2023 Audited Financial Statements</li>
                        </a>
                        <a href="https://svcbl.com/pdf/Financial-Statement/2022.pdf" target="_blank">
                            <li>2022 Audited Financial Statements</li>
                        </a>
                        <a href="https://svcbl.com/pdf/Financial-Statement/2021.pdf" target="_blank">
                            <li>2021 Audited Financial Statements</li>
                        </a>
                        <a href="https://svcbl.com/pdf/Financial-Statement/2020.pdf" target="_blank">
                            <li>2020 Audited Financial Statements</li>
                        </a>
                        <a href="https://svcbl.com/pdf/Financial-Statement/2019.pdf" target="_blank">
                            <li>2019 Audited Financial Statements</li>
                        </a>
                        <a href="https://svcbl.com/pdf/Financial-Statement/2018.pdf" target="_blank">
                            <li>2018 Audited Financial Statements</li>
                        </a>
                        <a href="https://svcbl.com/pdf/Financial-Statement/2016.pdf" target="_blank">
                            <li>2016 Audited Financial Statements</li>
                        </a>
                        <a href="https://svcbl.com/pdf/Financial-Statement/2015.pdf" target="_blank">
                            <li>2015 Audited Financial Statements</li>
                        </a>
                        <a href="https://svcbl.com/pdf/Financial-Statement/2014.pdf" target="_blank">
                            <li>2014 Audited Financial Statements</li>
                        </a>
                        <a href="https://svcbl.com/pdf/Financial-Statement/2013.pdf" target="_blank">
                            <li>2013 Audited Financial Statements</li>
                        </a>
                        <a href="https://svcbl.com/pdf/Financial-Statement/2012.pdf" target="_blank">
                            <li>2012 Audited Financial Statements</li>
                        </a>
                        <a href="https://svcbl.com/pdf/Financial-Statement/2011.pdf" target="_blank">
                            <li>2011 Audited Financial Statements</li>
                        </a>
                        <a href="https://svcbl.com/pdf/Financial-Statement/2010.pdf" target="_blank">
                            <li>2010 Audited Financial Statements</li>
                        </a>
                    </ul>

                </DialogContent>
                <DialogActions>
                    <Button onClick={financialStatementView_CloseHandler} color="secondary">
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog open={AGMView} onClose={AGM_CloseHandler} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">Annual Reports:</DialogTitle>
                <DialogContent>

                    <ul class="list">
                       <a href="https://svcbl.com/pdf/Annual-Report/2023.pdf" target="_blank">
                            <li>2023 Annual Report</li>
                       </a>
                       <a href="https://svcbl.com/pdf/Annual-Report/2022.pdf" target="_blank">
                            <li>2022 Annual Report</li>
                        </a>
                    </ul>
                
                </DialogContent>
                <DialogActions>
                    <Button onClick={AGM_CloseHandler} color="secondary">
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog open={abandonedPropertiesView} onClose={abandonedPropertiesView_CloseHandler} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">Abandoned properties listing as of:</DialogTitle>
                <DialogContent>

                    <ul class="list">
                        <a href="https://svcbl.com/pdf/Abandoned-Properties/2024.pdf" target="_blank">
                            <li>31st January 2024</li>
                        </a>
                        <a href="https://svcbl.com/pdf/Abandoned-Properties/2023.pdf" target="_blank">
                            <li>31st January 2023</li>
                        </a>
                       <a href="https://svcbl.com/pdf/Abandoned-Properties/2022.pdf" target="_blank">
                            <li>31st January 2022</li>
                        </a>
                        <a href="https://svcbl.com/pdf/Abandoned-Properties/2021.pdf" target="_blank">
                            <li>31st January 2021</li>
                        </a>
                        <a href="https://svcbl.com/pdf/Abandoned-Properties/2020.pdf" target="_blank">
                            <li>31st January 2020</li>
                        </a>
                        <a href="https://svcbl.com/pdf/Abandoned-Properties/2019.pdf" target="_blank">
                            <li>31st January 2019</li>
                        </a>
                        <a href="https://svcbl.com/pdf/Abandoned-Properties/2017.pdf" target="_blank">
                            <li>1st September 2017</li>
                        </a>
                    </ul>
                
                </DialogContent>
                <DialogActions>
                    <Button onClick={abandonedPropertiesView_CloseHandler} color="secondary">
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}