import React from 'react';
import '../style/pageTop.css';

export default function PageTop(){
    return(
        <>
           <div class="pageTop">
               <div class="container">
               </div>
           </div>
        </>
    )
}