import React from 'react';

import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';


import '../../style/about.css';

import Note from '../../components/note.js';
import Header from '../../components/header.js';
import Footer from '../../components/footer';

import PageTop from '../../components/pageTop';

import Slider from '../../components/about_slider';

import Alert from '../../components/alert';

// Images
import RAImage1 from '../../assets/img/news/Playaz_Youth_Academy/1.jpg';
import RAImage2 from '../../assets/img/news/Playaz_Youth_Academy/2.jpg';
import RAImage3 from '../../assets/img/news/Playaz_Youth_Academy/3.jpg';
import RAImage4 from '../../assets/img/news/Playaz_Youth_Academy/4.jpg';
import RAImage5 from '../../assets/img/news/Playaz_Youth_Academy/5.jpg';
import RAImage6 from '../../assets/img/news/Playaz_Youth_Academy/6.jpg';
import RAImage7 from '../../assets/img/news/Playaz_Youth_Academy/7.jpg';
import RAImage8 from '../../assets/img/news/Playaz_Youth_Academy/8.jpg';
import RAImage9 from '../../assets/img/news/Playaz_Youth_Academy/9.jpg';
import RAImage10 from '../../assets/img/news/Playaz_Youth_Academy/10.jpg';


function NewsPage(){
    window.scrollTo(0, 0);

    return(
        <div>
            {/* <Note/> */}
            <Alert/>
            <Header/>
            <PageTop/>
            <div className="page_title">
               <label>News</label>
            </div>
            <div class="about_body" style={{lineHeight: '40px', marginTop: '-200px'}}>

                <Carousel>
                    <div>
                        <img src={RAImage1} />
                    </div>
                    <div>
                        <img src={RAImage2} />
                    </div>
                    <div>
                        <img src={RAImage3} />
                    </div>
                    <div>
                        <img src={RAImage4} />
                    </div>
                    <div>
                        <img src={RAImage5} />
                    </div>
                    <div>
                        <img src={RAImage6} />
                    </div>
                    <div>
                        <img src={RAImage7} />
                    </div>
                    <div>
                        <img src={RAImage8} />
                    </div>
                    <div>
                        <img src={RAImage9} />
                    </div>
                    <div>
                        <img src={RAImage10} />
                    </div>
                    
                </Carousel>
                <div class="">
                     <div>
                        <div class="title">
                            <label>SVCBL team up with Playaz Youth Academy</label>
                            <div></div>
                        </div>
                        <p>
                        On Monday July 25th The St. Vincent Co-operative Bank Ltd teamed up with Playaz Youth Academy (PYA) in hosting their annual Basketball Summer Camp for youths throughout St. Vincent and the Grenadines.

                        </p>
                        <p>
                        PYA is a non-profit registered organization which aims to positively influence children and teenagers through basketball. It teaches young people between ages 7 to 23 years the fundamentals of the game, strengthen those who already have some knowledge of the game, provides mentorship, sportsmanship, teamwork, and empower the youths.

                        </p>

                        <p>
                        Playaz youth Academy is the brainchild of Mr.Orlando Fergusson. Mr. Fergusson hails from the small community of Largo Height. Orlando played basketball for St. Vincent National Basketball Team as a shooting guard/Power Forward from 1999 to 2014. He also played Basketball for the University of Victoria (Vikes) in British Columbia 2001-2002; there he won the Canada West Rookie of the year and had the opportunity to play in the Jones Cup Championship in Taiwan.

                        </p>

                        <p>
                        From an early age it was his dream to have his own Basketball Club “Playaz”, therefore he started a youth basketball club called “Crushers” in his community. Later he formed the Playaz Youth Academy (PYA) as he saw the need to pass on the knowledge and passion, he had for the sport his aim was to get the youths to become involved and to challenge them in a positive way and hopefully through the sport their impact to society would be a positive one.

                        </p>

                        <p>
                        The COVID-19 Pandemic and the aftermath of an explosive volcanic eruption prevented Playaz Youth Academy from executing their 2020 and 2021 basketball camp. The 2022 camp saw a significant volume of participants

                        </p>

                        <p>
                        Executive Director of The St. Vincent Co-operative Bank Ltd, Mr. Albert Porter expressed that the Bank likes to lend its support to organizations such as Playaz Youth Academy as it benefits the community on a whole. In addition, it is the mission of the Bank to have a meaningful impact on the community and one initiative is through an investment in our youths as they play a pivotal role in our society.

                        </p>

                     </div>         
                </div>

            </div>
            <Footer/>
        </div>
    )
}

export default NewsPage;