import React, { useEffect } from 'react';
import '../../style/feel_the_magic.css';

import AOS from 'aos';
import 'aos/dist/aos.css';

import PageTop from '../../components/pageTop';
import Header from '../../components/header.js';
import Footer from '../../components/footer';
import FeelTheMagic_Word from '../../assets/promo/feel-the-magic.png';
import Christmasbell_Left from '../../assets/promo/christmas-bell-left.png';
import Santa_Blowing from '../../assets/promo/Santa-blowing.png';

import FeelTheMagic_Scroll from '../../assets/promo/christmas-scroll.png';
import FeelTheMagic_Scroll_closed from '../../assets/promo/christmas-scroll-closed.png';

import bed from '../../assets/promo/bed.png';
import chair from '../../assets/promo/chair.png';
import refrigerator from '../../assets/promo/refrigerator.png';


import iosImg from '../../assets/download_ios.png';
import androidImg from '../../assets/download_android.png';


export default function FeelTheMagic(){

    useEffect(() => {
        AOS.init({ duration: 1000 });
        window.scrollTo(0, 0);
     }, []);

    return(
        <>
        
        <div class="FeelTheMagic-Cont">
           <Header class="FTM"/>
           <img class="FTM-ChristmasBell-Left" src={Christmasbell_Left}/>
           {/* <img class="FTM-Santa-Blowing" data-aos="fade-up" src={Santa_Blowing}/> */}

           <img class="FTM-MainImage" data-aos="fade-up" src={FeelTheMagic_Word}/>
           <div class="FTM-Section">
                <img class="FTM-Santa-Blowing" data-aos="fade-up" src={Santa_Blowing}/>

                <img class="FTM-Scroll" data-aos="zoom-in-up" src={FeelTheMagic_Scroll}/>
           </div>

           {/* <img class="FTM-Scroll" data-aos="zoom-in-up" src={FeelTheMagic_Scroll}/> */}
           {/* <div class="FTM-Btns">
                <a  href="https://play.google.com/store/apps/details?id=com.mobilearth.svcb.mobibanking" target="_blank" color="primary">
                    <img src={androidImg} />
                </a>
                <a  href="https://apps.apple.com/vc/app/svcb/id1514230676" target="_blank" color="primary">
                    <img src={iosImg} />
                </a>
            </div> */}

           {/* <div class="FTM-appliances">
              <img class="FTM-applicance" src={refrigerator}/>
              <img class="FTM-applicance" src={chair}/>
              <img class="FTM-applicance" src={bed}/>
           </div> */}

           {/* <div class="FTM-Santa-Blowing" data-aos="fade-right" ></div> */}
           {/* <div class="footer">
               <div class="wrap">
                  <h4>PROMOTION DURATION: OCTOBER 18TH 2021 - DECEMBER 17TH 2021</h4>
                  <h3>THE PENNY BANK AND SO MUCH MORE</h3>
                  <h4>SVCBL TERMS AND CONDITIONS APPLY</h4>
                  <br/>
                  <h3>For more information visit our website: SVCBL.com or call us at 784-456-1894</h3>
               </div>
           </div> */}

           <div>
            <div class="snowflake"></div>
            <div class="snowflake"></div>
            <div class="snowflake"></div>
            <div class="snowflake"></div>
            <div class="snowflake"></div>
            <div class="snowflake"></div>
            <div class="snowflake"></div>
            <div class="snowflake"></div>
            <div class="snowflake"></div>
            <div class="snowflake"></div>
            <div class="snowflake"></div>
            <div class="snowflake"></div>
            <div class="snowflake"></div>
            <div class="snowflake"></div>
            <div class="snowflake"></div>
            <div class="snowflake"></div>
            <div class="snowflake"></div>
            <div class="snowflake"></div>
            <div class="snowflake"></div>
            <div class="snowflake"></div>
            <div class="snowflake"></div>
            <div class="snowflake"></div>
            <div class="snowflake"></div>
            <div class="snowflake"></div>
            <div class="snowflake"></div>
            <div class="snowflake"></div>
            <div class="snowflake"></div>
            <div class="snowflake"></div>
            <div class="snowflake"></div>
            <div class="snowflake"></div>
            <div class="snowflake"></div>
            <div class="snowflake"></div>
            <div class="snowflake"></div>
            <div class="snowflake"></div>
            <div class="snowflake"></div>
            <div class="snowflake"></div>
            <div class="snowflake"></div>
            <div class="snowflake"></div>
            <div class="snowflake"></div>
            <div class="snowflake"></div>
            <div class="snowflake"></div>
            <div class="snowflake"></div>
            <div class="snowflake"></div>
            <div class="snowflake"></div>
            <div class="snowflake"></div>
            <div class="snowflake"></div>
            <div class="snowflake"></div>
            <div class="snowflake"></div>
            <div class="snowflake"></div>
            <div class="snowflake"></div>
           </div>
           
        </div>
        <Footer/>
        </>
    )
}