import React, { useEffect } from 'react';
import '../style/services.css';

import Button from '@material-ui/core/Button';

import AOS from 'aos';
import 'aos/dist/aos.css'; 

import Note from '../components/note.js';
import Header from '../components/header.js';
import Footer from '../components/footer';

import atmImg from '../assets/artwork/atm_short.jpg';


import Slider from '../components/about_slider';

import cardImg from '../assets/card.png';

import CardIcon from '../assets/icons/card.svg';
import MapsIcon from '../assets/icons/maps.svg';

import { Link } from 'react-router-dom';

import PageTop from '../components/pageTop';


export default function ATM(){
    window.scrollTo(0, 0);

    useEffect(() => {
        AOS.init({ duration: 1000 });
    }, []);

    return(
        <div>
            {/* <Note/> */}
            <Header/>
            <Slider/>
            <PageTop/>
            <div className="page_title">
               <label className='fancy'>ATM Services</label>
            </div>
            
            <div class="service_body" style={{marginTop: '-150px'}}>
                <div class="service">
                   <div data-aos="fade-right" class="image">
                       <img src={atmImg}/>
                   </div>
                   <div data-aos="fade-left" class="content">
                        <div class="icon">
                            <img src={CardIcon}/>
                        </div><br/>
                        <label class="label">Sign up today for a SVCB ATM Card!</label>

                        <p>
                           The St Vincent Co-operative Bank Limited continues to enhance its offering in order to make banking as easy and convenient as possible for its customers. On April 13th 2010, St Vincent Co-operative Bank announced its new ATM capabilities.
                        </p>
                        <p>
                           Customers can access their funds any time of the day, any day of the week from our Main branch or from any Bank of St Vincent & the Grenadines ATM machine.
                        </p>
                        <b>General Requirements:</b>
                        <ul>
                            <li>Must be an active account holder</li>
                            <li>National ID Card / Other ID</li>
                        </ul>

                        <br/>
                        <Link to="/apply/atm" className="link">
                          Sign Up
                        </Link>
                   </div>
                </div>
                <div class="service">
                   <div data-aos="fade-right" class="content">
                       <div class="icon">
                            <img src={MapsIcon}/>
                        </div><br/>
                        <label class="label">Accessible at 19 of BOSVG ATM</label><br/><br/>
                        <b>ATM Locations:</b>
                        <ul>
                            <li><b>Kingstown</b> Bedford Street, Reigate, Massy Stoney Grounds, Massy Bay Street FLOW</li>
                            <li><b>Windward</b> Massy Arnos Vale, Mespo, Calliaqua, AIA, Georgetown</li>
                            <li><b>Leeward</b> Layou, Barrouallie</li>
                            <li><b>Grenadines</b> Bequia, Union, Canouan</li>
                        </ul>
                        <p style={{color: 'red'}}>
                           Please note a flat fee of XCD$1.50 applies to transactions done through a BOSVG ATM
                        </p>
                   </div>
                   <div data-aos="fade-left" class="image">
                      <iframe src="https://www.google.com/maps/d/embed?mid=1kQ9VejaaljSKZLybpgj0P8lzGzhFeVzr" className="maps"></iframe>
                   </div>
                </div>
            </div>
            <Footer/>
        </div>
    )
}
