import React from 'react';
import '../style/loader.css';
import '../style/linear.css';

export default function Loader(){
    return(
        <>
          <div class="loader">
            <div class="base">
                <div class="wrap">
                  <label class="title">Please wait</label>
                  <span class="subtext">Posting your application....</span>
                  <progress class="linear"></progress>
                </div>
            </div>
          </div>
        </>
    )
}