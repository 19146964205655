import React, { Fragment, useState, useEffect } from 'react';
import '../style/application.css';

import Loader from '../components/loader';

import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Alert, AlertTitle } from '@material-ui/lab';

import Button from '@material-ui/core/Button';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';

import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import TextField from '@material-ui/core/TextField';
import FormHelperText from '@material-ui/core/FormHelperText';

import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';

import Note from '../components/note.js';
import Header from '../components/header.js';
import Footer from '../components/footer';

import Slider from '../components/about_slider';

import PageTop from '../components/pageTop';

import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers';

const useStyles = makeStyles((theme) => ({
    button: {
      display: 'block',
      marginTop: theme.spacing(2),
    },
    formControl: {
      margin: theme.spacing(1),
      width: '50%',
      minWidth: 200,
      maxWidth: '100%'
    },
  }));


export default function Savings(){
    const [loader, setLoader] = useState(false);

    useEffect(() => {
        window.scrollTo(0, 0);
        const hash = window.location.hash.substr(1);
        setAccountType({'value': hash, 'error': false, 'helper': ''});
    }, []);
      

    const countries = [
        {"name": "Afghanistan", "code": "AF"},
        {"name": "Åland Islands", "code": "AX"},
        {"name": "Albania", "code": "AL"},
        {"name": "Algeria", "code": "DZ"},
        {"name": "American Samoa", "code": "AS"},
        {"name": "AndorrA", "code": "AD"},
        {"name": "Angola", "code": "AO"},
        {"name": "Anguilla", "code": "AI"},
        {"name": "Antarctica", "code": "AQ"},
        {"name": "Antigua and Barbuda", "code": "AG"},
        {"name": "Argentina", "code": "AR"},
        {"name": "Armenia", "code": "AM"},
        {"name": "Aruba", "code": "AW"},
        {"name": "Australia", "code": "AU"},
        {"name": "Austria", "code": "AT"},
        {"name": "Azerbaijan", "code": "AZ"},
        {"name": "Bahamas", "code": "BS"},
        {"name": "Bahrain", "code": "BH"},
        {"name": "Bangladesh", "code": "BD"},
        {"name": "Barbados", "code": "BB"},
        {"name": "Belarus", "code": "BY"},
        {"name": "Belgium", "code": "BE"},
        {"name": "Belize", "code": "BZ"},
        {"name": "Benin", "code": "BJ"},
        {"name": "Bermuda", "code": "BM"},
        {"name": "Bhutan", "code": "BT"},
        {"name": "Bolivia", "code": "BO"},
        {"name": "Bosnia and Herzegovina", "code": "BA"},
        {"name": "Botswana", "code": "BW"},
        {"name": "Bouvet Island", "code": "BV"},
        {"name": "Brazil", "code": "BR"},
        {"name": "British Indian Ocean Territory", "code": "IO"},
        {"name": "Brunei Darussalam", "code": "BN"},
        {"name": "Bulgaria", "code": "BG"},
        {"name": "Burkina Faso", "code": "BF"},
        {"name": "Burundi", "code": "BI"},
        {"name": "Cambodia", "code": "KH"},
        {"name": "Cameroon", "code": "CM"},
        {"name": "Canada", "code": "CA"},
        {"name": "Cape Verde", "code": "CV"},
        {"name": "Cayman Islands", "code": "KY"},
        {"name": "Central African Republic", "code": "CF"},
        {"name": "Chad", "code": "TD"},
        {"name": "Chile", "code": "CL"},
        {"name": "China", "code": "CN"},
        {"name": "Christmas Island", "code": "CX"},
        {"name": "Cocos (Keeling) Islands", "code": "CC"},
        {"name": "Colombia", "code": "CO"},
        {"name": "Comoros", "code": "KM"},
        {"name": "Congo", "code": "CG"},
        {"name": "Congo, The Democratic Republic of the", "code": "CD"},
        {"name": "Cook Islands", "code": "CK"},
        {"name": "Costa Rica", "code": "CR"},
        {"name": "Cote D'Ivoire", "code": "CI"},
        {"name": "Croatia", "code": "HR"},
        {"name": "Cuba", "code": "CU"},
        {"name": "Cyprus", "code": "CY"},
        {"name": "Czech Republic", "code": "CZ"},
        {"name": "Denmark", "code": "DK"},
        {"name": "Djibouti", "code": "DJ"},
        {"name": "Dominica", "code": "DM"},
        {"name": "Dominican Republic", "code": "DO"},
        {"name": "Ecuador", "code": "EC"},
        {"name": "Egypt", "code": "EG"},
        {"name": "El Salvador", "code": "SV"},
        {"name": "Equatorial Guinea", "code": "GQ"},
        {"name": "Eritrea", "code": "ER"},
        {"name": "Estonia", "code": "EE"},
        {"name": "Ethiopia", "code": "ET"},
        {"name": "Falkland Islands (Malvinas)", "code": "FK"},
        {"name": "Faroe Islands", "code": "FO"},
        {"name": "Fiji", "code": "FJ"},
        {"name": "Finland", "code": "FI"},
        {"name": "France", "code": "FR"},
        {"name": "French Guiana", "code": "GF"},
        {"name": "French Polynesia", "code": "PF"},
        {"name": "French Southern Territories", "code": "TF"},
        {"name": "Gabon", "code": "GA"},
        {"name": "Gambia", "code": "GM"},
        {"name": "Georgia", "code": "GE"},
        {"name": "Germany", "code": "DE"},
        {"name": "Ghana", "code": "GH"},
        {"name": "Gibraltar", "code": "GI"},
        {"name": "Greece", "code": "GR"},
        {"name": "Greenland", "code": "GL"},
        {"name": "Grenada", "code": "GD"},
        {"name": "Guadeloupe", "code": "GP"},
        {"name": "Guam", "code": "GU"},
        {"name": "Guatemala", "code": "GT"},
        {"name": "Guernsey", "code": "GG"},
        {"name": "Guinea", "code": "GN"},
        {"name": "Guinea-Bissau", "code": "GW"},
        {"name": "Guyana", "code": "GY"},
        {"name": "Haiti", "code": "HT"},
        {"name": "Heard Island and Mcdonald Islands", "code": "HM"},
        {"name": "Holy See (Vatican City State)", "code": "VA"},
        {"name": "Honduras", "code": "HN"},
        {"name": "Hong Kong", "code": "HK"},
        {"name": "Hungary", "code": "HU"},
        {"name": "Iceland", "code": "IS"},
        {"name": "India", "code": "IN"},
        {"name": "Indonesia", "code": "ID"},
        {"name": "Iran, Islamic Republic Of", "code": "IR"},
        {"name": "Iraq", "code": "IQ"},
        {"name": "Ireland", "code": "IE"},
        {"name": "Isle of Man", "code": "IM"},
        {"name": "Israel", "code": "IL"},
        {"name": "Italy", "code": "IT"},
        {"name": "Jamaica", "code": "JM"},
        {"name": "Japan", "code": "JP"},
        {"name": "Jersey", "code": "JE"},
        {"name": "Jordan", "code": "JO"},
        {"name": "Kazakhstan", "code": "KZ"},
        {"name": "Kenya", "code": "KE"},
        {"name": "Kiribati", "code": "KI"},
        {"name": "Korea, Democratic People'S Republic of", "code": "KP"},
        {"name": "Korea, Republic of", "code": "KR"},
        {"name": "Kuwait", "code": "KW"},
        {"name": "Kyrgyzstan", "code": "KG"},
        {"name": "Lao People'S Democratic Republic", "code": "LA"},
        {"name": "Latvia", "code": "LV"},
        {"name": "Lebanon", "code": "LB"},
        {"name": "Lesotho", "code": "LS"},
        {"name": "Liberia", "code": "LR"},
        {"name": "Libyan Arab Jamahiriya", "code": "LY"},
        {"name": "Liechtenstein", "code": "LI"},
        {"name": "Lithuania", "code": "LT"},
        {"name": "Luxembourg", "code": "LU"},
        {"name": "Macao", "code": "MO"},
        {"name": "Macedonia, The Former Yugoslav Republic of", "code": "MK"},
        {"name": "Madagascar", "code": "MG"},
        {"name": "Malawi", "code": "MW"},
        {"name": "Malaysia", "code": "MY"},
        {"name": "Maldives", "code": "MV"},
        {"name": "Mali", "code": "ML"},
        {"name": "Malta", "code": "MT"},
        {"name": "Marshall Islands", "code": "MH"},
        {"name": "Martinique", "code": "MQ"},
        {"name": "Mauritania", "code": "MR"},
        {"name": "Mauritius", "code": "MU"},
        {"name": "Mayotte", "code": "YT"},
        {"name": "Mexico", "code": "MX"},
        {"name": "Micronesia, Federated States of", "code": "FM"},
        {"name": "Moldova, Republic of", "code": "MD"},
        {"name": "Monaco", "code": "MC"},
        {"name": "Mongolia", "code": "MN"},
        {"name": "Montserrat", "code": "MS"},
        {"name": "Morocco", "code": "MA"},
        {"name": "Mozambique", "code": "MZ"},
        {"name": "Myanmar", "code": "MM"},
        {"name": "Namibia", "code": "NA"},
        {"name": "Nauru", "code": "NR"},
        {"name": "Nepal", "code": "NP"},
        {"name": "Netherlands", "code": "NL"},
        {"name": "Netherlands Antilles", "code": "AN"},
        {"name": "New Caledonia", "code": "NC"},
        {"name": "New Zealand", "code": "NZ"},
        {"name": "Nicaragua", "code": "NI"},
        {"name": "Niger", "code": "NE"},
        {"name": "Nigeria", "code": "NG"},
        {"name": "Niue", "code": "NU"},
        {"name": "Norfolk Island", "code": "NF"},
        {"name": "Northern Mariana Islands", "code": "MP"},
        {"name": "Norway", "code": "NO"},
        {"name": "Oman", "code": "OM"},
        {"name": "Pakistan", "code": "PK"},
        {"name": "Palau", "code": "PW"},
        {"name": "Palestinian Territory, Occupied", "code": "PS"},
        {"name": "Panama", "code": "PA"},
        {"name": "Papua New Guinea", "code": "PG"},
        {"name": "Paraguay", "code": "PY"},
        {"name": "Peru", "code": "PE"},
        {"name": "Philippines", "code": "PH"},
        {"name": "Pitcairn", "code": "PN"},
        {"name": "Poland", "code": "PL"},
        {"name": "Portugal", "code": "PT"},
        {"name": "Puerto Rico", "code": "PR"},
        {"name": "Qatar", "code": "QA"},
        {"name": "Reunion", "code": "RE"},
        {"name": "Romania", "code": "RO"},
        {"name": "Russian Federation", "code": "RU"},
        {"name": "RWANDA", "code": "RW"},
        {"name": "Saint Helena", "code": "SH"},
        {"name": "Saint Kitts and Nevis", "code": "KN"},
        {"name": "Saint Lucia", "code": "LC"},
        {"name": "Saint Pierre and Miquelon", "code": "PM"},
        {"name": "Saint Vincent and the Grenadines", "code": "VC"},
        {"name": "Samoa", "code": "WS"},
        {"name": "San Marino", "code": "SM"},
        {"name": "Sao Tome and Principe", "code": "ST"},
        {"name": "Saudi Arabia", "code": "SA"},
        {"name": "Senegal", "code": "SN"},
        {"name": "Serbia and Montenegro", "code": "CS"},
        {"name": "Seychelles", "code": "SC"},
        {"name": "Sierra Leone", "code": "SL"},
        {"name": "Singapore", "code": "SG"},
        {"name": "Slovakia", "code": "SK"},
        {"name": "Slovenia", "code": "SI"},
        {"name": "Solomon Islands", "code": "SB"},
        {"name": "Somalia", "code": "SO"},
        {"name": "South Africa", "code": "ZA"},
        {"name": "South Georgia and the South Sandwich Islands", "code": "GS"},
        {"name": "Spain", "code": "ES"},
        {"name": "Sri Lanka", "code": "LK"},
        {"name": "Sudan", "code": "SD"},
        {"name": "Suriname", "code": "SR"},
        {"name": "Svalbard and Jan Mayen", "code": "SJ"},
        {"name": "Swaziland", "code": "SZ"},
        {"name": "Sweden", "code": "SE"},
        {"name": "Switzerland", "code": "CH"},
        {"name": "Syrian Arab Republic", "code": "SY"},
        {"name": "Taiwan, Province of China", "code": "TW"},
        {"name": "Tajikistan", "code": "TJ"},
        {"name": "Tanzania, United Republic of", "code": "TZ"},
        {"name": "Thailand", "code": "TH"},
        {"name": "Timor-Leste", "code": "TL"},
        {"name": "Togo", "code": "TG"},
        {"name": "Tokelau", "code": "TK"},
        {"name": "Tonga", "code": "TO"},
        {"name": "Trinidad and Tobago", "code": "TT"},
        {"name": "Tunisia", "code": "TN"},
        {"name": "Turkey", "code": "TR"},
        {"name": "Turkmenistan", "code": "TM"},
        {"name": "Turks and Caicos Islands", "code": "TC"},
        {"name": "Tuvalu", "code": "TV"},
        {"name": "Uganda", "code": "UG"},
        {"name": "Ukraine", "code": "UA"},
        {"name": "United Arab Emirates", "code": "AE"},
        {"name": "United Kingdom", "code": "GB"},
        {"name": "United States", "code": "US"},
        {"name": "United States Minor Outlying Islands", "code": "UM"},
        {"name": "Uruguay", "code": "UY"},
        {"name": "Uzbekistan", "code": "UZ"},
        {"name": "Vanuatu", "code": "VU"},
        {"name": "Venezuela", "code": "VE"},
        {"name": "Viet Nam", "code": "VN"},
        {"name": "Virgin Islands, British", "code": "VG"},
        {"name": "Virgin Islands, U.S.", "code": "VI"},
        {"name": "Wallis and Futuna", "code": "WF"},
        {"name": "Western Sahara", "code": "EH"},
        {"name": "Yemen", "code": "YE"},
        {"name": "Zambia", "code": "ZM"},
        {"name": "Zimbabwe", "code": "ZW"}
        ];
    
    const countryList = countries.map((country) =>
         <MenuItem value={country.name} selected>{country.name}</MenuItem>
    );

    const classes = useStyles();

    const [steps, setSteps] = React.useState(0);
    const maxSteps = 10;

    const [accountType, setAccountType] = Data();
    const accountType_handler = (event) => {
        if(event.target.value == "business or group"){
            setIsJointAccount({'value': "yes", 'error': false, 'helper': ''});
        }
       setAccountType({'value': event.target.value})
    }

    const lastStep = () => {
        if(steps == maxSteps){
            alert('completing');
        }
    }

    const nextStep = () => {

        if(isJointAccount.value === "no"){
            if(!validate(1)){
                checkPerson(1);
            }else{
                showTerm();
                if(checkTerms()){
                    setLoader(true);
                    document.getElementById("SavingsForm").submit();
                }
            }
        }

        if(isJointAccount.value  === "yes"){
            if(numAccountHolders.value === '2'){
                
                if(personStatus[1].value === "" || personTitle[1].value === ""
                || personFirstName[1].value === "" || personLastName[1].value === ""
                || personDateOfBirth[1].value === "" || personCountryOfBirth[1].value === ""
                || personCountryOfResidence[1].value === "" || personAddress[1].value === "" || proofOfAddress[1].value === ""
                || proofOfIncome[1].value === ""
                || personMobileNumber[1].value === "" || personFirstId[1].value === "" || personFirstIdNumber[1].value === ""
                || personFirstIdExpiration[1].value === "" || personFirstIdCountry[1].value === ""
                || personSecondId[1].value === "" || personSecondIdNumber[1].value === "" || personSecondIdExpiration[1].value === ""
                || personSecondIdCountry[1].value === "" ){
                    checkPerson(1);
                }else{
                   if(personStatus[2].value === "" || personTitle[2].value === ""
                    || personFirstName[2].value === "" || personLastName[2].value === ""
                    || personDateOfBirth[2].value === "" || personCountryOfBirth[2].value === ""
                    || personCountryOfResidence[2].value === "" || personAddress[2].value === "" || proofOfAddress[2].value === ""
                    || proofOfIncome[2].value === ""
                    || personMobileNumber[2].value === "" || personFirstId[2].value === "" || personFirstIdNumber[2].value === ""
                    || personFirstIdExpiration[2].value === "" || personFirstIdCountry[2].value === ""
                    || personSecondId[2].value === "" || personSecondIdNumber[2].value === "" || personSecondIdExpiration[2].value === ""
                    || personSecondIdCountry[2].value === ""){
                        checkPerson(2);
                    }else{
                        showTerm();
                        if(checkTerms()){
                            setLoader(true);
                            document.getElementById("SavingsForm").submit();
                        }
                    }
                }

            }else if(numAccountHolders.value === '3'){
                if(personStatus[1].value === "" || personTitle[1].value === ""
                || personFirstName[1].value === "" || personLastName[1].value === ""
                || personDateOfBirth[1].value === "" || personCountryOfBirth[1].value === ""
                || personCountryOfResidence[1].value === "" || personAddress[1].value === "" || proofOfAddress[1].value === ""
                || proofOfIncome[1].value === ""
                || personMobileNumber[1].value === "" || personFirstId[1].value === "" || personFirstIdNumber[1].value === ""
                || personFirstIdExpiration[1].value === "" || personFirstIdCountry[1].value === ""
                || personSecondId[1].value === "" || personSecondIdNumber[1].value === "" || personSecondIdExpiration[1].value === ""
                || personSecondIdCountry[1].value === "" ){
                    checkPerson(1);
                }else{
                   if(personStatus[2].value === "" || personTitle[2].value === ""
                    || personFirstName[2].value === "" || personLastName[2].value === ""
                    || personDateOfBirth[2].value === "" || personCountryOfBirth[2].value === ""
                    || personCountryOfResidence[2].value === "" || personAddress[2].value === "" || proofOfAddress[2].value === ""
                    || proofOfIncome[2].value === ""
                    || personMobileNumber[2].value === "" || personFirstId[2].value === "" || personFirstIdNumber[2].value === ""
                    || personFirstIdExpiration[2].value === "" || personFirstIdCountry[2].value === ""
                    || personSecondId[2].value === "" || personSecondIdNumber[2].value === "" || personSecondIdExpiration[2].value === ""
                    || personSecondIdCountry[2].value === ""){
                        checkPerson(2);
                    }else{
                        if(personStatus[3].value === "" || personTitle[3].value === ""
                        || personFirstName[3].value === "" || personLastName[3].value === ""
                        || personDateOfBirth[3].value === "" || personCountryOfBirth[3].value === ""
                        || personCountryOfResidence[3].value === "" || personAddress[3].value === "" || proofOfAddress[3].value === ""
                        || proofOfIncome[3].value === ""
                        || personMobileNumber[3].value === "" || personFirstId[3].value === "" || personFirstIdNumber[3].value === ""
                        || personFirstIdExpiration[3].value === "" || personFirstIdCountry[3].value === ""
                        || personSecondId[3].value === "" || personSecondIdNumber[3].value === "" || personSecondIdExpiration[3].value === ""
                        || personSecondIdCountry[3].value === ""){
                            checkPerson(3);
                        }else{
                            showTerm();
                            if(checkTerms()){
                                setLoader(true);
                                document.getElementById("SavingsForm").submit();
                            }
                        }
                    }
                }
            }
        }

        if(accountType.value === "penny"){
            if(childFirstName.value == "" || childLastName.value == "" || childDateOfBirth.value == "" || childCountryOfBirth.value == "" || childCountryOfResidence.value == "" || childSchool.value == "" || childBirthCertificate.value == ""){
                setChildInfoError('block');                    
                // document.getElementById("childInfoError").scrollIntoView();
                childFirstName_handler('check');
                childLastName_handler('check');
                childDateOfBirth_handler('check');
                childCountryOfBirth_handler('check');
                childCountryOfResidence_handler('check');
                childAddressLine1_handler('check');
                childSchool_handler('check');
                childBirthCertificate_handler('check');
                
                checkPerson(1);
                
            }else{
                showTerm();
                if(checkTerms()){
                    setLoader(true);
                    document.getElementById("SavingsForm").submit();
                }
            }
        }
    }

    function valMultiple(val){
        if(numAccountHolders.value >= val){
            return !validate(val);
        }
    }

    function valMultiplePenny(val){
        if(parentGuardian.value >= val){
            return !validate(val);
        }
    }

    function showTerm(){
        setTermsView(terms());
        // document.getElementById('terms').scrollIntoView();
    }

    function checkTerms(){
        if(!agreeWithTerms){
            setTermsAgreementError('You must agree with the terms displayed above to continue');
            // document.getElementById('termsOfServiceError').scrollIntoView();
            return false;
        }else{
            setTermsAgreementError('');
            return true;
        }
    }

    function dataEmpty(value){
        if(value == ""){
            return false;
        }else{
            return true;
        }
    }

    const [termsView, setTermsView] = React.useState('');

    function validate(index){
        if(personStatus[index].value === "" || personTitle[index].value === ""
        || personFirstName[index].value === "" || personLastName[index].value === ""
        || personDateOfBirth[index].value === "" || personCountryOfBirth[index].value === ""
        || personCountryOfResidence[index].value === "" || personAddress[index].value === "" || proofOfAddress[index].value === ""
        || proofOfIncome[index].value === ""
        || personMobileNumber[index].value === "" || personFirstId[index].value === "" || personFirstIdNumber[index].value === ""
        || personFirstIdExpiration[index].value === "" || personFirstIdCountry[index].value === ""
        || personSecondId[index].value === "" || personSecondIdNumber[index].value === "" || personSecondIdExpiration[index].value === ""
        || personSecondIdCountry[index].value === ""){
           return false;
        }else{
            return true;
        }
    }

    function checkPerson(index){
        personStatus_handler('check', index);
        personTitle_handler('check', index);
        personFirstName_handler('check', index);
        personLastName_handler('check', index);

        personDateOfBirth_handler('check', index);
        personCountryOfBirth_handler('check', index);
        personCountryOfResidence_handler('check', index);
        personMobileNumber_handler('check', index);
        // personHomeNumber_handler('check', index);
        
        personEmailAddress_handler('check', index);

        personAddress_handler('check', index);
        proofOfAddress_handler('check', index);

        personEmployed_handler('check', index);
        income_handler('check', index);
        proofOfIncome_handler('check', index);
        incomeFrequency_handler('check', index);

        personFirstId_handler('check', index);
        personFirstIdNumber_handler('check', index);
        personFirstIdExpiration_handler('check', index);
        personFirstIdCountry_handler('check', index);

        personSecondId_handler('check', index);
        personSecondIdNumber_handler('check', index);
        personSecondIdExpiration_handler('check', index);
        personSecondIdCountry_handler('check', index);   
    }


    const prevStep = () => {
       if(steps >= 1){
           setSteps(steps - 1);
       }
    }

    function Data(){
        return React.useState({'value': '', 'error': false, 'helper': ''});
    }

    function DataArray(){
        return React.useState([
            {'value': '', 'error': false, 'helper': ''},
            {'value': '', 'error': false, 'helper': ''},
            {'value': '', 'error': false, 'helper': ''},
            {'value': '', 'error': false, 'helper': ''},
        ]);
    }

    function DateArray(){
        return React.useState([
            {'value': new Date(), 'error': false, 'helper': ''},
            {'value': new Date(), 'error': false, 'helper': ''},
            {'value': new Date(), 'error': false, 'helper': ''},
            {'value': new Date(), 'error': false, 'helper': ''},
        ]);
    }

    function DataArrayCountry(){
        return React.useState([
            {'value': 'Saint Vincent and the Grenadines', 'error': false, 'helper': ''},
            {'value': 'Saint Vincent and the Grenadines', 'error': false, 'helper': ''},
            {'value': 'Saint Vincent and the Grenadines', 'error': false, 'helper': ''},
            {'value': 'Saint Vincent and the Grenadines', 'error': false, 'helper': ''},
        ]);
    }

    const ImageThumb = ({ image }) => {
        return <img src={URL.createObjectURL(image)} alt={image.name} />;
    };

    const [serviceOptions, setServiceOptions] = React.useState({
        savings: true,
        fixed: false,
        online: false,
        atm: false
    });

    const serviceOptions_handler = (event) => {
         setServiceOptions({...serviceOptions, [event.target.name]: event.target.checked})
    }

    // !###################################################################################################################
    //  ? CHILD INFO FORM.... FOR PENNY SAVERS
    // !###################################################################################################################
    
    const [isFormError, setIsFormError] = React.useState('none');
    const [formError, setFormError] = React.useState('');

    // !###################################################################################################################
    //  ? CHILD INFO FORM.... FOR PENNY SAVERS
    // !###################################################################################################################

    const [childInfoError, setChildInfoError] = React.useState('none');

    const [parentGuardian, setParentGuardian] = Data();
    const parentGuardian_handler = (event) => {
        const value = event == 'check' ? parentGuardian.value : event.target.value;
        if(value === ""){ setParentGuardian({'value':value, 'error': true, 'helper': 'Please enter number of parent or guardian'})}
        else if(value < 1){ setParentGuardian({'value': value, 'error': true, 'helper': 'A minimum of 1 parent or guardian is required'})}
        else if(value > 3){ setParentGuardian({'value': value, 'error': true, 'helper': 'A maximum of 3 parent or guardian is allowed on an account'})}
        else { setParentGuardian({'value': value, 'error': false, 'helper': ''})}
    }

    const [childFirstName, setChildFirstName] = Data();
    const childFirstName_handler = (event) => {
        const value = event == 'check' ? childFirstName.value : event.target.value;
        if(value === ""){ setChildFirstName({'value':value, 'error': true, 'helper': 'Child first name is required'})} 
        else { setChildFirstName({'value': value, 'error': false, 'helper': ''})}
    }

    const [childMiddleName, setChildMiddleName] = Data();
    const childMiddleName_handler = (event) => {
        const value = event == 'check' ? childMiddleName.value : event.target.value;
        if(value === ""){ setChildMiddleName({'value':value, 'error': true, 'helper': 'Child middle name is required'})} 
        else { setChildMiddleName({'value': value, 'error': false, 'helper': ''})}
    }

    const [childLastName, setChildLastName] = Data();
    const childLastName_handler = (event) => {
        const value = event == 'check' ? childLastName.value : event.target.value;
        if(value === ""){ setChildLastName({'value':value, 'error': true, 'helper': 'Child last name is required'})} 
        else { setChildLastName({'value': value, 'error': false, 'helper': ''})}
    }

    const [childDateOfBirth, setChildDateOfBirth] = React.useState({'value': new Date(), 'error': false, 'helper': ''})
    const childDateOfBirth_handler = (event) => {
        const value = event == 'check' ? childDateOfBirth.value : event;
        if(value === ""){ setChildDateOfBirth({'value': value, 'error': true, 'helper': 'Child date of birth required'})} 
        else { setChildDateOfBirth({'value': value, 'error': false, 'helper': ''})}
    }

    const [childCountryOfBirth, setChildCountryOfBirth] = React.useState({'value': 'Saint Vincent and the Grenadines', 'error': false, 'helper': ''});
    const childCountryOfBirth_handler = (event) => {
        const value = event == 'check' ? childCountryOfBirth.value : event.target.value;
        if(value === ""){ setChildCountryOfBirth({'value':value, 'error': true, 'helper': 'Child country of birth required'})} 
        else { setChildCountryOfBirth({'value': value, 'error': false, 'helper': ''})}
    }

    const [childCountryOfResidence, setChildCountryOfResidence] = React.useState({'value': 'Saint Vincent and the Grenadines', 'error': false, 'helper': ''});
    const childCountryOfResidence_handler = (event) => {
        const value = event == 'check' ? childCountryOfResidence.value : event.target.value;
        if(value === ""){ setChildCountryOfResidence({'value':value, 'error': true, 'helper': 'Child country of Residence required'})} 
        else { setChildCountryOfResidence({'value': value, 'error': false, 'helper': ''})}
    }

    const [childAddressLine1, setChildAddressLine1] = Data();
    const childAddressLine1_handler = (event) => {
        const value = event == 'check' ? childAddressLine1.value : event.target.value;
        if(value === ""){ setChildAddressLine1({'value':value, 'error': true, 'helper': 'Child address line is required'})} 
        else { setChildAddressLine1({'value': value, 'error': false, 'helper': ''})}
    }

    const [childAddressLine2, setChildAddressLine2] = Data();
    const childAddressLine2_handler = (event) => {
        const value = event.target.value
        setChildAddressLine2({'value': value});
    }

    const [childSchool, setChildSchool] = Data();
    const childSchool_handler = (event) => {
        const value = event == 'check' ? childSchool.value : event.target.value;
        if(value === ""){ setChildSchool({'value':value, 'error': true, 'helper': 'Child school required'})} 
        else { setChildSchool({'value': value, 'error': false, 'helper': ''})}
    }

    const [childSchoolAddress, setChildSchoolAddress] = Data();
    const childSchoolAddress_handler = (event) => {
        const value = event == 'check' ? childSchoolAddress.value : event.target.value;
        if(value === ""){ setChildSchoolAddress({'value':value, 'error': true, 'helper': 'Child school address required'})} 
        else { setChildSchoolAddress({'value': value, 'error': false, 'helper': ''})}
    }
    
    const [childBirthCertificate, setChildBirthCertificate] = Data();
    const childBirthCertificate_handler = (event) => {
        const value = event == 'check' ? childBirthCertificate.value : event.target.value;
        if(value === ""){ setChildBirthCertificate({'value':value, 'error': true, 'helper': 'Child birth certificate is required'})} 
        else { setChildBirthCertificate({'value': value, 'error': false, 'helper': ''})}
    }

    // !###################################################################################################################
    //  ? SECOND ACCOUNT HOLDER INFO
    // !###################################################################################################################

    const [personalInfoError, setPersonalInfoError] = React.useState('none');

    const [isJointAccount, setIsJointAccount] = Data();
    const isJointAccount_handler = (event) => {
        const value = event == 'check' ? isJointAccount.value : event.target.value;
        if(value === ""){ setIsJointAccount({'value':value, 'error': true, 'helper': 'Required'})} 
        else { setIsJointAccount({'value': value, 'error': false, 'helper': ''})}
    }

    const [numAccountHolders, setnumAccountHolders] = Data();
    const numAccountHolders_handler = (event) => {
        const value = event == 'check' ? numAccountHolders.value : event.target.value;
        if(value === ""){ setnumAccountHolders({'value':value, 'error': true, 'helper': 'Please enter number of account holders'})}
        else if(value < 2){ setnumAccountHolders({'value': value, 'error': true, 'helper': 'A joint account requires a minimum of 2 account holders'})}
        else if(value > 3){ setnumAccountHolders({'value': value, 'error': true, 'helper': 'A maximum of 3 account holders for a joint or business account'})}
        else { setnumAccountHolders({'value': value, 'error': false, 'helper': ''})}
    }

    //! ##########################################################################

    const [personStatus, setPersonStatus] = DataArray();
    const personStatus_handler = (val, index) => {
        const value = val == 'check' ? personStatus[index].value : val;

        const newPersonalStatus = [...personStatus];

        if(value == ""){ newPersonalStatus[index] = {'value': value, 'error': true, 'helper': 'Please select Marital status'}}
        else { newPersonalStatus[index] = {'value': value, 'error': false, 'helper': ''}} 

        setPersonStatus(newPersonalStatus);
    }

    const [personTitle, setPersonTitle] = DataArray();
    const personTitle_handler = (val, index) => {
        const value = val == 'check' ? personTitle[index].value : val;

        const newPersonalStatus = [...personTitle];

        if(value == ""){ newPersonalStatus[index] = {'value':value, 'error': true, 'helper': 'Please select title'}}
        else { newPersonalStatus[index] = {'value': value, 'error': false, 'helper': ''}} 

        setPersonTitle(newPersonalStatus);
    }

    const [personFirstName, setPersonFirstName] = DataArray();
    const personFirstName_handler = (val, index) => {
        const value = val == 'check' ? personFirstName[index].value : val;

        const newPersonalStatus = [...personFirstName];

        if(value == ""){ newPersonalStatus[index] = {'value':value, 'error': true, 'helper': 'First name is required'}}
        else { newPersonalStatus[index] = {'value': value, 'error': false, 'helper': ''}} 

        setPersonFirstName(newPersonalStatus);
    }

    const [personMiddleName, setPersonMiddleName] = DataArray();
    const personMiddleName_handler = (val, index) => {
        const value = val == 'check' ? personMiddleName[index].value : val;

        const newPersonalStatus = [...personMiddleName];

        if(value == ""){ newPersonalStatus[index] = {'value':value, 'error': true, 'helper': 'Middle name is required'}}
        else { newPersonalStatus[index] = {'value': value, 'error': false, 'helper': ''}} 

        setPersonMiddleName(newPersonalStatus);
    }

    const [personLastName, setPersonLastName] = DataArray();
    const personLastName_handler = (val, index) => {
        const value = val == 'check' ? personLastName[index].value : val;

        const newPersonalStatus = [...personLastName];

        if(value == ""){ newPersonalStatus[index] = {'value':value, 'error': true, 'helper': 'Last name is required'}}
        else { newPersonalStatus[index] = {'value': value, 'error': false, 'helper': ''}} 

        setPersonLastName(newPersonalStatus);
    }

    const [personDateOfBirth, setPersonDateOfBirth] = DateArray();
    const personDateOfBirth_handler = (val, index) => {
        const value = val == 'check' ? personDateOfBirth[index].value : val;

        const newPersonalStatus = [...personDateOfBirth];

        if(value == ""){ newPersonalStatus[index] = {'value':value, 'error': true, 'helper': 'Date of birth required'}}
        else { newPersonalStatus[index] = {'value': value, 'error': false, 'helper': ''}} 

        setPersonDateOfBirth(newPersonalStatus);
    }


    const [personCountryOfBirth, setPersonCountryOfBirth] = DataArrayCountry();
    const personCountryOfBirth_handler = (val, index) => {
        const value = val == 'check' ? personCountryOfBirth[index].value : val;

        const newPersonalStatus = [...personCountryOfBirth];

        if(value == ""){ newPersonalStatus[index] = {'value':value, 'error': true, 'helper': 'Country of birth required'}}
        else { newPersonalStatus[index] = {'value': value, 'error': false, 'helper': ''}} 

        setPersonCountryOfBirth(newPersonalStatus);
    }


    const [personCountryOfResidence, setPersonCountryOfResidence] = DataArrayCountry();
    const personCountryOfResidence_handler = (val, index) => {
        const value = val == 'check' ? personCountryOfResidence[index].value : val;

        const newPersonalStatus = [...personCountryOfResidence];

        if(value == ""){ newPersonalStatus[index] = {'value':value, 'error': true, 'helper': 'Country of Residence required'}}
        else { newPersonalStatus[index] = {'value': value, 'error': false, 'helper': ''}} 

        setPersonCountryOfResidence(newPersonalStatus);
    }


    const [personAddress, setPersonAddress] = DataArray();
    const personAddress_handler = (val, index) => {
        const value = val == 'check' ? personAddress[index].value : val;

        const newPersonalStatus = [...personAddress];

        if(value == ""){ newPersonalStatus[index] = {'value':value, 'error': true, 'helper': 'Address required'}}
        else { newPersonalStatus[index] = {'value': value, 'error': false, 'helper': ''}} 

        setPersonAddress(newPersonalStatus);
    }

    const [proofOfAddress, setProofOfAddress] = DataArray();
    const proofOfAddress_handler = (val, index) => {
        const value = val == 'check' ? proofOfAddress[index].value : val;

        const newPersonalStatus = [...personAddress];

        if(value == ""){ newPersonalStatus[index] = {'value':value, 'error': true, 'helper': 'Proof of address required'}}
        else { newPersonalStatus[index] = {'value': value, 'error': false, 'helper': ''}} 

        setProofOfAddress(newPersonalStatus);
    }


    const [personAddress2, setPersonAddress2] = DataArray();
    const personAddress2_handler = (val, index) => {
        const value = val == 'check' ? personAddress2[index].value : val;

        const newPersonalStatus = [...personAddress2];

        if(value == ""){ newPersonalStatus[index] = {'value':value, 'error': true, 'helper': 'Address required'}}
        else { newPersonalStatus[index] = {'value': value, 'error': false, 'helper': ''}} 

        setPersonAddress2(newPersonalStatus);
    }


    const [personHomeNumber, setPersonHomeNumber] = DataArray();
    const personHomeNumber_handler = (val, index) => {
        const value = val == 'check' ? personHomeNumber[index].value : val;

        const newPersonalStatus = [...personHomeNumber];

        if(value == "" && personMobileNumber[index].value == ""){ newPersonalStatus[index] = {'value':value, 'error': true, 'helper': 'Either mobile or Home number is required'}}
        else { newPersonalStatus[index] = {'value': value, 'error': false, 'helper': ''}} 

        setPersonHomeNumber(newPersonalStatus);
    }

    const [personMobileNumber, setPersonMobileNumber] = DataArray();
    const personMobileNumber_handler = (val, index) => {
        const value = val == 'check' ? personMobileNumber[index].value : val;

        const newPersonalStatus = [...personMobileNumber];

        // if(value == "" && personHomeNumber[index].value == ""){ newPersonalStatus[index] = {'value':value, 'error': true, 'helper': 'Either mobile or Home number is required'}}
        if(value == ""){ newPersonalStatus[index] = {'value':value, 'error': true, 'helper': 'Phone number is required'}}
        else { newPersonalStatus[index] = {'value': value, 'error': false, 'helper': ''}} 

        setPersonMobileNumber(newPersonalStatus);
    }

    const [personEmailAddress, setPersonEmailAddress] = DataArray();
    const personEmailAddress_handler = (val, index) => {
        const value = val == 'check' ? personEmailAddress[index].value : val;

        const newPersonalStatus = [...personEmailAddress];

        if(value == ""){ newPersonalStatus[index] = {'value':value, 'error': true, 'helper': 'Email address required'}}
        else if(!validateEmail(value)){ newPersonalStatus[index] = {'value':value, 'error': true, 'helper': 'Invalid email address... valid email format example@gmail.com'}}
        else { newPersonalStatus[index] = {'value': value, 'error': false, 'helper': ''}} 

        setPersonEmailAddress(newPersonalStatus);
    }

    function validateEmail(email) {
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }

    const isNumericInput = (event) => {
        const key = event.keyCode;
        return ((key >= 48 && key <= 57) || // Allow number line
            (key >= 96 && key <= 105) // Allow number pad
        );
    };
    
    const isModifierKey = (event) => {
        const key = event.keyCode;
        return (event.shiftKey === true || key === 35 || key === 36) || // Allow Shift, Home, End
            (key === 8 || key === 9 || key === 13 || key === 46) || // Allow Backspace, Tab, Enter, Delete
            (key > 36 && key < 41) || // Allow left, up, right, down
            (
                // Allow Ctrl/Command + A,C,V,X,Z
                (event.ctrlKey === true || event.metaKey === true) &&
                (key === 65 || key === 67 || key === 86 || key === 88 || key === 90)
            )
    };

    const enforceFormat = (event) => {
        // Input must be of a valid number format or a modifier key, and not longer than ten digits
        if(!isNumericInput(event) && !isModifierKey(event)){
            event.preventDefault();
        }
    };
    
    const formatToPhone = (event) => {
        if(isModifierKey(event)) {return;}
    
        const input = event.target.value.replace(/\D/g,'').substring(0,10); // First ten digits of input only
        const areaCode = input.substring(0,3);
        const middle = input.substring(3,6);
        const last = input.substring(6,10);
    
        if(input.length > 6){event.target.value = `(${areaCode}) ${middle} - ${last}`;}
        else if(input.length > 3){event.target.value = `(${areaCode}) ${middle}`;}
        else if(input.length > 0){event.target.value = `(${areaCode}`;}
    };

    const [personEmployed, setPersonEmployed] = DataArray();
    const personEmployed_handler = (val, index) => {
        const value = val == 'check' ? personEmployed[index].value : val;

        const newPersonalStatus = [...personEmailAddress];
        if(value == ""){ newPersonalStatus[index] = {'value':value, 'error': true, 'helper': 'Please select employment status'}}
        else { newPersonalStatus[index] = {'value': value, 'error': false, 'helper': ''}} 

        setPersonEmployed(newPersonalStatus);
    }

    // !###################################################################################################################
    //  ? FIRST ID
    // !###################################################################################################################


    const [personFirstId, setPersonFirstId] = DataArray();
    const personFirstId_handler = (val, index) => {
        const value = val == 'check' ? personFirstId[index].value : val;

        const newPersonalStatus = [...personFirstId];

        if(value == ""){ newPersonalStatus[index] = {'value':value, 'error': true, 'helper': 'First ID is required'}}
        else { newPersonalStatus[index] = {'value': value, 'error': false, 'helper': ''}} 

        setPersonFirstId(newPersonalStatus);
    }

    const [personFirstIdType, setPersonFirstIdType] = DataArray();
    const personFirstIdType_handler = (val, index) => {
        const value = val == 'check' ? personFirstIdType[index].value : val;

        const newPersonalStatus = [...personFirstIdType];

        if(value == ""){ newPersonalStatus[index] = {'value':value, 'error': true, 'helper': 'ID type is required'}}
        else { newPersonalStatus[index] = {'value': value, 'error': false, 'helper': ''}} 

        setPersonFirstIdType(newPersonalStatus);
    }

    const [personFirstIdNumber, setPersonFirstIdNumber] = DataArray();
    const personFirstIdNumber_handler = (val, index) => {
        const value = val == 'check' ? personFirstIdNumber[index].value : val;

        const newPersonalStatus = [...personFirstIdNumber];

        if(value == ""){ newPersonalStatus[index] = {'value':value, 'error': true, 'helper': 'ID Number is required'}}
        else { newPersonalStatus[index] = {'value': value, 'error': false, 'helper': ''}} 

        setPersonFirstIdNumber(newPersonalStatus);
    }

    const [personFirstIdExpiration, setPersonFirstIdExpiration] = DateArray();
    const personFirstIdExpiration_handler = (val, index) => {
        const value = val == 'check' ? personFirstIdExpiration[index].value : val;

        const newPersonalStatus = [...personFirstIdExpiration];

        if(value == ""){ 
            newPersonalStatus[index] = {'value':value, 'error': true, 'helper': 'Id expiration date is required'}
        }else { 
            if(isAfterToday(value)){
                newPersonalStatus[index] = {'value': value, 'error': false, 'helper': ''}
            }else{
                newPersonalStatus[index] = {'value':value, 'error': true, 'helper': 'Id already expired'}
            }
        } 

        setPersonFirstIdExpiration(newPersonalStatus);
    }

    const [personFirstIdCountry, setPersonFirstIdCountry] = DataArrayCountry();
    const personFirstIdCountry_handler = (val, index) => {
        const value = val == 'check' ? personFirstIdCountry[index].value : val;

        const newPersonalStatus = [...personFirstIdCountry];

        if(value == ""){ newPersonalStatus[index] = {'value':value, 'error': true, 'helper': 'Country of issue is required'}}
        else { newPersonalStatus[index] = {'value': value, 'error': false, 'helper': ''}} 

        setPersonFirstIdCountry(newPersonalStatus);
    }

    // !###################################################################################################################
    //  ? SOURCE OF INCOME
    // !###################################################################################################################

    const [occupation, setOccupation] = DataArray();
    const occupation_handler = (val, index) => {
        const value = val == 'check' ? occupation[index].value : val;

        const newPersonalStatus = [...occupation];

        if(value == ""){ newPersonalStatus[index] = {'value':value, 'error': true, 'helper': 'Occupation required'}}
        else { newPersonalStatus[index] = {'value': value, 'error': false, 'helper': ''}} 

        setOccupation(newPersonalStatus);
    }

    const [income, setIncome] = DataArray();
    const income_handler = (val, index) => {
        const value = val == 'check' ? income[index].value : val;

        const newPersonalStatus = [...income];

        if(value == ""){ newPersonalStatus[index] = {'value':value, 'error': true, 'helper': 'Income is required'}}
        else { newPersonalStatus[index] = {'value': value, 'error': false, 'helper': ''}} 

        setIncome(newPersonalStatus);
    }

    const [incomeFrequency, setIncomeFrequency] = DataArray();
    const incomeFrequency_handler = (val, index) => {
        const value = val == 'check' ? incomeFrequency[index].value : val;

        const newPersonalStatus = [...incomeFrequency];

        if(value == ""){ newPersonalStatus[index] = {'value':value, 'error': true, 'helper': 'Income frequency is required'}}
        else { newPersonalStatus[index] = {'value': value, 'error': false, 'helper': ''}} 

        setIncomeFrequency(newPersonalStatus);
    }

    const [proofOfIncome, setProofOfIncome] = DataArray();
    const proofOfIncome_handler = (val, index) => {
        const value = val == 'check' ? proofOfIncome[index].value : val;

        const newPersonalStatus = [...proofOfIncome];

        if(value == ""){ newPersonalStatus[index] = {'value':value, 'error': true, 'helper': 'Proof of income is required'}}
        else { newPersonalStatus[index] = {'value': value, 'error': false, 'helper': ''}} 

        setProofOfIncome(newPersonalStatus);
    }

    const [workNumber, setWorkNumber] = DataArray();
    const workNumber_handler = (val, index) => {
        const value = val == 'check' ? workNumber[index].value : val;

        const newPersonalStatus = [...workNumber];

        if(value == ""){ newPersonalStatus[index] = {'value':value, 'error': true, 'helper': 'Work number required'}}
        else { newPersonalStatus[index] = {'value': value, 'error': false, 'helper': ''}} 

        setWorkNumber(newPersonalStatus);
    }

    const [workEmail, setWorkEmail] = DataArray();
    const workEmail_handler = (val, index) => {
        const value = val == 'check' ? workEmail[index].value : val;

        const newPersonalStatus = [...workEmail];

        if(value == ""){ newPersonalStatus[index] = {'value':value, 'error': true, 'helper': 'Work email required'}}
        else { newPersonalStatus[index] = {'value': value, 'error': false, 'helper': ''}} 

        setWorkEmail(newPersonalStatus);
    }

    const [workAddress, setWorkAddress] = DataArray();
    const workAddress_handler = (val, index) => {
        const value = val == 'check' ? workAddress[index].value : val;

        const newPersonalStatus = [...workAddress];

        if(value == ""){ newPersonalStatus[index] = {'value':value, 'error': true, 'helper': 'Work address required'}}
        else { newPersonalStatus[index] = {'value': value, 'error': false, 'helper': ''}} 

        setWorkAddress(newPersonalStatus);
    }

    const [sourceOfFund, setSourceOfFund] = DataArray();
    const sourceOfFund_handler = (val, index) => {
        const value = val == 'check' ? sourceOfFund[index].value : val;

        const newPersonalStatus = [...sourceOfFund];

        if(value == ""){ newPersonalStatus[index] = {'value':value, 'error': true, 'helper': 'Source of funds required'}}
        else { newPersonalStatus[index] = {'value': value, 'error': false, 'helper': ''}} 

        setSourceOfFund(newPersonalStatus);
    }

    // !###################################################################################################################
    //  ? SECOND ID
    // !###################################################################################################################

    const [personSecondId, setPersonSecondId] = DataArray();
    const personSecondId_handler = (val, index) => {
        const value = val == 'check' ? personSecondId[index].value : val;

        const newPersonalStatus = [...personSecondId];

        if(value == ""){ newPersonalStatus[index] = {'value': value, 'error': true, 'helper': 'Second ID is required'}}
        else { newPersonalStatus[index] = {'value': value, 'error': false, 'helper': ''}} 

        setPersonSecondId(newPersonalStatus);
    }

    const isAfterToday = (date) => {
        return new Date(date).valueOf() > new Date().valueOf();
    }

    const [personSecondIdType, setPersonSecondIdType] = DataArray();
    const personSecondIdType_handler = (val, index) => {
        const value = val == 'check' ? personSecondIdType[index].value : val;

        const newPersonalStatus = [...personSecondIdType];

        if(value == ""){ newPersonalStatus[index] = {'value':value, 'error': true, 'helper': 'ID type is required'}}
        else { newPersonalStatus[index] = {'value': value, 'error': false, 'helper': ''}} 

        setPersonSecondIdType(newPersonalStatus);
    }

    const [personSecondIdNumber, setPersonSecondIdNumber] = DataArray();
    const personSecondIdNumber_handler = (val, index) => {
        const value = val == 'check' ? personSecondIdNumber[index].value : val;

        const newPersonalStatus = [...personSecondIdNumber];

        if(value == ""){ newPersonalStatus[index] = {'value':value, 'error': true, 'helper': 'ID Number is required'}}
        else { newPersonalStatus[index] = {'value': value, 'error': false, 'helper': ''}} 

        setPersonSecondIdNumber(newPersonalStatus);
    }

    const [personSecondIdExpiration, setPersonSecondIdExpiration] = DateArray();
    const personSecondIdExpiration_handler = (val, index) => {
        const value = val == 'check' ? personSecondIdExpiration[index].value : val;

        const newPersonalStatus = [...personSecondIdExpiration];

        if(value == ""){ 
            newPersonalStatus[index] = {'value': value, 'error': true, 'helper': 'Id expiration date is required'}
        }else { 
            if(isAfterToday(value)){
                newPersonalStatus[index] = {'value': value, 'error': false, 'helper': ''}
            }else{
                newPersonalStatus[index] = {'value': value, 'error': true, 'helper': 'ID already expired'}
            }
        } 

        setPersonSecondIdExpiration(newPersonalStatus);
    }

    const [personSecondIdCountry, setPersonSecondIdCountry] = DataArrayCountry();
    const personSecondIdCountry_handler = (val, index) => {
        const value = val == 'check' ? personSecondIdCountry[index].value : val;

        const newPersonalStatus = [...personSecondIdCountry];

        if(value == ""){ newPersonalStatus[index] = {'value':value, 'error': true, 'helper': 'Country of issue is required'}}
        else { newPersonalStatus[index] = {'value': value, 'error': false, 'helper': ''}} 

        setPersonSecondIdCountry(newPersonalStatus);
    }


    const [agreeWithTerms, setAgreeWithTerms] = React.useState();
    const [termsAgreementError, setTermsAgreementError] = React.useState('');

    const agreeWithTerms_handler = (event) => {
        setAgreeWithTerms(event.target.checked);
     }

    const personalInfo = (index, target="Account holder ") => {
        return(
            <>     
            <hr class="line"/><br/>
              <fieldset class="person">
                <legend>
                    {target}    
                    {" "+index+" "}
                    ({personFirstName[index].value} {personMiddleName[index].value} { personLastName[index].value})
                
                </legend>
            
                    <div class="row">
                        <FormControl className={classes.formControl} variant="filled">
                                <InputLabel id="demo-controlled-open-select-label">Marital Status</InputLabel>
                                <Select
                                    labelId="demo-controlled-open-select-label"
                                    error={personStatus[index].error}
                                    value={personStatus[index].value}
                                    name={"marital_status"+index}
                                    onChange={e => {personStatus_handler(e.target.value, index)}}
                                    id="demo-controlled-open-select">
                                        <MenuItem value="Single">Single</MenuItem>
                                        <MenuItem value="Married">Married</MenuItem>
                                        <MenuItem value="Widowed">Widowed</MenuItem>
                                        <MenuItem value="Divorced">Divorced</MenuItem>
                                </Select>
                                <FormHelperText>{personStatus[index].helper}</FormHelperText>
                            </FormControl>

                            <FormControl className={classes.formControl} variant="filled">
                                <InputLabel id="demo-controlled-open-select-label">Choose Title</InputLabel>
                                <Select
                                    labelId="demo-controlled-open-select-label"
                                    error={personTitle[index].error}
                                    value={personTitle[index].value}
                                    name={"title"+index}
                                    onChange={e => {personTitle_handler(e.target.value, index)}}
                                    id="demo-controlled-open-select">
                                        <MenuItem value="Mr">Mr.</MenuItem>
                                        <MenuItem value="Mrs">Mrs.</MenuItem>
                                        <MenuItem value="Ms">Ms.</MenuItem>
                                        <MenuItem value="Dr">Dr.</MenuItem>
                                        <MenuItem value="Sen">Sen.</MenuItem>
                                        <MenuItem value="Hon">Hon.</MenuItem>
                                </Select>
                                <FormHelperText>{personTitle[index].helper}</FormHelperText>
                            </FormControl>
                    </div>

                        <div className="row">
                            <TextField 
                                error={personFirstName[index].error}
                                value={personFirstName[index].value}
                                label="First Name"
                                name={"fname"+index}
                                helperText={personFirstName[index].helper}
                                onChange={e => {personFirstName_handler(e.target.value, index)}}
                                variant="filled"
                                className="input"/>
                            
                            <TextField 
                                error={personMiddleName[index].error}
                                value={personMiddleName[index].value}
                                label="Middle Name"
                                name={"mname"+index}
                                helperText={personMiddleName[index].helper}
                                onChange={e => {personMiddleName_handler(e.target.value, index)}}
                                variant="filled"
                                className="input"/>

                            <TextField 
                                error={personLastName[index].error}
                                value={personLastName[index].value}
                                label="Last Name"
                                name={"lname"+index}
                                helperText={personLastName[index].helper}
                                onChange={e => {personLastName_handler(e.target.value, index)}}
                                variant="filled"
                                className="input"/>
                        </div>
                        <br/>

                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <KeyboardDatePicker
                                variant="filled"
                                format="MM/dd/yyyy"
                                id="date-picker-inline"
                                label="Date of birth"
                                name={"dateOfBirth"+index}
                                value={personDateOfBirth[index].value}
                                error={personDateOfBirth[index].error}
                                helperText={personDateOfBirth[index].helper}
                                onChange={e => {personDateOfBirth_handler(e, index)}}
                                className="date-input"
                                KeyboardButtonProps={{
                                    'aria-label': 'change date',
                                }}/>
                        </MuiPickersUtilsProvider>

                        <div class="row">
                            <FormControl className={classes.formControl} variant="filled">
                                <InputLabel id="countries-selector-label">Country of birth</InputLabel>
                                <Select
                                    labelId="countries-selector-label"
                                    name={"countryOfBirth"+index}
                                    error={personCountryOfBirth[index].error}
                                    value={personCountryOfBirth[index].value}
                                    onChange={e => {personCountryOfBirth_handler(e.target.value, index)}}
                                    id="countries-selector">
                                    {countryList}
                                </Select>
                                <FormHelperText>{personCountryOfBirth[index].helper}</FormHelperText>
                            </FormControl>

                            <FormControl className={classes.formControl} variant="filled">
                                <InputLabel id="countries-selector-label">Country of Residence</InputLabel>
                                <Select
                                    labelId="countries-selector-label"
                                    name={"countryOfResidence"+index}
                                    error={personCountryOfResidence[index].error}
                                    value={personCountryOfResidence[index].value}
                                    onChange={e => {personCountryOfResidence_handler(e.target.value, index)}}
                                    id="countries-selector">
                                    {countryList}
                                </Select>
                                <FormHelperText>{personCountryOfResidence[index].helper}</FormHelperText>
                            </FormControl>
                        </div>   
                        <div class="row">
                            <TextField
                                    id="outlined-secondary"
                                    name={"address"+index}
                                    error={personAddress[index].error}
                                    value={personAddress[index].value}
                                    helperText={personAddress[index].helper}
                                    onChange={e => {personAddress_handler(e.target.value, index)}}
                                    label="Address line 1"
                                    variant="filled"
                                    className="input"
                                />
                            <TextField
                                    id="outlined-secondary"
                                    name={"address2"+index}
                                    error={personAddress2[index].error}
                                    value={personAddress2[index].value}
                                    helperText={personAddress2[index].helper}
                                    onChange={e => {personAddress2_handler(e.target.value, index)}}
                                    label="Address line 2"
                                    variant="filled"
                                    className="input"
                                />
                        </div>
                        <div class="row">
                            <TextField
                                name={"proofOfAddress"+index}
                                error={proofOfAddress[index].error}
                                // value={proofOfAddress[index].value}
                                helperText={proofOfAddress[index].helper}
                                label="Upload copy or photo of Proof of Address"
                                onChange={e => {proofOfAddress_handler(e.target.value, index)}}
                                variant="filled"
                                type="file"
                                className="input"
                                inputProps={{ accept: "image/png, image/jpeg, application/pdf" }}
                                InputLabelProps={{
                                    shrink: true,
                                }}/>
                        </div>
                        <br/>
                        <label className="label">
                        Contact Information
                        </label><br/>

                        <div class="row">
                            {/* <TextField
                                id="outlined-secondary"
                                name={"homeNumber"+index}
                                error={personHomeNumber[index].error}
                                value={personHomeNumber[index].value}
                                helperText={personHomeNumber[index].helper}
                                label="Home Telephone Number"
                                onChange={e => {personHomeNumber_handler(e.target.value, index)}}
                                onKeyDown={enforceFormat}
                                onKeyUp={formatToPhone}
                                variant="filled"
                                className="input"
                            /> */}
                            <TextField
                                id="outlined-secondary"
                                name={"mobileNumber"+index}
                                error={personMobileNumber[index].error}
                                value={personMobileNumber[index].value}
                                helperText={personMobileNumber[index].helper}
                                label="Phone number"
                                onChange={e => {personMobileNumber_handler(e.target.value, index)}}
                                onKeyDown={enforceFormat}
                                onKeyUp={formatToPhone}
                                variant="filled"
                                className="input"
                            />
                        </div>

                        <div class="row">

                            <TextField
                                id="outlined-secondary"
                                name={"emailAddress"+index}
                                error={personEmailAddress[index].error}
                                value={personEmailAddress[index].value}
                                helperText={personEmailAddress[index].helper}
                                label="Email address"
                                onChange={e => {personEmailAddress_handler(e.target.value, index)}}
                                variant="filled"
                                className="input"/>
                        </div><br/>
                        <fieldset class="small">
                            <legend>Source of funds</legend>
                            <label className="question">
                                    Employment status
                            </label><br/>
                            <RadioGroup row aria-label="employement_type"  name="employement_type" error={personEmployed[index].error} defaultValue={personEmployed[index].value} onChange={e => {personEmployed_handler(e.target.value, index)}}>
                                <FormControlLabel
                                    value="employed"
                                    control={<Radio color="primary" />}
                                    label="Employed"
                                    labelPlacement="end"/>
                                <FormControlLabel 
                                    value="self" 
                                    control={<Radio color="primary" />} 
                                    label="Self employed" />
                                <FormControlLabel 
                                    value="unemployed" 
                                    control={<Radio color="primary" />} 
                                    label="Unemployed" />
                            </RadioGroup>
                            <label class="error">{personEmployed[index].helper}</label>
                            {
                                personEmployed[index].value == "employed"
                                ? <>
                                    <div class="row">
                                        <TextField
                                            id="outlined-secondary"
                                            name={"occupation"+index}
                                            error={occupation[index].error}
                                            value={occupation[index].value}
                                            helperText={occupation[index].helper}
                                            label="Occupation"
                                            onChange={e => {occupation_handler(e.target.value, index)}}
                                            variant="filled"
                                            className="x2"/>
                                        
                                        <FormControl className="x1" variant="filled">
                                            <InputLabel id="demo-controlled-open-select-label">Income Frequency</InputLabel>
                                            <Select
                                                labelId="demo-controlled-open-select-label"
                                                name={"incomeFrequency"+index}
                                                error={incomeFrequency[index].error}
                                                value={incomeFrequency[index].value}
                                                onChange={e => {incomeFrequency_handler(e.target.value, index)}}
                                                id="demo-controlled-open-select">
                                                    <MenuItem value="Weekly">Weekly</MenuItem>
                                                    <MenuItem value="Fortnightly">Fortnightly</MenuItem>
                                                    <MenuItem value="Monthly">Monthly</MenuItem>
                                            </Select>
                                            <FormHelperText>{incomeFrequency[index].helper}</FormHelperText>
                                        </FormControl>
                                        
                                        <TextField
                                            id="outlined-secondary"
                                            name={"income"+index}
                                            error={income[index].error}
                                            value={income[index].value}
                                            helperText={income[index].helper}
                                            type="number"
                                            label={`${incomeFrequency[index].value} Income (XCD)`}
                                            onChange={e => {income_handler(e.target.value, index)}}
                                            variant="filled"
                                            className="x1"/>
                                    </div>
                                  </>
                                : personEmployed[index].value == "self" || personEmployed[index].value == "unemployed"
                                  ? <>
                                        <div class="row">
                                            <TextField
                                                id="outlined-secondary"
                                                name={"sourceOfFund"+index}
                                                error={sourceOfFund[index].error}
                                                value={sourceOfFund[index].value}
                                                helperText={sourceOfFund[index].helper}
                                                label="Source of funds"
                                                onChange={e => {sourceOfFund_handler(e.target.value, index)}}
                                                variant="filled"
                                                className="x2"/>

                                            <FormControl className="x1" variant="filled">
                                                <InputLabel id="demo-controlled-open-select-label">Income Frequency</InputLabel>
                                                <Select
                                                    labelId="demo-controlled-open-select-label"
                                                    name={"incomeFrequency"+index}
                                                    error={incomeFrequency[index].error}
                                                    value={incomeFrequency[index].value}
                                                    onChange={e => {incomeFrequency_handler(e.target.value, index)}}
                                                    id="demo-controlled-open-select">
                                                        <MenuItem value="Weekly">Weekly</MenuItem>
                                                        <MenuItem value="Fortnightly">Fortnightly</MenuItem>
                                                        <MenuItem value="Monthly">Monthly</MenuItem>
                                                </Select>
                                                <FormHelperText>{incomeFrequency[index].helper}</FormHelperText>
                                            </FormControl>

                                            <TextField
                                                id="outlined-secondary"
                                                name={"income"+index}
                                                error={income[index].error}
                                                value={income[index].value}
                                                helperText={income[index].helper}
                                                type="number"
                                                label={`${incomeFrequency[index].value} Income ($)`}
                                                onChange={e => {income_handler(e.target.value, index)}}
                                                variant="filled"
                                                className="x1"/>
                                        </div>
                                    </>
                                  : ''
                                  
                            }
                            {
                                personEmployed.value == ""
                                ? ""
                                : <>
                                    <div class="row">
                                        <TextField
                                            error={proofOfIncome[index].error}
                                            name={"proofOfIncome"+index}
                                            // value={proofOfAddress[index].value}
                                            helperText={proofOfIncome[index].helper}
                                            label="Upload copy or photo of Proof of Income"
                                            onChange={e => {proofOfIncome_handler(e.target.value, index)}}
                                            variant="filled"
                                            type="file"
                                            className="input"
                                            inputProps={{ accept: "image/png, image/jpeg, application/pdf" }}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}/>
                                    </div>
                                  </>
                            }
                        </fieldset>

                        <fieldset class="small">
                            <legend>First ID Info:</legend>
                            {/* {
                                person1FirstId.value
                                ? <ImageThumb image={person1FirstId.value}/>
                                : ''
                            } */}
                            {personFirstId.value}
                            <div class="row">
                                <TextField
                                    error={personFirstId[index].error}
                                    name={"firstId"+index}
                                    // value={personFirstId[index].value}
                                    helperText={personFirstId[index].helper}
                                    label="Upload copy or photo of ID"
                                    onChange={e => {personFirstId_handler(e.target.value, index)}}
                                    variant="filled"
                                    type="file"
                                    inputProps={{ accept: "image/png, image/jpeg, application/pdf" }}                                    className="input"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}/>

                                <TextField
                                    name={"firstIDNumber"+index}
                                    error={personFirstIdNumber[index].error}
                                    value={personFirstIdNumber[index].value}
                                    helperText={personFirstIdNumber[index].helper}
                                    label="ID Number"
                                    onChange={e => {personFirstIdNumber_handler(e.target.value, index)}}
                                    variant="filled"
                                    className="input"/>
                            </div>
                            <div class="row">

                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <KeyboardDatePicker
                                        name={"firstIDExpiration"+index}
                                        variant="filled"
                                        format="MM/dd/yyyy"
                                        id="date-picker-inline"
                                        label="ID Expiration date"
                                        value={personFirstIdExpiration[index].value}
                                        error={personFirstIdExpiration[index].error}
                                        helperText={personFirstIdExpiration[index].helper}
                                        onChange={e => {personFirstIdExpiration_handler(e, index)}}
                                        className="date-input"
                                        KeyboardButtonProps={{
                                            'aria-label': 'change date',
                                        }}/>
                                </MuiPickersUtilsProvider>

                                <FormControl className={classes.formControl} variant="filled">
                                    <InputLabel id="countries-selector-label">Country of Issue</InputLabel>
                                    <Select
                                        name={"firstIdCountry"+index}
                                        labelId="countries-selector-label"
                                        error={personFirstIdCountry[index].error}
                                        value={personFirstIdCountry[index].value}
                                        onChange={e => {personFirstIdCountry_handler(e.target.value, index)}}
                                        id="countries-selector">
                                        {countryList}
                                    </Select>
                                    <FormHelperText>{personFirstIdCountry[index].helper}</FormHelperText>
                                </FormControl>
                            </div>
                        </fieldset>

                        <fieldset class="small">
                            <legend>Second ID Info:</legend>
                            {/* {
                                person1SecondId.value
                                ? <ImageThumb image={person1SecondId.value}/>
                                : ''
                            } */}
                            <div class="row">
                                <TextField
                                    name={"secondId"+index}
                                    error={personSecondId[index].error}
                                    // value={personSecondId[index].value}
                                    helperText={personSecondId[index].helper}
                                    label="Upload copy or photo of ID"
                                    onChange={e => {personSecondId_handler(e.target.value, index)}}
                                    variant="filled"
                                    type="file"
                                    className="input"
                                    inputProps={{ accept: "image/png, image/jpeg, application/pdf" }}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}/>
                                 <TextField
                                    name={"secondIDNumber"+index}
                                    error={personSecondIdNumber[index].error}
                                    value={personSecondIdNumber[index].value}
                                    helperText={personSecondIdNumber[index].helper}
                                    label="ID Number"
                                    onChange={e => {personSecondIdNumber_handler(e.target.value, index)}}
                                    variant="filled"
                                    className="input"/>
                            </div>
                            <div class="row">
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <KeyboardDatePicker
                                        name={"secondIDExpiration"+index}
                                        variant="filled"
                                        format="MM/dd/yyyy"
                                        id="date-picker-inline"
                                        label="ID Expiration date"
                                        value={personSecondIdExpiration[index].value}
                                        error={personSecondIdExpiration[index].error}
                                        helperText={personSecondIdExpiration[index].helper}
                                        onChange={e => {personSecondIdExpiration_handler(e, index)}}
                                        className="date-input"
                                        KeyboardButtonProps={{
                                            'aria-label': 'change date',
                                        }}/>
                                </MuiPickersUtilsProvider>

                                <FormControl className={classes.formControl} variant="filled">
                                    <InputLabel id="countries-selector-label">Country of issue</InputLabel>
                                    <Select
                                        name={"secondIDCountry"+index}
                                        labelId="countries-selector-label"
                                        error={personSecondIdCountry[index].error}
                                        value={personSecondIdCountry[index].value}
                                        onChange={e => {personSecondIdCountry_handler(e.target.value, index)}}
                                        id="countries-selector">
                                        {countryList}
                                    </Select>
                                    <FormHelperText>{personSecondIdCountry[index].helper}</FormHelperText>
                                </FormControl>
                            </div>
                        </fieldset>
              </fieldset>
            </>
        )
    }

    function range(start, end) {
        return Array(end - start + 1).fill().map((_, idx) => start + idx)
    }


    const step1 = () => {

        const notJointAccount = () => {
            return(
                <>
                  {
                      services()
                  }
                  {
                     personalInfo(1)
                  }
                </>
            )
        }

        const accountSettings = () => {
            const numAccountHoldersArray = range(1, numAccountHolders.value);

            return(
                <>
                  <Alert severity="error" id="personalInfoError" style={{display: personalInfoError}}>
                    <AlertTitle>Error</AlertTitle>
                    <h3>Please correct the following errors </h3>
                  </Alert>
                  {
                        accountType.value == "savings" || accountType.value == "business or group"
                        ? <>
                            <label className="question">
                                Is this a joint account?
                            </label><br/>
                            <RadioGroup row aria-label="position" name="position" value={isJointAccount.value} onChange={isJointAccount_handler}>
                                <FormControlLabel
                                    value="yes"
                                    control={<Radio color="primary" />}
                                    label="Yes"
                                    labelPlacement="end"/>
                                <FormControlLabel 
                                    value="no" 
                                    control={<Radio color="primary" />} 
                                    label="No" />
                            </RadioGroup><br/>
                          </>
                        : ''
                    }
                    {
                        isJointAccount.value == "yes"
                        ? <>
                            <label className="question">
                                Please enter number of account holders.
                            </label><br/>
                            <TextField 
                                error={numAccountHolders.error}
                                value={numAccountHolders.value}
                                label="Number of account holders"
                                helperText={numAccountHolders.helper}
                                onChange={numAccountHolders_handler}
                                type="number"
                                variant="filled"
                                className="input"/>
                                <br/><br/>
                          </>
                        : isJointAccount.value == "no"
                           ? notJointAccount()
                           : ''
                    }
                    {
                        isJointAccount.value == "yes" && numAccountHolders.value < 4 && numAccountHolders.value > 1
                        ? <>{services()}{numAccountHoldersArray.map((item, key) => personalInfo(item))}</>
                        : ''
                    }
                </>
            )
        }

        const services = () => {
            return(
                <>    
                    <label className="question">
                        Select the services you wish to apply for.
                    </label><br/>

                    <div class="options">
                        <FormControlLabel 
                            control={<Checkbox checked={serviceOptions.savings} color="primary" onChange={serviceOptions_handler} name="savings" disabled />} label="Regular Savings" />
                        <FormControlLabel 
                            control={<Checkbox checked={serviceOptions.fixed} color="primary" onChange={serviceOptions_handler} name="fixed" />} label="Fixed Deposit" />
                        <FormControlLabel 
                            control={<Checkbox checked={serviceOptions.online} color="primary" onChange={serviceOptions_handler} name="online" />} label="Online Banking" />
                        <FormControlLabel 
                            control={<Checkbox checked={serviceOptions.atm} color="primary" onChange={serviceOptions_handler} name="atm" />} label="ATM Services" />
                    </div><br/>
                </>
            )
        }

        const childInfo = () => {
            const number_of_parent_guardian = parentGuardian.value

            const numParentGuardianArray = range(1, number_of_parent_guardian);

            return(
                <>
                    <label className="question">
                        Number of parents/ guardians to be added
                    </label><br/>
                    <TextField 
                        error={parentGuardian.error}
                        value={parentGuardian.value}
                        label="Number of parents/guardians"
                        helperText={parentGuardian.helper}
                        onChange={parentGuardian_handler}
                        type="number"
                        variant="filled"
                        className="input"/>
                        <br/><br/><hr class="line"/><br/><br/>
                    {
                        parentGuardian.value < 4 && parentGuardian.value >= 1
                        ? <>
                            
                            <fieldset>
                                <legend>Child Information</legend>

                                <Alert severity="error" id="childInfoError" style={{display: childInfoError}}>
                                    <AlertTitle>Error</AlertTitle>
                                    Please correct the following errors 
                                </Alert>
                    
                                <div className="row">
                                    <TextField 
                                        name="childFirstName"
                                        error={childFirstName.error}
                                        value={childFirstName.value}
                                        label="First Name"
                                        helperText={childFirstName.helper}
                                        variant="filled"
                                        onChange={childFirstName_handler}
                                        className="input"/>
                                    
                                    <TextField 
                                        name="childMiddleName"
                                        error={childMiddleName.error}
                                        value={childMiddleName.value}
                                        label="Middle Name"
                                        helperText={childMiddleName.helper}
                                        variant="filled"
                                        onChange={childMiddleName_handler}
                                        className="input"/>

                                    <TextField 
                                        name="childLastName"
                                        error={childLastName.error}
                                        value={childLastName.value}
                                        label="Last Name"
                                        helperText={childLastName.helper}
                                        variant="filled"
                                        onChange={childLastName_handler}
                                        className="input"/>
                                </div>

                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <KeyboardDatePicker
                                        name="childDateOfBirth"
                                        variant="filled"
                                        format="MM/dd/yyyy"
                                        id="date-picker-inline"
                                        label="Date of birth"
                                        value={childDateOfBirth.value}
                                        error={childDateOfBirth.error}
                                        helperText={childDateOfBirth.helper}
                                        onChange={childDateOfBirth_handler}
                                        className="date-input"
                                        KeyboardButtonProps={{
                                            'aria-label': 'change date',
                                        }}/>
                                </MuiPickersUtilsProvider>

                                <div class="row">
                                    <FormControl className={classes.formControl} variant="filled">
                                        <InputLabel id="countries-selector-label">Country of birth</InputLabel>
                                        <Select
                                            name="childCountryOfBirth"
                                            labelId="countries-selector-label"
                                            error={childCountryOfBirth.error}
                                            value={childCountryOfBirth.value}
                                            onChange={childCountryOfBirth_handler}
                                            id="countries-selector">
                                            {countryList}
                                        </Select>
                                        <FormHelperText>{childCountryOfBirth.helper}</FormHelperText>
                                    </FormControl>

                                    <FormControl className={classes.formControl} variant="filled">
                                        <InputLabel id="countries-selector-label">Country of Residence</InputLabel>
                                        <Select
                                            name="childCountryOfResidence"
                                            error={childCountryOfResidence.error}
                                            labelId="countries-selector-label"
                                            id="countries-selector"
                                            value={childCountryOfResidence.value}
                                            onChange={childCountryOfResidence_handler}>
                                            {countryList}
                                        </Select>
                                        <FormHelperText>{childCountryOfResidence.helper}</FormHelperText>
                                    </FormControl>
                                </div>
                                <div class="row">
                                    <TextField
                                        name="childAddressLine1"
                                        error={childAddressLine1.error}
                                        id="outlined-secondary"
                                        label="Address Line 1"
                                        value={childAddressLine1.value}
                                        helperText={childAddressLine1.helper}
                                        onChange={childAddressLine1_handler}
                                        variant="filled"
                                        className="input"
                                    />
                                    <TextField
                                        name="childAddressLine2"
                                        error={childAddressLine2.error}
                                        id="outlined-secondary"
                                        label="Address Line 2"
                                        value={childAddressLine2.value}
                                        helperText={childAddressLine2.helper}
                                        onChange={childAddressLine2_handler}
                                        variant="filled"
                                        className="input"
                                    />
                                </div>
                                <br/><br/>
                                <fieldset>
                                    <legend>Child School Information</legend>
                                    <div class="row">
                                        <TextField
                                            name="childSchool"
                                            error={childSchool.error}
                                            label="Child School"
                                            helperText={childSchool.helper}
                                            value={childSchool.value}
                                            onChange={childSchool_handler}
                                            variant="filled"
                                            className="input"/>

                                        <TextField
                                            name="childSchoolAddress"
                                            error={childSchoolAddress.error}
                                            label="School address"
                                            helperText={childSchoolAddress.helper}
                                            value={childSchoolAddress.value}
                                            onChange={childSchoolAddress_handler}
                                            variant="filled"
                                            className="input"/>
                                    </div>
                                </fieldset><br/>
                                <fieldset>
                                    <legend>Child Birth certificate</legend>
                                    <div class="row">
                                        <TextField
                                            name="childBirthCertificate"
                                            error={childBirthCertificate.error}
                                            label="Birth certificate"
                                            helperText={childBirthCertificate.helper}
                                            // value={childBirthCertificate.value}
                                            onChange={childBirthCertificate_handler}
                                            // onChange={(e) => childBirthCertificate_handler(e.target.files[0])}
                                            variant="filled"
                                            type="file"
                                            className="input"
                                            inputProps={{ accept: "image/png, image/jpeg, application/pdf" }}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}/>
                                    </div>
                                </fieldset>
                            </fieldset>
                            {numParentGuardianArray.map((item, key) => personalInfo(item, "Parent/ Guardian"))}

                          </>
                        : ''
                    }
                </>
            )
        }

        return(
            <form id="SavingsForm" action="https://svcbl.com/api/NewAccount.php" method="post" enctype="multipart/form-data">
                <label className="label" style={{fontSize: 40}}>
                    Ready to open an account?
                </label>

                <Alert severity="error" id="formError" style={{display: isFormError}}>
                    <AlertTitle>Error</AlertTitle>
                    <h3>{formError}</h3>
                </Alert>

                <p>
                  Opening an account at SVCB can be done in 3 easy steps. Complete 99% of the process online allowing you to sign up from the comfort of your home.                
                </p><br/>
 
                <label className="question">
                    Select the type of account you looking to open.
                </label><br/>
                <RadioGroup row aria-label="account_type" name="account_type" value={accountType.value} defaultValue={accountType.value} onChange={accountType_handler}>
                    <FormControlLabel
                        value="savings"
                        control={<Radio color="primary" />}
                        label="Savings Account"
                        labelPlacement="end"/>
                    <FormControlLabel 
                        value="business or group" 
                        control={<Radio color="primary" />} 
                        label="Business/ Group Account" />
                    <FormControlLabel 
                        value="penny" 
                        control={<Radio color="primary" />} 
                        label="Penny Savers" />
                </RadioGroup><br/>

                {
                    accountType.value == "savings" || accountType.value == "business or group"
                    ? accountSettings()
                    : ''
                }
                {
                    accountType.value == "penny"
                    ? childInfo()
                    : ''
                }
                {
                   termsView
                }

            </form>
        )
    }

    function terms(){
        return(
            <>
              <br/>
                <fieldset id="terms" class="terms">
                    <legend>ACCOUNT AGREEMENT</legend>
                    <p>
                    In consideration of The St. Vincent Co-operative Bank Limited keeping in its books an account of the type indicated above, opened by the undersigned, it is hereby expressly agreed between the undersigned and said bank, that the latter
may from time to time make and debit to said account its usual charges for the keeping of an account which the undersigned agrees to pay. 
                    </p>
                    <p>
                    In the case of the above account being a joint account, eash of the undersigned in order effectually to constitute said joint account, hereby assigns and transfers to all the undersigned jointly and to the survivor or survivors of them, all
monies heretofore or hereafter at credit of said account and accrued interest thereon, if any, to be the joint property of the undersigned and the property of the survivor or survivors of them  
                    </p>
                    <p>
                      I/We are aware that the St. Vincent Co-operative Bank Limited will not enter into any transaction with funds related to any illigal activity. I/We hereby declare that none of my/our activities, in any way, relate o the illegal drug trade;
prostitution; armaments; money laundering or any other illegal activity
                    </p>
                    <p>
                    In accordance with the requirements of the proceeds of Crime and Money Laundering (Prevention) Act 2001, and the regulations and Anti-Money Laundering Guidance Notes Issued therunder, the Bank is required to obtain independent
verification, if it so wishes, of the information given by the applicant of business
                    </p>
                    <p>
                    I/We confirm that the above information submitted to be true and correct, and agree to the above terms and conditions. Also, that I/We understand the provisions of the above Act and the consequences for failing to comply with the
regulations
                    </p>
                    <p>
                      I/We undertake that I/we shall be wholly liable/responsible for all transactions done on my/our account through my/our ATM card. I/We hereby declare that I/we would not enter into any transaction with funds related to any illegal activity or
which is designed to facilitate illegal activity
                    </p>
                    <p>
                    I/We further declare that I/we understand that I/we may be required to provide information in accordance with the provisions of the Proceeds of Crime Act 2013 and the Anti-money Laundering and Terrorist Financing Regulation 2014 and
consent to the Bank disclosing this information to relevant law enforcement agencies, if required.
                    </p>

                    {
                        serviceOptions.atm
                        ? <>
                            <h1>ATM Agreement</h1>
                            <p>
                            The customer agrees to collect his/her ATM Card within thirty-one days of submitting the application form. Failure to do so will result in the ATM Card being destroyed and the customer’s account being debited the cost of embossing said
        ATM Card. Any future attempt to acquire an ATM card will be deemed a replacement and a cost of $20.00 charged upon collection.
                            </p>
                            <p>
                            The ATM Card must be signed promptly upon receipt by the customer and can only be used after it has been signed. The customer is responsible for ensuring the safety of the ATM Card and will not disclose the PIN number to any person
        who is not its Authorised user
                            </p>
                            <p>
                            The Bank is authorized to charge the customer’s account for any and all funds obtained by the customer through the use of the ATM Card.
                            </p>
                            <p>
                            The ATM Card cannot be used to transfer money in or out of the account until the funds have been validated by the Bank.
                            </p>
                            <p>
                            The ATM Card is the property of the Bank and the Bank can at any time terminate its use without notice. The card is not transferable. The customer agrees to surrender the card upon its demand from the Bank.
                            </p>
                            <p>
                            The ATM Card cannot be used for illegal transaction(s) in contravention with the Proceeds of Crime Act 2013 and the Anti-money Laundering and Terrorist Financing Regulation 2014.
                            </p>
                            <p>
                            The customer agrees to notify the Bank immediately if the ATM Card is lost or stolen. If a new ATM Card is issued the customer agrees to pay a replacement fee for such replacement card and that their account is debited for such fee. 
                            </p>
                            <p>
                            The customer agrees to pay all legal expenses if for any reason the Bank has to use legal means to collect amounts that may become outstanding under this ATM Card Holder Agreement.
                            </p>
                            <p>
                            If the account is a joint account, two (2) ATM Cards may be issued upon the signing of the agreement by both parties. Each account holder may exercise all rights under this agreement individually and shall be jointly and severally
        responsible for any obligation incurred by such exercise. Each account holder may use the ATM Card(s) and agree to or terminate the agreement or close any such accounts. Each card holder agrees that any action taken by one party will
        be binding upon each account holder
                            </p>
                            <p>
                            The St. Vincent Co-operative Bank Limited makes no claims or warranties with respect to the system or any equipment to be used in this process and shall not be liable for any failure or malfunction of same.
                            </p>
                            <p>
                            The Bank reserves the right to make amendments to this agreement at any time and also to make immediate changes in order to maintain or restore security. The Bank will also notify the customer of any changes that will affect the
        customer’s use of the ATM Card.
                            </p>
                            <p>
                            All correspondence to the customer shall be sent to the address given in the application and the customer agrees to notify the Bank whenever there is a change in same.
        If an investigation is required from use of a BOSVG machine, this bank charges $80.00 that must be paid before the investigation commences.
                            </p>
                          </>
                        : ""
                    }

                    {
                        serviceOptions.online
                        ? <>
                            <h1>Online Banking</h1>
                            <p>
                            I/WE, the undersigned hereby authorize your Bank to accept all tested and untested instructions, which may be related but not limited to Payments and or Transfer of Funds, given by me or on my behalf by telephone, email or otherwise,
        regarding any or all of my accounts (either existing or opened at a future date) with yourselves, subject to any written restrictions imposed by me on your Bank to the issuance of such instructions.
        Regarding instructions issued, which are not received simultaneously upon issuance, I agree that the instructions shall be deemed to have been issued only upon their receipt by your Bank. Regarding instructions issued by electronic and
        or mechanical processes, I accept the risk of equipment malfunctioning, including paper shortage, transmission errors, omissions and distortions.
        It is agreed that the risks of misunderstanding and errors, and the risks of instructions being given by unauthorized parties, are my own and that your Bank shall not be held responsible for any loss, liability or expenses that may result from
        misunderstanding, errors and unauthorized instructions. I hereby undertake to indemnify your Bank from and against all actions, proceedings, damages, cost, claims, demands, and expenses by reason of you following such instructions.
        Your Bank may at any time at your discretion, refuse to execute my instructions or any part thereof, without incurring any responsibility for loss, liability or expense arising out of such refusal, providing that such refusal is reasonable given
        the circumstances.
        The rights and obligations of the parties hereto shall be governed by and interpreted in accordance with the laws of St. Vincent and the Grenadines.
                            </p>
                          </>
                        : ""
                    }
                    
                </fieldset>

                <div class="options">
                    
                     <FormControlLabel 
                        control={<Checkbox checked={agreeWithTerms} color="primary" onChange={agreeWithTerms_handler} />} label="I agree with the terms and services" />
                </div>
                <span id="termsOfServiceError" class="error">{termsAgreementError}</span>
                <br/>
            </>
        )
    }

    const [employementStatus, setEmployementStatus] = React.useState();
    const employementStatusHandler = (event) => {
        setEmployementStatus(event.target.value);
    }

    const step2 = () => {
        return(
            <>
              {
                  accountType == "penny"
                  ? personalInfo()
                  : ""
              }
            </>
        )
    }

    const step3 = () => {
        return(
            <>
              <h1>Step3</h1>
            </>
        )
    }
    
    return(
         <>
            <Note/>
            <Header/>
            
            {/* <PageTop/> */}
            {
                loader
                ? <Loader/>
                : ''
            }
            <br/>
            <div className="page_title spacer">
               <label className='fancy'>Savings Application</label>
             </div>
 
            <div class="application">
                <div class="content">
                   <div class="questions">
                       {
                           steps < 1
                           ? step1()
                           : steps == 1
                              ? step2()
                              : steps == 2
                                 ? step3()
                                 : steps == 3
                                    ? 'step4()'
                                    : 'error'
                       }
                   </div>
                </div><br/>

                <div class="action">
                    {
                        steps >= 1
                        ? <Button size="large" variant="contained" className="backBtn" onClick={() => prevStep()}>
                                <KeyboardArrowLeft /> 
                                Back
                            </Button>
                        : <h1></h1>
                    }

                    {
                        steps < maxSteps
                        ? <Button size="large" variant="contained" className="nextBtn" onClick={() => nextStep()}>
                              Next
                              <KeyboardArrowRight /> 
                         </Button>
                        : <Button size="large" variant="contained" className="nextBtn" onClick={() => lastStep()}>
                              Finish
                              <KeyboardArrowRight /> 
                          </Button>
                    }
                </div>
                <br/>
                <progress id="file" value={steps * 10} max="100"> 32% </progress>
                
            </div><br/><br/><br/><br/><br/><br/>
            <Footer/>
         </>
    )
}