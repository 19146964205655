import React, { useEffect } from 'react'
import '../../style/back_to_school_2022.css'

import AOS from 'aos'
import 'aos/dist/aos.css'

import PageTop from '../../components/pageTop'
import Header from '../../components/header.js'
import Footer from '../../components/footer'

import ScholarshipBursaryImg from '../../assets/img/scholarship_bursary.jpg'
import SBBackPackImg from '../../assets/img/scholarship.png'
import EducationSteps from '../../assets/img/education-steps.png'
import LittleGirlWithBooks from '../../assets/img/little_girl.png'
import BackToSchoolText from '../../assets/img/back-to-school-text.png'

import BookImg from '../../assets/img/book.png'

import capImg from '../../assets/img/cap.png'

export default function Scholarship_Bursary(){

    useEffect(() => {
        AOS.init({ duration: 1000 });
        window.scrollTo(0, 0);
    }, []);

   return(
       <div class="promotions">
          <Header class="promotions"/>
          <div class="promo">
              {/* <div style={{width: 400, height: 400}}></div> */}
              <img data-aos="fade-up" class="mainImage" src={LittleGirlWithBooks}/>
              <img data-aos="fade-up" class="textImage" src={BackToSchoolText}/>

              <span data-aos="fade-up" class="span voucherText" style={{textAlign: 'left', fontSize: 30, textTransform: 'none', textAlign: 'center'}}>VOUCHERS</span><br/>

            
              <ol class="rules">
                  <li data-aos="fade-left" style={{fontSize: 25}}>Random selection will be done on a weekly for existing Penny Savers.</li>
                  <li data-aos="fade-left" style={{fontSize: 25}}>Any existing Penny savers, where a transaction has been completed within the last 6 months will qualify for vouchers to assist with their “back to school” purchases. Inclusive of New accounts and reactivations.</li>
                  <li data-aos="fade-left" style={{fontSize: 25}}>Sign up for a new Penny Saver account and become eligible for back-to-school Vouchers..</li>
                  <li data-aos="fade-left" style={{fontSize: 25}}>Vouchers will be redeemable up to October 31st, 2022. Vouchers will be redeemable at Coreas City Store, Jax Enterprises and Gaymes Book Centre.</li>
                  <li data-aos="fade-left" style={{fontSize: 25}}>There will be 8 winners drawn weekly for the duration of the promotion.</li>
                  <li data-aos="fade-left" style={{fontSize: 25}}>Photo Ops and publication of ALL presentations will be done on SVCBL social media platforms.</li>

              </ol>

              <br/><br/>

              <span class="span instructions" style={{textAlign: 'center', color: '#72a6e1', fontSize: 30}}>PROMOTION RUNS FROM  <br/><a style={{textTransform: 'none', color: '#ffe800', textDecoration: 'none'}}>AUGUST 22ND - SEPTEMBER 30TH 2022</a></span>
              <br/><br/>

              <span data-aos="fade-up" class="span" style={{color: '#33ccff', textTransform: 'uppercase'}}>The Penny Bank and so much more</span>
              <span data-aos="fade-up" class="span" style={{color: '#fff', textTransform: 'uppercase', fontSize: '20px'}}>SVCBL Terms and Conditions Apply</span>
          </div>
          <Footer/>
       </div>
   )
}