import React from 'react';
import '../style/login.css';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';

// import MobileBanking from '../components/mobile_banking'

import { Link } from 'react-router-dom';

function Login(){
    return(
      <div class="login">
        <label class="formLabel">Online Banking</label>
        <form className="login-form">
            <TextField className="input" id="userId" label="User ID"/>
            <TextField className="input" id="password" type="password" label="Password"/>
            <FormControlLabel control={<Checkbox name="checkedC" color="primary" />} label="Remember Me" />
 
            <Button variant="contained" color="primary">
              Sign In
            </Button>
        </form>
        <a href="https://secure.svcooperativebank.com/#/forgot-password" target="_blank">
          <Button className="link" color="primary">Forgot User ID or Password?</Button>
        </a>
        
        <Link to="apply/onlineBanking">
           <Button className="link" color="primary">Not registered? Signup</Button>
        </Link> 
      </div>
    )
}

export default Login;