import '../style/news.css';

import { Link } from 'react-router-dom';

import logo from '../assets/article.jpg';
import AMG2023 from '../assets/artwork/AGM2023.png';
import ECCB_Baner from '../assets/article/What_is_DCash.jpg';
import RAImage1 from '../assets/img/news/renaldo_andrews_feature.jpeg';

import Scholarship_bursary from '../assets/img/PENNYBANK_SCHOLARSHIPS_2022.png'
import Chato_Fun_day from '../assets/img/news/Chato_Fun_Day/CFD143.jpg';
import PlayazYouthAcademy from '../assets/img/news/Playaz_Youth_Academy/1.jpg';

function News(){
    return(
        <div class="news">
        <div class="wrap">
          <div class="label" data-aos="zoom-in">
            <label>Latest News</label>
          </div>
          <div class="articles">

          <a href="https://svcbl.com/pdf/Vacancy/Risk-Officer-Advert.pdf" target="_blank">
            <div class="card" data-aos="fade-up">
                <div>
                  <img src={logo}/>
                  <h3>Vacancy</h3>
                </div>
                <div>
                  <span>13th August, 2024</span>
                  <label className='truncate'>Risk Officer</label>

                  <a class="read-more">
                    <div>
                      <svg viewBox="0 0 24 24">
                          <path fill="currentColor" d="M4,11V13H16L10.5,18.5L11.92,19.92L19.84,12L11.92,4.08L10.5,5.5L16,11H4Z" />
                      </svg>
                      
                      <label>Read More</label>
                    </div>
                  </a>
                </div>
            </div>
          </a>

		  
		  <a href="https://svcbl.com/pdf/Financial-Statement/2024.pdf" target="_blank">
                <div class="card" data-aos="fade-up">
                <div>
                  <img src={logo}/>
                  <h3>FINANCIAL STATEMENT</h3>
                </div>
                <div>
                  <span>30th April, 2024</span>
                  <label>2024 Audited Financial Statement</label>

                  <a class="read-more">
                    <div>
                      <svg viewBox="0 0 24 24">
                          <path fill="currentColor" d="M4,11V13H16L10.5,18.5L11.92,19.92L19.84,12L11.92,4.08L10.5,5.5L16,11H4Z" />
                      </svg>
                      
                      <label>Read More</label>
                    </div>
                  </a>
                </div>
              </div></a>

          <a href="https://svcbl.com/pdf/Abandoned-Properties/2024.pdf" target="_blank">
                <div class="card" data-aos="fade-up">
                <div>
                  <img src="https://svcbl.com/img/Notice.png"/>
                  <h3>NEWS</h3>
                </div>
                <div>
                  <span>31 January, 2024</span>
                  <label>Abandoned Properties</label>

                  <a class="read-more">
                    <div>
                      <svg viewBox="0 0 24 24">
                          <path fill="currentColor" d="M4,11V13H16L10.5,18.5L11.92,19.92L19.84,12L11.92,4.08L10.5,5.5L16,11H4Z" />
                      </svg>
                      
                      <label>Read More</label>
                    </div>
                  </a>
                </div>
              </div></a>

          <a href="https://svcbl.com/pdf/Vacancy/Head-of-Internal-Audit.pdf" target="_blank">
            <div class="card" data-aos="fade-up">
                <div>
                  <img src={logo}/>
                  <h3>Vacancy</h3>
                </div>
                <div>
                  <span>20th December, 2023</span>
                  <label className='truncate'>Head of Internal Audit</label>

                  <a class="read-more">
                    <div>
                      <svg viewBox="0 0 24 24">
                          <path fill="currentColor" d="M4,11V13H16L10.5,18.5L11.92,19.92L19.84,12L11.92,4.08L10.5,5.5L16,11H4Z" />
                      </svg>
                      
                      <label>Read More</label>
                    </div>
                  </a>
                </div>
            </div>
          </a>

          <a href="https://svcbl.com/pdf/Vacancy/Commercial-Loans-Manager.pdf" target="_blank">
            <div class="card" data-aos="fade-up">
                <div>
                  <img src={logo}/>
                  <h3>Vacancy</h3>
                </div>
                <div>
                  <span>20th December, 2023</span>
                  <label className='truncate'>Commercial Loans Manager</label>

                  <a class="read-more">
                    <div>
                      <svg viewBox="0 0 24 24">
                          <path fill="currentColor" d="M4,11V13H16L10.5,18.5L11.92,19.92L19.84,12L11.92,4.08L10.5,5.5L16,11H4Z" />
                      </svg>
                      
                      <label>Read More</label>
                    </div>
                  </a>
                </div>
            </div>
          </a>


          <a href="https://svcbl.com/pdf/AGM-Notice-2023.pdf" target="_blank">
                <div class="card" data-aos="fade-up">
                <div>
                  <img src={logo}/>
                  <h3>AGM</h3>
                </div>
                <div>
                  <span>21st September, 2023</span>
                  <label className='truncate'>Annual General Meeting</label>

                  <a class="read-more">
                    <div>
                      <svg viewBox="0 0 24 24">
                          <path fill="currentColor" d="M4,11V13H16L10.5,18.5L11.92,19.92L19.84,12L11.92,4.08L10.5,5.5L16,11H4Z" />
                      </svg>
                      
                      <label>Read More</label>
                    </div>
                  </a>
                </div>
              </div></a>

          <a href="https://svcbl.com/pdf/Internal_Auditor_2023.pdf" target="_blank">
                <div class="card" data-aos="fade-up">
                <div>
                  <img src={logo}/>
                  <h3>Vacancy</h3>
                </div>
                <div>
                  <span>18th July, 2023</span>
                  <label className='truncate'>Internal Auditor</label>

                  <a class="read-more">
                    <div>
                      <svg viewBox="0 0 24 24">
                          <path fill="currentColor" d="M4,11V13H16L10.5,18.5L11.92,19.92L19.84,12L11.92,4.08L10.5,5.5L16,11H4Z" />
                      </svg>
                      
                      <label>Read More</label>
                    </div>
                  </a>
                </div>
              </div></a>

          <a href="https://svcbl.com/pdf/Risk_and_Compliance_Officer_2023.pdf" target="_blank">
                <div class="card" data-aos="fade-up">
                <div>
                  <img src={logo}/>
                  <h3>Vacancy</h3>
                </div>
                <div>
                  <span>17th July, 2023</span>
                  <label className='truncate'>Risk and Compliance Officer</label>

                  <a class="read-more">
                    <div>
                      <svg viewBox="0 0 24 24">
                          <path fill="currentColor" d="M4,11V13H16L10.5,18.5L11.92,19.92L19.84,12L11.92,4.08L10.5,5.5L16,11H4Z" />
                      </svg>
                      
                      <label>Read More</label>
                    </div>
                  </a>
                </div>
              </div></a>

          <a href="https://svcbl.com/pdf/Customer_Service_Manager_2023.pdf" target="_blank">
                <div class="card" data-aos="fade-up">
                <div>
                  <img src={logo}/>
                  <h3>Vacancy</h3>
                </div>
                <div>
                  <span>17th July, 2023</span>
                  <label className='truncate'>Customer Service Manager</label>

                  <a class="read-more">
                    <div>
                      <svg viewBox="0 0 24 24">
                          <path fill="currentColor" d="M4,11V13H16L10.5,18.5L11.92,19.92L19.84,12L11.92,4.08L10.5,5.5L16,11H4Z" />
                      </svg>
                      
                      <label>Read More</label>
                    </div>
                  </a>
                </div>
              </div></a>


          <a href="https://svcbl.com/pdf/Financial-Statement/2023.pdf" target="_blank">
                <div class="card" data-aos="fade-up">
                <div>
                  <img src={logo}/>
                  <h3>FINANCIAL STATEMENT</h3>
                </div>
                <div>
                  <span>27th April, 2023</span>
                  <label>2023 Audited Financial Statement</label>

                  <a class="read-more">
                    <div>
                      <svg viewBox="0 0 24 24">
                          <path fill="currentColor" d="M4,11V13H16L10.5,18.5L11.92,19.92L19.84,12L11.92,4.08L10.5,5.5L16,11H4Z" />
                      </svg>
                      
                      <label>Read More</label>
                    </div>
                  </a>
                </div>
              </div></a>

          <a href="https://svcbl.com/pdf/Customer%20Service%20Manager.pdf" target="_blank">
                <div class="card" data-aos="fade-up">
                <div>
                  <img src={logo}/>
                  <h3>Vacancy</h3>
                </div>
                <div>
                  <span>6th April, 2023</span>
                  <label className='truncate'>Customer Service Manager</label>

                  <a class="read-more">
                    <div>
                      <svg viewBox="0 0 24 24">
                          <path fill="currentColor" d="M4,11V13H16L10.5,18.5L11.92,19.92L19.84,12L11.92,4.08L10.5,5.5L16,11H4Z" />
                      </svg>
                      
                      <label>Read More</label>
                    </div>
                  </a>
                </div>
              </div></a>

          <a href="https://svcbl.com/download/SVCBL-Scholarship-Bursary-Form.docx" target="_blank">
                <div class="card" data-aos="fade-up">
                <div>
                  <img src={logo}/>
                  <h3>NEWS</h3>
                </div>
                <div>
                  <span>4 April, 2023</span>
                  <label>Scholarships and bursaries</label>

                  <a class="read-more">
                    <div>
                      <svg viewBox="0 0 24 24">
                          <path fill="currentColor" d="M4,11V13H16L10.5,18.5L11.92,19.92L19.84,12L11.92,4.08L10.5,5.5L16,11H4Z" />
                      </svg>
                      
                      <label>Download</label>
                    </div>
                  </a>
                </div>
              </div></a>

          <a href="https://svcbl.com/pdf/Abandoned-Properties/2023.pdf" target="_blank">
                <div class="card" data-aos="fade-up">
                <div>
                  <img src="https://svcbl.com/img/Notice.png"/>
                  <h3>NEWS</h3>
                </div>
                <div>
                  <span>31 January, 2023</span>
                  <label>Abandoned Properties</label>

                  <a class="read-more">
                    <div>
                      <svg viewBox="0 0 24 24">
                          <path fill="currentColor" d="M4,11V13H16L10.5,18.5L11.92,19.92L19.84,12L11.92,4.08L10.5,5.5L16,11H4Z" />
                      </svg>
                      
                      <label>Read More</label>
                    </div>
                  </a>
                </div>
              </div></a>

          <a href="https://svcbl.com/pdf/Vacancy/CSR-Job-Advert.pdf" target="_blank">
                <div class="card" data-aos="fade-up">
                <div>
                  <img src={logo}/>
                  <h3>Vacancy</h3>
                </div>
                <div>
                  <span>9th January, 2023</span>
                  <label className='truncate'>Customer Service Representative</label>

                  <a class="read-more">
                    <div>
                      <svg viewBox="0 0 24 24">
                          <path fill="currentColor" d="M4,11V13H16L10.5,18.5L11.92,19.92L19.84,12L11.92,4.08L10.5,5.5L16,11H4Z" />
                      </svg>
                      
                      <label>Read More</label>
                    </div>
                  </a>
                </div>
              </div></a>

          <a href="https://svcbl.com/pdf/Vacancy/CEO.pdf" target="_blank">
                <div class="card" data-aos="fade-up">
                <div>
                  <img src={logo}/>
                  <h3>Vacancy</h3>
                </div>
                <div>
                  <span>21th September, 2022</span>
                  <label className='truncate'>CHIEF EXECUTIVE OFFICER– *ECCB REGULATED BANK</label>

                  <a class="read-more">
                    <div>
                      <svg viewBox="0 0 24 24">
                          <path fill="currentColor" d="M4,11V13H16L10.5,18.5L11.92,19.92L19.84,12L11.92,4.08L10.5,5.5L16,11H4Z" />
                      </svg>
                      
                      <label>Read More</label>
                    </div>
                  </a>
                </div>
              </div></a>

          <a href="https://svcbl.com/pdf/Annual-Report/2022.pdf" target="_blank">
                <div class="card" data-aos="fade-up">
                <div>
                  <img src={logo}/>
                  <h3>2022 Annual Report</h3>
                </div>
                <div>
                  <span>1st September, 2022</span>
                  <label>2022 Annual Report</label>

                  <a class="read-more">
                    <div>
                      <svg viewBox="0 0 24 24">
                          <path fill="currentColor" d="M4,11V13H16L10.5,18.5L11.92,19.92L19.84,12L11.92,4.08L10.5,5.5L16,11H4Z" />
                      </svg>
                      
                      <label>Read More</label>
                    </div>
                  </a>
                </div>
              </div></a>
          
          <a href="https://svcbl.com/pdf/Risk-and-Compliance-Officer.pdf" target="_blank">
                <div class="card" data-aos="fade-up">
                <div>
                  <img src={logo}/>
                  <h3>Vacancy</h3>
                </div>
                <div>
                  <span>20th August, 2022</span>
                  <label className='truncate'>Risk and Compliance Officer</label>

                  <a class="read-more">
                    <div>
                      <svg viewBox="0 0 24 24">
                          <path fill="currentColor" d="M4,11V13H16L10.5,18.5L11.92,19.92L19.84,12L11.92,4.08L10.5,5.5L16,11H4Z" />
                      </svg>
                      
                      <label>Read More</label>
                    </div>
                  </a>
                </div>
              </div></a>
          
          <Link to="/news/Playaz-Youth-Academy">
              <div class="card" data-aos="fade-up">
                <div>
                  <img src={PlayazYouthAcademy}/>
                  <h3>Article</h3>
                </div>
                <div>
                  <span>20th August, 2022</span>
                  <label>Playaz Youth Academy</label>

                  <a class="read-more">
                    <div>
                      <svg viewBox="0 0 24 24">
                          <path fill="currentColor" d="M4,11V13H16L10.5,18.5L11.92,19.92L19.84,12L11.92,4.08L10.5,5.5L16,11H4Z" />
                      </svg>
                      
                      <label>Read More</label>
                    </div>
                  </a>
                </div>
              </div>
          </Link>

          <a href="https://svcbl.com/pdf/HRM.pdf" target="_blank">
                <div class="card" data-aos="fade-up">
                <div>
                  <img src={logo}/>
                  <h3>Vacancy</h3>
                </div>
                <div>
                  <span>23rd June, 2022</span>
                  <label className='truncate'>Human Resource/Administration Manager</label>

                  <a class="read-more">
                    <div>
                      <svg viewBox="0 0 24 24">
                          <path fill="currentColor" d="M4,11V13H16L10.5,18.5L11.92,19.92L19.84,12L11.92,4.08L10.5,5.5L16,11H4Z" />
                      </svg>
                      
                      <label>Read More</label>
                    </div>
                  </a>
                </div>
              </div></a>

          <Link to="/news/children_fun_day">
              <div class="card" data-aos="fade-up">
                <div>
                  <img src={Chato_Fun_day}/>
                  <h3>Article</h3>
                </div>
                <div>
                  <span>18th May, 2022</span>
                  <label>Chateaubelair Children Fun Day</label>

                  <a class="read-more">
                    <div>
                      <svg viewBox="0 0 24 24">
                          <path fill="currentColor" d="M4,11V13H16L10.5,18.5L11.92,19.92L19.84,12L11.92,4.08L10.5,5.5L16,11H4Z" />
                      </svg>
                      
                      <label>Read More</label>
                    </div>
                  </a>
                </div>
              </div>
          </Link>

          <Link to="/promotions/scholarship_bursary">
              <div class="card" data-aos="fade-up">
                <div>
                  <img src={Scholarship_bursary}/>
                  <h3>SCHOLARSHIP & BURSARIES</h3>
                </div>
                <div>
                  <span>10th May, 2022</span>
                  <label>2022 Scholarship and bursaries</label>

                  <a class="read-more">
                    <div>
                      <svg viewBox="0 0 24 24">
                          <path fill="currentColor" d="M4,11V13H16L10.5,18.5L11.92,19.92L19.84,12L11.92,4.08L10.5,5.5L16,11H4Z" />
                      </svg>
                      
                      <label>Read More</label>
                    </div>
                  </a>
                </div>
              </div>
          </Link>

          <a href="https://svcbl.com/pdf/Financial-Statement/2022.pdf" target="_blank">
                <div class="card" data-aos="fade-up">
                <div>
                  <img src={logo}/>
                  <h3>FINANCIAL STATEMENT</h3>
                </div>
                <div>
                  <span>29th April, 2022</span>
                  <label>2022 Audited Financial Statement</label>

                  <a class="read-more">
                    <div>
                      <svg viewBox="0 0 24 24">
                          <path fill="currentColor" d="M4,11V13H16L10.5,18.5L11.92,19.92L19.84,12L11.92,4.08L10.5,5.5L16,11H4Z" />
                      </svg>
                      
                      <label>Read More</label>
                    </div>
                  </a>
                </div>
              </div></a>

          <a href="https://svcbl.com/pdf/Customer-Service-Manager.pdf" target="_blank">
                <div class="card" data-aos="fade-up">
                <div>
                  <img src={logo}/>
                  <h3>Vacancy</h3>
                </div>
                <div>
                  <span>11th April, 2022</span>
                  <label>Customer Service Manager</label>

                  <a class="read-more">
                    <div>
                      <svg viewBox="0 0 24 24">
                          <path fill="currentColor" d="M4,11V13H16L10.5,18.5L11.92,19.92L19.84,12L11.92,4.08L10.5,5.5L16,11H4Z" />
                      </svg>
                      
                      <label>Read More</label>
                    </div>
                  </a>
                </div>
              </div></a>
            
          <a href="https://svcbl.com/pdf/Teller-Job-Alert.pdf" target="_blank">
                <div class="card" data-aos="fade-up">
                <div>
                  <img src={logo}/>
                  <h3>Vacancy</h3>
                </div>
                <div>
                  <span>23rd March, 2022</span>
                  <label>Teller</label>

                  <a class="read-more">
                    <div>
                      <svg viewBox="0 0 24 24">
                          <path fill="currentColor" d="M4,11V13H16L10.5,18.5L11.92,19.92L19.84,12L11.92,4.08L10.5,5.5L16,11H4Z" />
                      </svg>
                      
                      <label>Read More</label>
                    </div>
                  </a>
                </div>
              </div></a>

          <a href="https://svcbl.com/pdf/Abandoned-Properties/2022.pdf" target="_blank">
                <div class="card" data-aos="fade-up">
                <div>
                  <img src="https://svcbl.com/img/Notice.png"/>
                  <h3>NEWS</h3>
                </div>
                <div>
                  <span>31 January, 2022</span>
                  <label>Abandoned Properties</label>

                  <a class="read-more">
                    <div>
                      <svg viewBox="0 0 24 24">
                          <path fill="currentColor" d="M4,11V13H16L10.5,18.5L11.92,19.92L19.84,12L11.92,4.08L10.5,5.5L16,11H4Z" />
                      </svg>
                      
                      <label>Read More</label>
                    </div>
                  </a>
                </div>
              </div></a>
{/* 
          <a href="https://svcbl.com/pdf/CFO.pdf" target="_blank">
                <div class="card" data-aos="fade-up">
                <div>
                  <img src={logo}/>
                  <h3>Vacancy</h3>
                </div>
                <div>
                  <span>3rd January, 2022</span>
                  <label>Chief Financial Officer</label>

                  <a class="read-more">
                    <div>
                      <svg viewBox="0 0 24 24">
                          <path fill="currentColor" d="M4,11V13H16L10.5,18.5L11.92,19.92L19.84,12L11.92,4.08L10.5,5.5L16,11H4Z" />
                      </svg>
                      
                      <label>Read More</label>
                    </div>
                  </a>
                </div>
              </div></a> */}


          <Link to="/news/Ronaldo_Andrews">
                <div class="card" data-aos="fade-up">
                <div>
                  <img src={RAImage1}/>
                  <h3>Feature</h3>
                </div>
                <div>
                  <span>September 6, 2021</span>
                  <label>Ronaldo Andrews</label>

                  <a class="read-more">
                    <div>
                      <svg viewBox="0 0 24 24">
                          <path fill="currentColor" d="M4,11V13H16L10.5,18.5L11.92,19.92L19.84,12L11.92,4.08L10.5,5.5L16,11H4Z" />
                      </svg>
                      
                      <label>Read More</label>
                    </div>
                  </a>
                </div>
              </div></Link>

            {/* <a href="https://www.eccb-centralbank.org/p/what-you-should-know-1" target="_blank">
                <div class="card" data-aos="fade-up">
                <div>
                  <img src={ECCB_Baner}/>
                  <h3>Coming Soon</h3>
                </div>
                <div>
                  <span>June 28 2021</span>
                  <label>What you should know</label>

                  <a class="read-more">
                    <div>
                      <svg viewBox="0 0 24 24">
                          <path fill="currentColor" d="M4,11V13H16L10.5,18.5L11.92,19.92L19.84,12L11.92,4.08L10.5,5.5L16,11H4Z" />
                      </svg>
                      
                      <label>Read More</label>
                    </div>
                  </a>
                </div>
              </div></a> */}

            {/* <a href="https://svcbl.com/pdf/Vacancy/Internal%20Auditor.pdf" target="_blank">
                <div class="card" data-aos="fade-up">
                <div>
                  <img src={logo}/>
                  <h3>Vacancy</h3>
                </div>
                <div>
                  <span>27 August, 2021</span>
                  <label>Internal Auditor</label>

                  <a class="read-more">
                    <div>
                      <svg viewBox="0 0 24 24">
                          <path fill="currentColor" d="M4,11V13H16L10.5,18.5L11.92,19.92L19.84,12L11.92,4.08L10.5,5.5L16,11H4Z" />
                      </svg>
                      
                      <label>Read More</label>
                    </div>
                  </a>
                </div>
              </div></a> */}
            {/* <a href="https://svcbl.com/pdf/Vacancy/Administrative_Officer.pdf" target="_blank">
              <div class="card" data-aos="fade-up">
              <div>
                <img src={logo}/>
                <h3>Vacancy</h3>
              </div>
              <div>
                <span>June 3 2021</span>
                <label>Administrative Officer</label>

                <a class="read-more">
                  <div>
                    <svg viewBox="0 0 24 24">
                        <path fill="currentColor" d="M4,11V13H16L10.5,18.5L11.92,19.92L19.84,12L11.92,4.08L10.5,5.5L16,11H4Z" />
                    </svg>
                    
                    <label>Read More</label>
                  </div>
                </a>
              </div>
            </div></a> */}

           

          </div>
        </div>
      </div>
    )
}

export default News;