import React from 'react';
import '../style/about.css';

import Note from '../components/note.js';
import Header from '../components/header.js';
import Footer from '../components/footer';

import PageTop from '../components/pageTop';

export default function Pr(){
    window.scrollTo(0, 0);
    return(
        <div>
            <Note/>
            <Header/>
            {/* <PageTop/> */}
            <div className="page_title spacer">
               <label className='fancy'>Privacy Policy</label>
            </div>
            <div class="about_body" style={{lineHeight: '40px', marginTop: '-200px'}}>

            <i>Last Updated: April 15, 2020</i>

            <br/><br/>

            1.	Introduction<br/>

            Welcome to The Saint Vincent Co-operative Bank Ltd.

            The Saint Vincent Co-operative Bank Ltd. (‘SVCB’) is dedicated to protecting your personal information and will make every reasonable effort to handle collected information appropriately. All information collected will be handled with care in accordance with SVCB’s standards for integrity and objectivity and respect for your privacy. SVCB endeavors to comply with all laws and regulations that apply to the gathering and use of personal information. This privacy policy describes the information we collect, the purposes for which it is used, and your choices regarding its use. As used in this privacy policy, “we” and “us” includes SVCB and our staff, employees, officers and directors.

            SVCB operates the website https://svcooperativebank.com (hereinafter referred to as “the Service”).

            Our Privacy Policy governs your visit to https://svcooperativebank.com, and explains how we collect, safeguard and disclose information that results from your use of the Service.

            We use your data to provide and improve the Service. By using the Service, you agree to the collection and use of information in accordance with this policy. Unless otherwise defined in this Privacy Policy, the terms used in this Privacy Policy have the same meanings as in our Terms and Conditions as set out in the Service (“Terms”).

            Our Terms govern all use of the Service and together with the Privacy Policy constitute your agreement with us (“Agreement”).


            <br/><br/>

            2.	Definitions<br/>

            SERVICE means the https://svcooperativebank.com website operated by The Saint Vincent Co- operative Bank Ltd.

            PERSONAL DATA means data about a living individual who can be identified from those data (or from those and other information either in our possession or likely to come into our possession).

            USAGE DATA is data collected automatically either generated by the use of the Service or from the Service infrastructure itself (for example, the duration of a page visit).

            COOKIES are small files stored on your device (computer or mobile device).

            DATA CONTROLLER means a natural or legal person who (either alone or jointly or in common with other persons) determines the purposes for which and the manner in which any
            
            Personal Data is, or is to be, processed. For the purpose of this Privacy Policy, we are a Data Controller of your data.

            DATA PROCESSORS (OR SERVICE PROVIDERS) means any natural or legal person who processes the data on behalf of the Data Controller. We may use the services of various Service Providers in order to process your data more effectively.

            DATA SUBJECT is any living individual who is the subject of Personal Data.

            THE USER is the individual using the Service. The User corresponds to the Data Subject, who is the subject of Personal Data.

            <br/><br/>

            3.	Information Collection and Use<br/>

            We collect several different types of information for various purposes to provide and improve the Service for your benefit.


            <br/><br/>
            4.	Types of Data Collected Personal Data<br/>

            While using the Service, we may ask you to provide us with certain personally identifiable information that can be used to contact or identify you (“Personal Data”). Personally Identifiable Information may include, but is not limited to:

            1.	Email address

            2.	First name and last name

            3.	Phone number

            4.	Address, Country, State, Province, ZIP/Postal code, City

            5.	Cookies and Usage Data

            We may use your Personal Data to contact you with newsletters, marketing or promotional materials and other information that may be of interest to you. You may opt out of receiving any, or all, of these communications from us by following the unsubscribe link.


            <br/><br/>
            Usage Data<br/>

            We may also collect information that your browser sends whenever you visit the Service or when you access the Service by or through any device (“Usage Data”).

            This Usage Data may include information such as your computer’s Internet Protocol address (e.g. IP address), browser type, browser version, the pages of the Service that you visit, the time and
            
            date of your visit, the time spent on those pages, unique device identifiers and other diagnostic data.

            When you access the Service with a device, this Usage Data may include information such as the type of device you use, your device unique ID, the IP address of your device, your device operating system, the type of Internet browser you use, unique device identifiers and other diagnostic data.


            <br/><br/>
            Tracking Cookies Data<br/>

            We use Cookies and similar tracking technologies to track the activity on the Service and we hold certain information obtained as a result.

            Cookies may include an anonymous unique identifier. Cookies are sent to your browser from a website and stored on your device. Other tracking technologies are also used such as beacons, tags and scripts to collect and track information and to improve and analyze the Service.

            You can instruct your browser to refuse all cookies or to indicate when a cookie is being sent. However, if you do not accept cookies, you may not be able to use some portions of the Service.

            Examples of Cookies we use:

            1.	Session Cookies: We use Session Cookies to operate the Service.

            2.	Preference Cookies: We use Preference Cookies to remember your preferences and various settings.

            3.	Security Cookies: We use Security Cookies for security purposes.

            4.	Advertising Cookies: Advertising Cookies are used to serve you with advertisements that may be relevant to you and your interests.


            <br/><br/>
            Other Data<br/>

            While using the Service, we may also collect the following information: sex, age, date of birth, place of birth, passport details, citizenship, registration at place of residence and actual address, telephone number (work, mobile), details of documents on education, qualification, professional training, employment agreements, non-disclosure agreements, information on bonuses and compensation, information on marital status, family members, social security (or other taxpayer identification) number, office location and other data.

            5.	Use of Data<br/>

            The Saint Vincent Co-operative Bank Ltd. uses the collected data for various purposes:
            
            1.	to provide and maintain the Service;

            2.	to notify you about changes to the Service;

            3.	to allow you to participate in interactive features of the Service when you choose to do so;

            4.	to provide customer support;

            5.	to gather analysis or valuable information so that we can improve the Service;

            6.	to monitor the usage of the Service;

            7.	to detect, prevent and address technical issues;

            8.	to fulfill any other purpose for which you provide it;

            9.	to carry out our obligations and enforce our rights arising from any contracts entered into between you and us, including for billing and collection;

            1	to provide you with notices about your account and/or subscription, including expiration and renewal notices, email-instructions, etc.;

            11.	to provide you with news, special offers and general information about other goods, services and events which we offer that are similar to those that you have already purchased or enquired about unless you have opted not to receive such information;

            12.	in any other way we may describe when you provide the information;

            13.	for any other purpose with your consent.


            <br/><br/>
            6.	Retention of Data<br/>

            We will retain your Personal Data only for as long as is necessary for the purposes set out in this Privacy Policy. We will retain and use your Personal Data to the extent necessary to comply with our legal obligations (for example, if we are required to retain your data to comply with applicable laws), resolve disputes, and enforce our legal agreements and policies.

            We will also retain Usage Data for internal analysis purposes. Usage Data is generally retained for a shorter period, except when this data is used to strengthen the security or to improve the functionality of the Service, or when we are legally obligated to retain this data for longer time periods.
            

            <br/><br/>
            7.	Transfer of Data<br/>

            Your information, including Personal Data, may be transferred to – and maintained on – computers located outside of your state, province, country or other governmental jurisdiction where the data protection laws may differ from those of your jurisdiction.

            If you are located outside Saint Vincent and the Grenadines and choose to provide information to us, please note that we transfer the data, including Personal Data, to Saint Vincent and the Grenadines and process it there.

            Your consent to this Privacy Policy followed by your submission of such information represents your agreement to that transfer.

            The Saint Vincent Co-operative Bank Ltd. will take all the steps reasonably necessary to ensure that your data is treated securely and in accordance with this Privacy Policy and no transfer of your Personal Data will take place to an organization or a country unless there are adequate controls in place including the security of your data and other personal information.


            <br/><br/>
            8.	Disclosure of Data<br/>

            We may disclose personal information that we collect, or you provide:

            1.	Disclosure for Law Enforcement.

            Under certain circumstances, we may be required to disclose your Personal Data if required to do so by law or in response to valid requests by public authorities.

            2.	Business Transaction.

            If we or our subsidiaries are involved in a merger, acquisition or asset sale, your Personal Data may be transferred to another entity as part of the said merger, acquisition or asset sale.

            3.	Other cases. We may disclose your information also:

            3.1.	to our subsidiaries and affiliates;

            3.2.	to contractors, service providers, and other third parties we use to support our business;

            3.3.	to fulfill the purpose for which you provide it;

            3.4.	for the purpose of including your company’s logo on our website;

            3.5.	for any other purpose disclosed by us when you provide the information;

            3.6.	with your consent in any other cases;
            
            3.7.	if we believe disclosure is necessary or appropriate to protect the rights, property, or safety of the Company, our customers, or others.


            <br/><br/>
            9.	Security of Data<br/>

            The security of your data is important to us but remember that no method of transmission over the Internet or method of electronic storage is 100% secure. While we strive to use commercially acceptable means to protect your Personal Data, we cannot guarantee its absolute security.


            <br/><br/>
            10.	Service Providers<br/>

            We may employ third party companies and individuals to facilitate the Service (“Service Providers”), provide the Service on our behalf, perform services related to the Service or assist us in analyzing how the Service is used.

            Service Providers have access to your Personal Data only to perform these tasks on our behalf and are obligated not to disclose or use it for any other purpose.


            <br/><br/>
            11.	Analytics<br/>

            We may use Service Providers to monitor and analyze the use of the Service.


            <br/><br/>
            12.	CI/CD tools<br/>

            We may use Service Providers to automate the development process of the Service.


            <br/><br/>
            13.	Behavioral Remarketing<br/>

            We may use remarketing services to advertise on third party websites to you after you visited the Service. We and Service Providers use Cookies to inform, optimize and serve advertisements based on your past visits to the Service.


            <br/><br/>
            14.	Payments<br/>

            We may provide paid products and/or services within the Service. In that case, we use Service Providers for payment processing (e.g. payment processors).

            We will not store or collect your payment card details. That information is provided directly to the Service Providers who facilitate third-party payment processing and whose use of your personal information is governed by their Privacy Policy. These Service Providers who facilitate such payment adhere to the standards set by PCI-DSS as managed by the PCI Security Standards Council, which is a joint effort of brands like Visa, Mastercard, American Express and Discover. PCI-DSS requirements help ensure the secure handling of payment information.
            

            <br/><br/>
            15.	Links to Other Sites<br/>

            If, in your interactions with the Service, you are linked or directed to, or click on, a third party website, we cannot control what information you may provide to that party or on/at that website, and are not responsible for how that party may use or disclose any information you may provide to them. As such, we urge that you exercise caution before providing them with your personal information and to review the third party’s privacy policy for information on its data processing practice.


            <br/><br/>
            16.	Children’s Privacy<br/>

            The Services are not intended for use by children under the age of 18 (“Child” or “Children”).

            We do not knowingly collect personally identifiable information from Children under 18. If you become aware that a Child has provided us with Personal Data, please contact us. If we become aware that we have collected Personal Data from Children without verification of parental consent, we take steps to remove that information from our servers.


            <br/><br/>
            17.	Changes to This Privacy Policy<br/>

            We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page.

            We will let you know via email and/or a prominent notice on the Service, prior to the change becoming effective and update “Last Updated” at the top of this Privacy Policy.

            You are advised to review this Privacy Policy periodically for any changes. Changes to this Privacy Policy are effective when they are posted on this page.

            <br/><br/>
            18.	Contact Us<br/>

            If  you  have  any  questions  about  this  Privacy  Policy,  please  contact  us  by  email:
            info@svcooperativebank.com.

            </div>
            <Footer/>
        </div>
    )
}