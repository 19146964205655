import React from 'react';
import OwlCarousel from 'react-owl-carousel3';
import '../style/slider.css';
import img from '../assets/st_vincent.jpg';
import Button from '@material-ui/core/Button';

import img1 from '../assets/slider/image1.jpeg';
import img2 from '../assets/slider/image2.jpeg';
import img3 from '../assets/slider/image3.jpeg';

export default function AboutSlider(){
    return(
        <>
        <br/><br/>
            {/* <OwlCarousel
                className="owl-theme main_slider"
                loop margin={10} items={1} autoPlay={true}>

                <div class="item">
                    <img src={img1}/>
                </div>

                <div class="item">
                    <img src={img2}/>
                </div>

                <div class="item">
                    <img src={img3}/>
                </div>

            </OwlCarousel> */}
        </>
    )
}