import React from 'react';
import '../style/about.css';

import Note from '../components/note.js';
import Header from '../components/header.js';
import Footer from '../components/footer';

import PageTop from '../components/pageTop';

export default function Guidelines(){
    window.scrollTo(0, 0);
    return(
        <div>
            <Note/>
            <Header/>
            {/* <PageTop/> */}
            <div className="page_title spacer">
               <label className='fancy'>Guidelines</label>
            </div>
            <div class="about_body" style={{lineHeight: '40px', marginTop: '-200px'}}>
                <p>
                  Please ensure that you (referred to as the” User”, “you” or “your”) read these terms and conditions of use with care as they govern your use of and access to this Web Site (the “Site”). If you do not accept them, please do not use this Site.
                </p>
                <p>
                  To users of SVCB’s Site, the following provisions apply: 
                </p>
                <ol>
                    <li>
                        <b>Reservation of Ownership</b><br/>
                        <p>
                          This Site is owned by SVCB Limited, incorporated as a limited liability company, in St. Vincent and the Grenadines and regulated by… “We” or “us” refers to SVCB Limited and its successors, assignees and transferees.
                        </p>
                    </li>
                    <li>
                        <b>Target Audience</b><br/>
                        <p>
                          a.  Although accessible by others, the information appearing on the pages of this Site is solely intended for use by individuals over the age of eighteen years old for private use. SVCB Limited makes no representations that information on this Site is appropriate or available for use by minors. Users who access this Site do so on their own initiative and risk and no warranty or representation of any kind is made in relation to compliance of this Site or the information thereon.b.  No information on this Site should be construed as a promotion or solicitation for any services or for the use thereof which is not authorized by the applicable laws and regulations of your place of citizenship, domicile or residence.                        
                        </p>
                    </li>
                    <li>
                        <b>Marketing Information</b><br/>
                        <p>The information contained on the marketing pages of the Site is not intended to constitute an offer to Users to avail of any services of SVCB Limited. The information provided on the pages of this Site, including these terms and conditions of use, is provided for your information and general interest only, is subject to change and update by SVCB Limited from time to time without notification. You should periodically revisit this page to review the then current terms and conditions of use.</p>
                    </li>
                    <li>
                        <b>Financial Information</b><br/>
                        <p>All financial market information, prices, quotes and financial and business newsfeeds are prepared from sources believed to be reliable and provided to SVCB Limited for the purpose of allowing you to view them and is provided for your general information only, and is not intended to address your particular requirements or to be used by you for trading purposes. In particular, the financial information does not constitute any form of advice, recommendation or arrangement by SVCB Limited and is not intended to be relied upon by users in making (or refraining from making) any specific investment or other decisions. Appropriate independent advice should be obtained before making any such decision. Please also read the “Investment Warning” below. Neither SVCB Limited nor any of its data or content providers shall be liable for any errors or delays in the content of the financial information or for information that has changed since it was initially gathered, or for any actions taken in reliance thereon. Any arrangements made between you and any third party named on this Site are at your sole risk and responsibility. Any opinions expressed in the financial information reflect the judgment of SVCB Limited’s content providers, are not those of SVCB Limited or its employees and are subject to change without notice. SVCB Limited does not accept responsibility for any errors or omissions or for any reliance placed by any person on any or all of the information on these pages.</p>
                    </li>
                    <li>
                        <b>Investment Warning</b><br/>
                        <p>For your benefit we would like to draw your attention to the following investment warnings. There are risks involved in acquiring or holding investments. The value of investments and the income derived from them may fluctuate and an investor may not receive back the amount originally invested. Past performance is not necessarily a guide to future performance. The investments and investment services referred to in this Site may not be suitable for all investors and if in doubt an investor should seek advice from a qualified financial adviser.</p>
                    </li>
                    <li>
                       <b>Exclusion of Warranty re Accuracy</b><br/>
                       <p>While SVCB Limited has taken all reasonable steps to ensure the accuracy, currency, availability, correctness and completeness of the information contained on the pages of this Site, the information is provided “as is” without warranty of any kind, whether express or implied subject to the provisions of the terms and conditions of the trade of SVCB Limited. The use of this Site is at the User’s sole risk. SVCB Limited shall not be liable for any loss or damage howsoever arising as a result of use of or reliance on the information contained on the Site, whether authorized or not, to the maximum extent permitted by the applicable law.</p>
                    </li>
                    <li>
                       <b>Links to Third Party Sites</b><br/>
                       <p>Certain links on this Site lead to sites maintained by third parties over which SVCB Limited has no control. SVCB Limited makes no representations as to the accuracy, completeness or any other aspect of the information contained on such third party sites or sites linked to such sites and shall not be liable for any loss or damage arising from your reliance upon such information. Any links to third party sites are provided solely as a convenience to Users and at your own risk.</p>
                    </li>
                    <li>
                       <b>Confidentiality Disclosure</b><br/>
                       <p>In accessing this Site the User accepts that electronic mail passing over the internet may not be free from interference by third parties. The User shall be responsible for the content and information contained in all your communications to this Site, including the lawfulness, truthfulness and accuracy of such communications. In particular, but without limitation, you should not post any unlawful, threatening, defamatory or obscene material or any material that could give rise to a criminal offence and/or civil liability in any relevant jurisdiction. It is entirely at your discretion to send any information to SVCB Limited.</p>
                    </li>
                    <li>
                       <b>Copyright</b><br/>
                       <p>a. The information contained on and the contents of the pages of this Site, including but not limited to the layout and expression, are © 2009 SVCB Limited and/or its third party suppliers and may not be copied, transmitted, converted, transcribed or reproduced without the prior consent of SVCB Limited and except in accordance with the following permission: You may print or download on any single computer one copy of the contents of these pages (other than those excepted from this license under this clause) for your personal non-commercial use. All copyright notices, proprietary notices and disclaimers should also be copied.</p>
                       <p>b. All rights not expressly granted by us in these terms and conditions of use are hereby reserved.</p>
                       <p>c. Where applicable, the author(s) of the literary and artistic works set out on the pages of this Site has/have asserted his/her/their moral rights to be identified as the author of those works. Their identities are set out on the pages containing their works.</p>
                       <p>d. The limited licenses granted to copy materials on this Site do not apply to the contents of any SVCB Limited art pages. All reproduction of these pages is prohibited, including printing and downloading. In addition, in respect of any software downloaded from this Site, title, including all intellectual property rights, shall vest and remain vested in SVCB Limited and/or its third party suppliers.</p>
                       <p>e. The limited license to copy materials on this Site set out above does not permit incorporation of the material or any part of it in any other work or publication, whether in hard copy, electronic or any other form. In particular but without limitation no part of the Site pages may be distributed or copied for any commercial purposes. No part of these Site pages may be reproduced or transmitted to or stored in any other web site or other form of electronic retrieval system.</p>
                    </li>
                    <li>
                       <b>Trademark</b><br/>
                       <p>SVCB Limited’s web site and financial services are trademarks of SVCB Limited and all rights in and to these trademarks are reserved. All brand names and product names referred to in this Site are trademarks or trade names of SVCB Limited and/or its third party suppliers, as appropriate.</p>
                    </li>
                    <li>
                       <b>Exclusion of Liability</b><br/>
                       <p>SVCB Limited is not responsible for any losses, injury or damage, whether direct or indirect, special or consequential caused by SVCB Limited on-line services or web site arising in any way out of the use thereof and in particular in respect of unavailability of access to the Internet, SVCB Limited’s on-line service system maintenance, or any electronic or telecommunication failure beyond the control of SVCB Limited. SVCB Limited makes no representations or warranties regarding the accuracy, functionality, or performance of any third party software that may be used in connection with the Site.</p>
                    </li>
                    <li>
                       <b>Governing Law</b><br/>
                       <p>Use of this Site shall be made subject to the laws of St. Vincent & the Grenadines which shall exclusively govern the interpretation, application and effect of all the above permissions, exclusions, licenses and conditions of use. The courts of St. Vincent & the Grenadines shall have exclusive jurisdiction over all claims or disputes arising in relation to, out of or in connection with this Site and its use. If any provision of these terms and conditions of use shall be held unlawful, void or unenforceable for any reason then such provision shall be deemed severed and shall not affect the validity and enforceability of the remaining provisions.</p>
                    </li>
                    <li>
                       <b>Entire Agreement</b><br/>
                       <p>These terms and conditions of use contain the entire agreement between the parties relating to the subject matter herein.</p>
                    </li>
                </ol>
            </div>
            <Footer/>
        </div>
    )
}