import React, { useState, useEffect } from 'react';
import '../style/application.css';

import Loader from '../components/loader';
import { Alert, AlertTitle } from '@material-ui/lab';

import Button from '@material-ui/core/Button';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';

import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import TextField from '@material-ui/core/TextField';
import FormHelperText from '@material-ui/core/FormHelperText';

import Note from '../components/note.js';
import Header from '../components/header.js';
import Footer from '../components/footer';

import Slider from '../components/about_slider';

import cardImg from '../assets/card.png';

import { Link } from 'react-router-dom';

import PageTop from '../components/pageTop';

export default function OnlineBanking(){
    const [loader, setLoader] = useState(false);
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    
    function Data(){
        return React.useState({'value': '', 'error': false, 'helper': ''});
    }

    function post(){
       if(personFirstName.value == "" || personLastName.value == "" || personAccountNumber.value == "" || personEmail.value == "" || personPhone.value == ""){
            personFirstName_handler('check');
            personLastName_handler('check');
            personAccountNumber_handler('check');
            personEmail_handler('check');
            personPhone_handler('check');
            setIsError('block');
            document.getElementById("infoError").scrollIntoView();
       }else{
          if(!agreeWithTerms){
            setTermsAgreementError('You must agree with the terms of service');
          }else{
            setLoader(true);
            document.getElementById("ATMForm").submit();
          }
       }
    }

    const [personStatus, setPersonStatus] = Data();
    const personStatus_handler = (val) => {
        const value = val == 'check' ? personStatus.value : val;

        const newPersonalStatus = [...personStatus];

        if(value == ""){ newPersonalStatus = {'value': value, 'error': true, 'helper': 'Please select Marital status'}}
        else { newPersonalStatus = {'value': value, 'error': false, 'helper': ''}} 

        setPersonStatus(newPersonalStatus);
    }

    const [personTitle, setPersonTitle] = Data();
    const personTitle_handler = (val) => {
        const value = val == 'check' ? personTitle.value : val;

        const newPersonalStatus = [...personTitle];

        if(value == ""){ newPersonalStatus = {'value':value, 'error': true, 'helper': 'Please select title'}}
        else { newPersonalStatus = {'value': value, 'error': false, 'helper': ''}} 

        setPersonTitle(newPersonalStatus);
    }

    const [personFirstName, setPersonFirstName] = Data();
    const personFirstName_handler = (val) => {
        const value = val == 'check' ? personFirstName.value : val;
        if(value === ""){ setPersonFirstName({'value':value, 'error': true, 'helper': 'First name is required'})} 
        else { setPersonFirstName({'value': value, 'error': false, 'helper': ''})}
    }

    const [personMiddleName, setPersonMiddleName] = Data();
    const personMiddleName_handler = (val) => {
        const value = val == 'check' ? personMiddleName.value : val;
        if(value === ""){ setPersonMiddleName({'value':value, 'error': true, 'helper': 'Middle name is required'})} 
        else { setPersonMiddleName({'value': value, 'error': false, 'helper': ''})}
    }

    const [personLastName, setPersonLastName] = Data();
    const personLastName_handler = (val) => {
        const value = val == 'check' ? personLastName.value : val;
        if(value === ""){ setPersonLastName({'value':value, 'error': true, 'helper': 'Last name is required'})} 
        else { setPersonLastName({'value': value, 'error': false, 'helper': ''})}
    }


    const [personAccountNumber, setPersonAccountNumber] = Data();
    const personAccountNumber_handler = (val) => {
        const value = val == 'check' ? personAccountNumber.value : val;
        if(value === ""){ setPersonAccountNumber({'value':value, 'error': true, 'helper': 'Account number is required'})} 
        else { setPersonAccountNumber({'value': value, 'error': false, 'helper': ''})}
    }

    const [personId, setPersonId] = Data();
    const personId_handler = (event) => {
        console.log(event.target.files[0]);
        const file = event == 'check' ? personId.value : event.target.files[0];
        if(file === ""){ setPersonId({'value':file, 'error': true, 'helper': 'Identification (ID) is required'})}
        else { setPersonId({'value': file, 'error': false, 'helper': ''}) }
    }

    const [personPhone, setPersonPhone] = Data();
    const personPhone_handler = (val) => {
        const value = val == 'check' ? personPhone.value : val;
        if(value === ""){ setPersonPhone({'value':value, 'error': true, 'helper': 'Phone number is required'})} 
        else { setPersonPhone({'value': value, 'error': false, 'helper': ''})}
    }

    const [personEmail, setPersonEmail] = Data();
    const personEmail_handler = (val) => {
        const value = val == 'check' ? personEmail.value : val;
        if(value === ""){ setPersonEmail({'value':value, 'error': true, 'helper': 'Email address is required'})} 
        else if(!validateEmail(value)){ setPersonEmail({'value':value, 'error': true, 'helper': 'Invalid email address... valid email format example@gmail.com'})} 
        else { setPersonEmail({'value': value, 'error': false, 'helper': ''})}
    }
    
    const [existingAccount, setExistingAccount] = Data();
    const existingAccount_handler = (event) => {
        setExistingAccount({'value': event.target.value})
    }

    const [feedback, setFeedback] = React.useState();


    // ##########################################################################################################################
    // ##########################################################################################################################
    function validateEmail(email) {
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }

    const isNumericInput = (event) => {
        const key = event.keyCode;
        return ((key >= 48 && key <= 57) || // Allow number line
            (key >= 96 && key <= 105) // Allow number pad
        );
    };
    
    const isModifierKey = (event) => {
        const key = event.keyCode;
        return (event.shiftKey === true || key === 35 || key === 36) || // Allow Shift, Home, End
            (key === 8 || key === 9 || key === 13 || key === 46) || // Allow Backspace, Tab, Enter, Delete
            (key > 36 && key < 41) || // Allow left, up, right, down
            (
                // Allow Ctrl/Command + A,C,V,X,Z
                (event.ctrlKey === true || event.metaKey === true) &&
                (key === 65 || key === 67 || key === 86 || key === 88 || key === 90)
            )
    };

    const enforceFormat = (event) => {
        // Input must be of a valid number format or a modifier key, and not longer than ten digits
        if(!isNumericInput(event) && !isModifierKey(event)){
            event.preventDefault();
        }
    };
    
    const formatToPhone = (event) => {
        if(isModifierKey(event)) {return;}
    
        const input = event.target.value.replace(/\D/g,'').substring(0,10); // First ten digits of input only
        const areaCode = input.substring(0,3);
        const middle = input.substring(3,6);
        const last = input.substring(6,10);
    
        if(input.length > 6){event.target.value = `(${areaCode}) ${middle} - ${last}`;}
        else if(input.length > 3){event.target.value = `(${areaCode}) ${middle}`;}
        else if(input.length > 0){event.target.value = `(${areaCode}`;}
    };

    const [termsView, setTermsView] = React.useState('none');

    const [isError, setIsError] = React.useState('none');

    const [agreeWithTerms, setAgreeWithTerms] = React.useState();
    const [termsAgreementError, setTermsAgreementError] = React.useState('');

    const agreeWithTerms_handler = (event) => {
        setAgreeWithTerms(event.target.checked);
     }

    const personInfo = () => {

        return(
            <form id="ATMForm" action="https://svcbl.com/api/OnlineBanking.php" method="post" enctype="multipart/form-data">
                <Alert severity="error" id="infoError" style={{display: isError}}>
                    <AlertTitle>Error</AlertTitle>
                    Please correct the following errors 
                </Alert><br/>

                <fieldset class="person">
                    <legend>
                        Account Information
                        ({personFirstName.value} {personMiddleName.value} { personLastName.value})
                    
                    </legend>

                    <div className="row">
                        <TextField 
                            error={personFirstName.error}
                            value={personFirstName.value}
                            label="First Name"
                            name="fname"
                            helperText={personFirstName.helper}
                            onChange={e => {personFirstName_handler(e.target.value)}}
                            variant="filled"
                            className="input"/>
                        
                        <TextField 
                            error={personMiddleName.error}
                            value={personMiddleName.value}
                            label="Middle Name"
                            name="mname"
                            helperText={personMiddleName.helper}
                            onChange={e => {personMiddleName_handler(e.target.value)}}
                            variant="filled"
                            className="input"/>

                        <TextField 
                            error={personLastName.error}
                            value={personLastName.value}
                            label="Last Name"
                            name="lname"
                            helperText={personLastName.helper}
                            onChange={e => {personLastName_handler(e.target.value)}}
                            variant="filled"
                            className="input"/>
                    </div>
                    <div className="row">
                        <TextField 
                            error={personAccountNumber.error}
                            value={personAccountNumber.value}
                            label="Account number"
                            name="account"
                            helperText={personAccountNumber.helper}
                            onChange={e => {personAccountNumber_handler(e.target.value)}}
                            variant="filled"
                            className="input"/>
                    </div>
                    <div className="row">
                        <TextField 
                            error={personEmail.error}
                            value={personEmail.value}
                            label="Email address"
                            name="email"
                            helperText={personEmail.helper}
                            onChange={e => {personEmail_handler(e.target.value)}}
                            variant="filled"
                            className="input"/>

                        <TextField
                            id="outlined-secondary"
                            error={personPhone.error}
                            value={personPhone.value}
                            helperText={personPhone.helper}
                            label="Phone number"
                            name="phone"
                            onChange={e => {personPhone_handler(e.target.value)}}
                            onKeyDown={enforceFormat}
                            onKeyUp={formatToPhone}
                            variant="filled"
                            className="input"/>
                    </div>
                </fieldset>
                <br/>
                <fieldset class="person">
                    <legend>
                        Identification - National ID, Passport or Drivers License (Optional) 
                    </legend>
                    {/* <label style={{fontSize: '20px'}} className="label">
                        For NON residents ONLY 
                    </label><br/> */}
                    <TextField
                            error={personId.error}
                            // value={personId.value}
                            id="fileID"
                            helperText={personId.helper}
                            label="Upload copy of ID"
                            onChange={personId_handler}
                            variant="filled"
                            type="file"
                            accept="image/png, image/jpeg"
                            className="input"
                            name="ID"
                            InputLabelProps={{
                                shrink: true,
                            }}/>

                </fieldset>
                <br/>
                {
                   terms()
                }
                <div class="action">
                    <Button size="large" variant="contained" className="nextBtn"  onClick={() => post()}>
                            Next
                            <KeyboardArrowRight /> 
                    </Button>
                </div>
            </form>
        )
    }

    function terms(){
        return(
            <>
              <fieldset class="person">
                <legend>
                     Terms and conditions                
                </legend>
                <p>
                  By signing below, I/we hereby apply for The St. Vincent Cooperative Bank Ltd. (the “Bank”) On-Line Banking service (the “Service”) and authorize you and your representatives to provide the Service for these Bank accounts indicated above. I/we understand that the use of the Service is subject to the terms and conditions contained in (a) St. Vincent Cooperative Bank On-line Banking Service Agreement (the “Agreement”) which I/we may access when I/we log onto the Service, (b) the Bank’s general disclosure for personal and business accounts which I/we received when I/we opened my account(s) and (c) your general account mandates. I/We acknowledge and agree that using, or permitting another person to use, the Service confirms the terms and conditions set forth in the Agreement(s). The Bank will not be liable to me for any loss or damages whatsoever or however caused by the use or otherwise to the service whether directly or indirectly including all and any liability for consequential loss or damage; I will not hold the Bank liable should any information whatsoever concerning my account(s) become available to any unauthorized person by whatever means or for whatever reason.
                </p>
                <p>
                Additionally, the Bank shall not be liable in any way if it is unable to perform its obligations due directly or indirectly to the failure of the data processing system (whether by way of hardware or software) or transmission link or to any industrial dispute or from any cause whatsoever outside the control of the Bank, its servants, agents, sub-contractors nor from any incident or data distortion or loss or any consequences thereof which occurred during or as a consequence of the use of the Service.
                </p>
                <p>
                When using the transfer between account options of the Service, I accept full responsibility for saving/printing copies of my/our confirmation statement(s) after the transfer/payment of funds. I fully indemnify the Bank against all claims, cost actions or other proceedings whatsoever or however arising out of the use of the Service made or brought against the Bank by any third party.
                </p>
                <p>
                I/We must indicate that if I wish to cancel the Service it must be done by written notification.
                </p>
                <p>
                I hereby certify that all statements in this application are true and complete and are made for the purpose of obtaining the Service; authorize you to obtain such information as you may require concerning the statements herein; agree that this application shall remain your property; and acknowledge my responsibility to inform you of any changes in name and address within a reasonable time.
                </p>
                
                <div class="options">
                    
                     <FormControlLabel 
                        control={<Checkbox name="term" value="I agree with the terms and services" checked={agreeWithTerms} color="primary" onChange={agreeWithTerms_handler} />} label="I agree with the terms and services" />
                </div>
                <span id="termsOfServiceError" class="error">{termsAgreementError}</span>
              </fieldset>
            </>
        )
    }

    return(
        <div>
            <Note/>
            <Header/>
            {/* <PageTop/> */}
            {
                loader
                ? <Loader/>
                : ''
            }
            <div className="page_title spacer">
               <label className='fancy'>Online Banking</label>
             </div>
            <div class="application" style={{marginTop: '-150px'}}>
                <div class="content">
                    <label className="label" style={{fontSize: 40}}>
                        View and transfer funds between your SVCB accounts
                    </label><br/>
                    <p>
                        Pay your utility bills
                    </p><br/>
                    
                    {
                        feedback == "Success" 
                        ? <> 
                             <h1>Request Posted Successfully</h1>
                          </>
                        : <>
                             <label className="question">
                                Do you have a SVCB savings account?
                            </label><br/>

                            <RadioGroup row aria-label="position" name="position" defaultValue={existingAccount.value} onChange={existingAccount_handler}>
                                <FormControlLabel
                                    value="yes"
                                    control={<Radio color="primary" />}
                                    label="Yes"
                                    labelPlacement="end"/>
                                <FormControlLabel 
                                    value="no" 
                                    control={<Radio color="primary" />} 
                                    label="No" />
                            </RadioGroup><br/>

                            {
                                existingAccount.value == "no" 
                                ? <>
                                    <h1>A SVCB account is required to sign up for online banking</h1>
                                    <Link to="/apply/savings" className="link">
                                    Savings Application
                                    </Link>
                                </>
                                : existingAccount.value == "yes"
                                ? personInfo() 
                                : <><h1>Please answer question above</h1></>
                            }
                          </>
                    }
                    
                </div>
                <br/><br/>
            </div>
            <Footer/>
        </div>
    )
}
