import React from 'react';
import '../style/about.css';
import '../style/cool-text.css';

import Note from '../components/note.js';
import Header from '../components/header.js';
import Footer from '../components/footer';

import PageTop from '../components/pageTop';

import Slider from '../components/about_slider';

import Alert from '../components/alert';

function About(){
    window.scrollTo(0, 0);

    return(
        <div>
            {/* <Note/> */}
            {/* <Alert/> */}
            <Header/>
            {/* <PageTop/> */}
            <div className="page_title spacer">
               <label className="fancy">About Us</label>
            </div>
            <div class="about_body" style={{lineHeight: '40px', marginTop: '-200px'}}>
              <div class="row">
                 <div>
                     <div class="title">
                        <label>Corporate Profile</label>
                        <div></div>
                     </div>
                     <p>
                       The St Vincent Co-operative Bank Limited opened its doors to business on 1st February 1945 and is one of the oldest indigenous financial institutions in St. Vincent and the Grenadines.
                     </p>
                     <p>
                       At the time of opening, a penny was all that was required to start an account, and to date the Bank is known as ‘Penny Bank’ or ‘Forde Bank’ after its founder Ormond Forde.
                     </p>
                     <p>
                       The relative ease of opening an account from inception has resulted in the St. Vincent Cooperative Bank now holding more Savings Accounts than any other financial institution in St. Vincent & the Grenadines.
                     </p>
                     <p>
                       The St. Vincent Cooperative Bank is regulated by the Eastern Caribbean Central Bank (ECCB) and offers the following services:
                     </p>
                     <ul>
                         <li>Residential Mortgages</li>
                         <li>Savings Accounts</li>
                         <li>Fixed deposits</li>
                         <li>Consumer Loans</li>
                         <li>Commercial Loans</li>
                         <li>Life Insurance On All Loans</li>
                     </ul>
                     <p>
                       Exceptional service has always been the foremost priority of the Bank in its quest to become a leading financial institution in the country.
                     </p>
                     <p>
                       The Bank’s mantra, ‘Service beyond your expectation’, is reflected in its mission statement and implemented by a courteous, efficient and reliable staff.
                     </p>
                     <div class="title">
                        <label>Corporate Stewardship</label>
                        <div></div>
                     </div>
                     <p>
                       We take our corporate social responsibility seriously and over the years we have provided financial support to several charities and other non-profit organizations. In addition, we have supported students with scholarships to the Community College and the Technical College and provided bursaries to students attending secondary schools.
                     </p>
                     <p>
                       Over the years the Bank has been committed to the social development of St Vincent & the Grenadines. Our Community involvement through youth development programmes has seen a successful tenure of sponsorship for the North Leeward under 15 Cricket Tournament.
                     </p>
                     <p>
                       We shall continue to support those institutions which contribute to the social welfare and development of all our stakeholders and to St. Vincent & the Grenadines.
                     </p>
                     <p>
                       Click here for the <a href="https://svcbl.com/pdf/Bank-License.pdf" target="_blank">Bank License</a>.
                     </p>
                 </div>
                 <div>
                     <div class="title">
                        <label>Mission Statement</label>
                        <div></div>
                     </div>
                     <p>We are committed:</p>
                     <ul>
                         <li>to providing exceptional service and banking solutions to our customers through engaged employees;</li>
                         <li>to providing an environment that fosters growth and stability for employees; and</li>
                         <li>to positively impact our community while maximizing our stakeholders’ value in a regulated environment.</li>
                     </ul>
                     
                  <div style={{backgroundColor: '#f0f0f0', borderRadius: '10px', padding: '10px'}}>
                     <div class="title">
                        <label>Vision Statement</label>
                        <div></div>
                     </div>
                     <p>To become the leading financial institution in the Eastern Caribbean by 2030.</p>

                     <div class="title">
                           <label>Core Values</label>
                           <div></div>
                        </div>
                        <ol>
                           <li>
                              <b>Accountability</b><br/>
                              We shall admit and fix any mistakes. Our clients should expect no less.
                           </li>
                           <li>
                              <b>Excellence</b><br/>
                              We will not rest on our accomplishments but will instead build on them in our goal to be the best we can be.
                           </li>
                           <li>
                              <b>Team Work</b><br/>
                              Together we are stronger and can contribute more.
                           </li>
                           <li>
                              <b>Service</b><br/>
                              We will go above and beyond what is required to attract and retain cherished customer relationships.
                           </li>
                           <li>
                              <b>Innovative(ness)</b><br/>
                              We encourage our staff to look for new, cost-effective ways to meet our customers’ banking needs.
                           </li>
                        </ol>
                    </div>

                     <div class="title">
                        <label>Strategic MVV Goals</label>
                        <div></div>
                     </div>
                     <p>
                       The Strategic Mission, Vision, and Values Goals (MVV Goals) for the St. Vincent Co-operative Bank Ltd:
                     </p>

                     <ol>
                         <li>To practice accountability in everything we do.</li>
                         <li>To practice excellence in everything we do.</li>
                         <li>To embrace teamwork in everything we do.</li>
                         <li>To practice innovative(ness) in everything we do.</li>
                         <li>To provide exceptional service and banking solution to our customers.</li>
                         <li>To have engaged employees.</li>
                         <li>To provide an environment that fosters growth and stability for employees.</li>
                         <li>To positively impact our community.</li>
                         <li>To maximize our stakeholders’ value in a regulated environment.</li>
                         <li>To become the leading financial institution in the Eastern Caribbean by 2030.</li>
                     </ol>
                 </div>
              </div>
              <div class="row" style={{backgroundColor: '#f0f0f0', borderRadius: '10px'}}>
                 <div className="full">
                     <div class="title">
                        <label>Management</label>
                        <div></div>
                     </div>
                     <p>
                       The St. Vincent Cooperative Bank is currently managed by Mr. Andre Cadogan, the bank’s sixth manager. The President and Chairman of the Board of Directors is Mr. Marcus Ballantyne.
                     </p>
                     <b>
                       The Board also includes:
                     </b>
                     <ul>
                        <li>Mr. Marcus Ballantyne – Chairman</li>
                        <li>Mr. Matthew E. A. Ferrari - Deputy Chairman</li>
                        <li>Mr. Albert Porter</li>
                        <li>Mr. Eldric M. James</li>
                        {/* <li>Mrs. Heather Sardine</li> */}
                        <li>Mrs. Zhinga A. Horne-Edwards</li>
                        <li>Mrs. Villette Browne</li>
                        <li>Mr. Brian Glasgow</li>
                        <li>Mr. Ken Hackshaw</li>
                        <li>Mr. Duane Allen</li>
                     </ul>
                     
                     <p>
                       Some employees are also shareholders of the Bank who take pride in their ownership and are further motivated to ensure the Bank’s success.
                     </p>
                 </div>
              </div>
              <div class="row">
                <div>
                     <div class="title">
                        <label>History</label>
                        <div></div>
                     </div>
                     <p>
                        The St. Vincent Co-operative Bank has provided 78 years of quality service to the people of St. Vincent and the Grenadines. The Bank was incorporated on October 18, 1944 and officially opened for business on February 1, 1945.
                     </p>   
                     <p>
                        The Bank was founded by the late Ormond Cyril “O.C.” Forde who also served as its first Manager. “O.C.” was a visionary who saw an opportunity in the 1940’s to service the credit needs of the majority of Vincentians who at that time faced difficulties in obtaining loans and banking services from the international Banks operating in St. Vincent & the Grenadines.  Only a privileged few qualified for such services while a large market existed for the banking needs of the many.  
                     </p>
                     <p>
                        The Bank’s first President was Mr. Alfred G. Hazell who together with “O.C.” were pioneers in developing the Bank. 
                     </p>
                     <p>
                        When “O.C.” retired in 1974, he was succeeded by Mr. Kenneth E. Forde who successfully managed and grew the bank for 34 years until his retirement on October 31, 2007.  Mr. Kenneth E. Forde was succeeded by Mrs. Laverne Velox. Mrs. Velox faithfully served and successfully managed the bank for over 10 years until her retirement on May 31st 2017. She was succeeded by Mrs. Champa Rampersad-Barnes. 
                        Mrs. Champa Rampersad-Barnes was then succeeded by Mr. Albert Porter. Mr. Porter served and successfully managed the bank for over 3 and a half years until his retirement on April 30th 2023. Mr Porter was then succeeded by Mr. Andre Cadogan, who is the current Chief Executive Officer of the bank.
                     </p>
                     <p>
                        From the outset, the Bank’s willingness to open accounts with a penny caused it to be affectionately known as “Penny Bank”.  The Bank has always been a place where anyone can open an account with a small sum of money.  It is a peoples Bank and one which has benefited tremendously from the loyalty of its customers.  This loyalty has passed on from generation to generation thus ensuring the continuity and growth of the Bank.
                     </p>
                     <p>
                        The Bank’s operations have historically been primarily of a Savings and Loans nature.
                     </p>
                </div>
                <div>
                     <div class="title">
                        <label>Chairpersons</label>
                        <div></div>
                     </div>

                     <ul>
                        <li><b>Mr. Alfred G. Hazell:</b> October 1944 – January 1968</li>
                        <li><b>Mr. Valentine D. Archer:</b> February 1968 – May 1974</li>
                        <li><b>Mr. Ormond C. Forde:</b> September 1974 – January 1984</li>
                        <li><b>Mr. William J. Abbott:</b> February 1984 – November 1988</li>
                        <li><b>Mr. O’neill Mc. Intosh:</b> January 1989 – September 1998</li>
                        <li><b>Sir Frederick N. Ballantyne:</b> October 1998 – October 2002</li>
                        <li><b>Mr. L.A. Douglas Williams:</b> October 2002 – September 2011</li>
                        <li><b>Mrs. Magaret H. Hughes-Ferrari:</b> September 2011 – February 2020</li>
                        <li><b>Mr. Samuel Goodluck:</b> March 2020 – July 2023</li>
                        <li><b>Mr. Marcus Ballantyne:</b> July 2023 – Present</li>
                        
                     </ul>

                     <div class="title">
                        <label>Managers</label>
                        <div></div>
                     </div>

                     <ul>
                        <li><b>Mr. Ormond C. Forde:</b> February 1945 – January 1973</li>
                        <li><b>Mr. Kenneth E. Forde:</b> April 1974 – October 2007</li>
                        <li><b>Mrs. H. Laverne Velox:</b> November 2007- May 2017</li>
                        <li><b>Mrs. Champa Rampersad-Barnes:</b> June 2017 – September 2019</li>
                        <li><b>Mr. Albert Porter:</b> November 2019 – April 2023</li>
                        <li><b>Mr. Andre Cadogan:</b> July 2023 – Present</li>
                     </ul>

                     <p>
                       * Acting Manager From February 1973 – March 1974
                     </p>
                </div>
              </div>
            </div>
            <Footer/>
        </div>
    )
}

export default About;