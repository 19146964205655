import React, { useEffect } from 'react';
import '../../style/sustainable_development.css';

import AOS from 'aos';
import 'aos/dist/aos.css';

import PageTop from '../../components/pageTop';
import Header from '../../components/header.js';
import Footer from '../../components/footer';


import UNGoal15_Img from '../../assets/img/UN_Goal_15.png';
import UNGoal9_Img from '../../assets/img/UN_Goal_9.png';

import hands_holding_dirt_img from '../../assets/img/hand_holding_dirt.png';
import KingstownImg from '../../assets/img/Kingstown.jpg';

import MobileBanking from '../../assets/artwork/mobile_banking.jpg';

import iosImg from '../../assets/download_ios.png';
import androidImg from '../../assets/download_android.png';


import la_soufriere from '../../assets/img/la soufriere.jpg';

import bulb_img from '../../assets/img/bulb.png';

export default function SustainableDevelopment(){

    useEffect(() => {
        // AOS.init({ duration: 1000 });
        // window.scrollTo(0, 0);
     }, []);
    return(
        <>
            <img class="SD-Cont-Background" src={KingstownImg}/>
            <img class="SD-Cont-Background volcano" src={la_soufriere}/>

            <div class="SD-Cont">
                <Header class="SD-header"/>
                <div class="main-text">
                    <span>We are going</span><br/>
                    <label>Green!</label>
                    <p>preserving the environment together</p>
                    <img class="UNGoals" src={UNGoal15_Img}/>
                    <img class="UNGoals" src={UNGoal9_Img}/>
                    <h3>Our mission at the Penny Bank is to play our part in environmental conservation.</h3>
                    <b>Let us play that part together!</b>
                </div>

                <img class="hands_holding_dirt" src={hands_holding_dirt_img}/>
                {/* <img class="SD-Cont-Background" src={la_soufriere}/> */}
                {/* <img class="bulb-img" src={bulb_img}/> */}

                <div class="Your-Part">
                     <div class="main-message">
                        <label>Your Part</label><br/>
                        <span>What can you do to help?</span>
                     </div>

                     <br/><br/>
                     

                     <div class="mobile-banking">
                       <img class="mobile-img" src={MobileBanking}/><br/>
                       <div class="download-now">
                        <br/>
                        <h3>Sign Up For Mobile Banking</h3>
                        <div class="btns">
                            <a href="https://play.google.com/store/apps/details?id=com.mobilearth.svcb.mobibanking" target="_blank" color="primary">
                              <img src={androidImg} />
                            </a>
                            
                            <a href="https://apps.apple.com/vc/app/svcb/id1514230676" target="_blank" color="primary">
                              <img src={iosImg} />
                            </a>
                        </div>
                       </div>
                     </div>

                     <br/><br/>
                </div>
                <Footer/>
            </div>

            
        </>
        
    )
}
