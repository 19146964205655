import React from 'react';
import Button from '@material-ui/core/Button';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import '../style/dialog.css';


import Canival_Closure from '../assets/notices/canival_closure.jpeg'

export default function Alert(){
    const [open, setOpen] = React.useState(true);
    // document.cookie = "Alert"
    return localStorage.getItem('alertViewed') === 'true';


    const handleClickOpen = () => {
        setOpen(true);
    };

    // const hasAlertBeenViewed = () => {
    //     return localStorage.getItem('alertViewed') === 'true';
    // };
    
    // const setAlertAsViewed = () => {
    //     localStorage.setItem('alertViewed', 'true');
    // };

    const handleClose = () => {
        setOpen(false);
    };

    // handleClickOpen()

    return(
        <>
            <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
                {/* <DialogTitle id="form-dialog-title">Loan Calculator</DialogTitle> */}
                <DialogContent>
                    <img style={{width: '100%'}} src={Canival_Closure}/>

                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="secondary">
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}