import React from 'react';
import '../style/about.css';

import Note from '../components/note.js';
import Header from '../components/header.js';
import Footer from '../components/footer';

import PageTop from '../components/pageTop';

export default function FATCA(){
    window.scrollTo(0, 0);
    return(
        <div>
            <Note/>
            <Header/>
            {/* <PageTop/> */}
            <div className="page_title spacer">
               <label className='fancy'>FATCA</label>
            </div>
            <div class="about_body" style={{lineHeight: '40px', marginTop: '-200px'}}>
                <ol>
                    <li>
                        <b>What does the abbreviation FATCA mean?</b><br/>
                        FATCA: Foreign Account Tax Compliance Act
                    </li>
                    <li>
                        <b>What is FATCA?</b><br/>
                        FATCA refers to the Foreign Account Tax Compliance Act. This legislation is part of the Hiring Incentives to Restore Employment Act and was enacted by the United States (“U.S.”) Congress on March 18, 2010. It aims to minimise tax evasion by U.S. persons through the use of offshore accounts.
                    </li>
                    <li>
                        <b>What is the purpose of FATCA?</b><br/>
                        It is the U.S. Government’s attempt to prevent tax evasion by U.S. persons with U.S. sourced income in Foreign Financial Institutions (“FFIs”). It, therefore, stipulates that Foreign Financial Institutions (“FFI”) provide information to the US Internal Revenue Service (“IRS”) on U.S. persons.
                    </li>
                    <li>
                        <b>What is a Foreign Financial Institution (FFI)?</b><br/>
                        Foreign Financial Institutions are institutions that:<br/>
                        <ul>
                            <li>accept deposits in the ordinary course of business (such as a bank);</li>
                            <li>holds financial assets for accountholders;</li>
                            <li>are engaged primarily in the business of investing;</li>
                            <li>are involved in reinvesting, or trading securities, partnership interests, commodities; or any interest in such securities, partnership interest, or commodities,</li>
                        </ul>
                    </li>
                    <li>
                        <b>How do I know whether I am affected?</b><br/>
                        FATCA legislation affects both personal and business accounts that can be classified as “U.S. persons” for U.S. tax purposes. FATCA also affects certain types of businesses which are owned by U.S. persons.<br/>

                        Listed below are some criteria for determining who can be classified as U.S. person.
                        <ul>
                            <li>Someone who has U.S. citizenship, including an individual who has dual citizenship</li>
                            <li>Someone who is a lawful permanent resident of the U.S., that is, a green card holder.</li>
                            <li>A holder of a U.S. passport</li>
                            <li>Someone who was born in the U.S. (even if he/she lives outside of the U.S)</li>
                            <li>Someone who was born in the U.S. and has not relinquished his/her U.S. citizenship</li>
                            <li>An accountholder who uses a U.S. residence address or a U.S. correspondence address</li>
                            <li>Standing instructions to transfer funds to an account maintained in the U.S.</li>
                            <li>Legal persons, such as, U.S. corporations, partnerships and trusts that a court in the U.S. can exercise primary supervision over its administration.</li>
                            <li>Legal persons (corporations, partnerships and trusts) where one or more U.S. persons control 10% or more of the shares in that entity or have the authority to control all substantial decisions.</li>
                        </ul>
                    </li>
                    <li>
                        <b>What is the impact of FATCA?</b><br/>
                        Banks and other financial institutions will be required to report information on accounts held directly or indirectly by U.S. person to the IRS.
                    </li>
                    <li>
                        <b>Is The St Vincent Co-operative Bank Ltd the only bank which is affected by FATCA?</b><br/>
                        No. FATCA impacts all financial institutions which hold accounts for “U.S. persons”.
                    </li>
                    <li>
                        <b>Do you have to be a citizen of the U.S. for your account to be subject to FATCA?</b><br/>
                        No. If anyone who is a signatory to an account satisfies any of the other criteria listed in question 5 that account may be subject to FATCA.
                    </li>
                    <li>
                        <b>Are joint accounts held by a “U.S. person” and a “non U.S. person” subjected to FATCA?</b><br/>
                        Yes. This type of account would be treated as a U.S. account and will be subject to FATCA.
                    </li>
                    <li>
                         <b>Why do financial institutions in the St Vincent and the Grenadines have to comply with FATCA?</b><br/>
                         <p>St Vincent and Grenadines and other countries in the Organisation of Eastern Caribbean States (“OECS”) have signed an inter-governmental agreement (“IGA”) with the U. S. in relation to FATCA. Therefore, the Government of St Vincent and the Grenadines has agreed to provide FATCA related information to the U.S. Government.</p>
                         <p>It is expected that the Government of St Vincent and the Grenadines will pass legislation governing the implementation of FATCA in St Vincent and the Grenadines and relevant financial institutions would have to comply with provisions in this legislation.</p>
                    </li>
                </ol>
                <p>For more information on FATCA, please go to</p>
                <a href="http://www.irs.gov/Businesses/Corporations/Foreign-Account-Tax-Compliance-Act-FATCA" target="_blank">http://www.irs.gov/Businesses/Corporations/Foreign-Account-Tax-Compliance-Act-FATCA</a><br/><br/>
            </div>
            <Footer/>
        </div>
    )
}