import React, { useEffect } from 'react'
import '../../style/christmas_2022.css'
import '../../style/showing.css'

import AOS from 'aos'
import 'aos/dist/aos.css' 

import PageTop from '../../components/pageTop'
import Header from '../../components/header.js'
import Footer from '../../components/footer'

import LittleGirlWithBooks from '../../assets/img/little_girl.png'

import BookImg from '../../assets/img/book.png'

import capImg from '../../assets/img/cap.png'

import loanAmount from '../../assets/Christmas2022/40,000.png'


import ChristmasCheer from '../../assets/Christmas2022/ChristmasCheer.png'
import Ring_in_the from '../../assets/Christmas2022/Ring-in-the.png'
import At_penny_bank_this_year from '../../assets/Christmas2022/At_penny_bank_this_year.png'

export default function Scholarship_Bursary(){

    useEffect(() => {
        AOS.init({ duration: 1000 });
        window.scrollTo(0, 0);
    }, []);

   return(
       <div class="promotions">
        <div class="snowing">
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
        </div>
    
          <Header class="promotions"/>
          <div class="promo">
              {/* <div style={{width: 400, height: 400}}></div> */}
              {/* <img data-aos="fade-up" class="mainImage" src={LittleGirlWithBooks}/> */}
              <img data-aos="fade-up" style={{marginTop: 200}} class="secondTextImage" src={Ring_in_the}/><br/>
              <img data-aos="fade-up" class="textImage" src={ChristmasCheer}/><br/>
              <img data-aos="fade-up" class="textImage" src={At_penny_bank_this_year}/>

              {/* <span data-aos="fade-up" class="span voucherText" style={{textAlign: 'left', fontSize: 30, textTransform: 'none', textAlign: 'center'}}>GET UP TO</span><br/> */}
              <img class="loan-amount" src={loanAmount}/>
              {/* <span data-aos="fade-up" class="span Text40k" style={{textAlign: 'left', textTransform: 'none', textAlign: 'center'}}>$40,000</span><br/> */}
              <br/>
              <span data-aos="fade-up" class="span secondTextLine" style={{textAlign: 'left', textTransform: 'none', textAlign: 'center', textTransform: 'uppercase'}}>Pay for Chritstmas expenses, repair your home</span><br/>
              <span data-aos="fade-up" class="span secondTextLine" style={{textAlign: 'left', textTransform: 'none', textAlign: 'center', textTransform: 'uppercase', marginTop: - 20}}>And Consolidate your debts</span><br/>

              <br/><br/>
              <span data-aos="fade-up" class="span secondTextLineYellow" style={{textAlign: 'left', textTransform: 'none', textAlign: 'center', textTransform: 'uppercase', marginTop: - 20}}>NO Payment Until January 2023 | Up to 5 years to Repay</span><br/>
              <span data-aos="fade-up" class="span secondTextLineYellow" style={{textAlign: 'left', textTransform: 'none', textAlign: 'center', textTransform: 'uppercase', marginTop: - 20}}>Competitive Rates | Weekly Prizes</span><br/>


              <ol class="rules">
                  <li data-aos="fade-left" style={{fontSize: 25}}>Two pieces of valid identification.</li>
                  <li data-aos="fade-left" style={{fontSize: 25}}>Recent Payslip</li>
                  <li data-aos="fade-left" style={{fontSize: 25}}>Job letter</li>
                  <li data-aos="fade-left" style={{fontSize: 25}}>Proof of address (Utility bill no older than three months)</li>
                  <li data-aos="fade-left" style={{fontSize: 25}}>Proof of requirement for funds (Reason for funds)</li>
              </ol>

              <br/>
              <span data-aos="fade-up" class="span" style={{color: '#33ccff', textTransform: 'uppercase'}}>The Penny Bank and so much more</span>
              <span data-aos="fade-up" class="span" style={{color: '#fff', textTransform: 'uppercase', fontSize: '20px'}}>SVCBL Terms and Conditions Apply</span>

          </div>
          <Footer/>
       </div>
   )
} 