import React from 'react';

import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';


import '../../style/about.css';

import Note from '../../components/note.js';
import Header from '../../components/header.js';
import Footer from '../../components/footer';

import PageTop from '../../components/pageTop';

import Slider from '../../components/about_slider';

import Alert from '../../components/alert';

// Images
import RAImage1 from '../../assets/img/news/renaldo_andrews_feature.jpeg';
import RAImage2 from '../../assets/img/news/renaldo_andrews_feature2.jpeg';
import RAImage3 from '../../assets/img/news/renaldo_andrews_feature3.jpeg';



function NewsPage(){
    window.scrollTo(0, 0);

    return(
        <div>
            {/* <Note/> */}
            <Alert/>
            <Header/>
            <PageTop/>
            <div className="page_title">
               <label>News</label>
            </div>
            <div class="about_body" style={{lineHeight: '40px', marginTop: '-200px'}}>

                <Carousel>
                    <div>
                        <img src={RAImage1} />
                    </div>
                    <div>
                        <img src={RAImage2} />
                    </div>
                    <div>
                        <img src={RAImage3} />
                    </div>
                </Carousel>
                <div class="">
                     <div>
                        <div class="title">
                            <label>Ronaldo Andrews Feature</label>
                            <div></div>
                        </div>
                        <p>
                        Former St. Vincent Grammar School Head Boy Ronaldo Andrews will receive additional financial assistance from The St. Vincent Co-operative Bank Ltd. (SVCBL) known as the Penny Bank as he embarks on a sporting career in Jamaica. Ronaldo, who was a Penny Saver at the SVCBL received an athletic scholarship to attend the Kingston College in Jamaica.                         
                        </p>

                        <p>
                        The St. Vincent Co-operative Bank Ltd (SVCBL) policy states that no candidate who is the holder of another scholarship can be awarded a SVCBL scholarship. However, the bank after its encounter with Ronaldo Andrews and consideration of his economic situation and the fact that he will be attending school off island has decided to award Ronaldo sponsorship to assist with any incidentals while in Jamaica.  This is another way the bank seeks to fulfill its corporate duties. 
                        </p>

                        <p>
                        Ronaldo, seventeen, of Murray’s Village was an exemplary student at the St. Vincent Grammar School.  His passion for success is shown through his achievements in track and field, he currently holds the national triple jump and long jump titles in his age category.  His leadership qualities have earned him the head-boy, peer counsellor, prefect, vice-captain of Lopey house, committee member and student counsellor badges. His Christian faith has grounded him, and his mantra is “hard work, dedication and discipline.”  Ronaldo is the seventh of eight children to single parent mother Jacqueline Waterstone. 
                        </p>

                        <p>
                        In Jamaica at the Kingston College, which is an all-boy traditional high school Ronaldo would be actively involve in developing his track and field career.  
                        </p>

                        <p>
                        In an interview Ronaldo stated “Fourth and Fifth form was not easy.  I had to endure because I knew what I wanted to achieve in life and how crucial education is in society today. My transition from form three to form four was a tremendous journey, I had to take my work more seriously and I intended to excel in every aspect of my academics.”   
                        </p>

                        <p>
                        Ronaldo took the time to thank all who assisted him. “ I am really excited for this opportunity that is given to me. I am beyond grateful that The St. Vincent Co-operative Bank is going to sponsor me on my journey to Kingston College.  I intend to do incredible things in terms of furthering my jumping skills in order to perform at my best in breaking national records in my athletic journey and hopefully going on to receiving more scholarships that can take me further in life.  I believe with God anything is possible.” 
                        </p>

                        <p>
                        While Ronaldo was committed to academics he was equally committed to sports.  “During my early secondary school years, I wasn't sure if I wanted to take athletics seriously. However, I participated in various activities pertaining to athletics for fun. At that time, I was more focused on running sprints specifically 100m and 200m and I was an amateur when it came to doing jumps. Nevertheless, I was still interested in the jumping events,” Ronaldo said. 
                        </p>

                        <p>
                        “In form 4, I competed in my school heats and I fouled my three jumps in the long jump. I was so devastated. It was just a normal heat for everyone, but it was a significant day for me. I refused to compete in or take part in the triple jump at that time because I was not knowledgeable of the technique and different phases, but this did not deter me. Mr. Morgan my coach invited me to attend a seminar that was based on teaching people how to do the triple jump, that is when I made up my mind to give it a try and that is when my interest grew,” Ronaldo added. 
                        </p>

                        <p>
                        He continued his training and successfully participated in fixtures in the national championships and is grateful to his coach and other well-wishers who believed in him. 
                        </p>

                        <p>
                        “Mr. Morgan was already in contact with a coach in Jamaica because he saw that I could reach beyond the stars if I were to continue. I earned the scholarship, and I am grateful for God and Mr. Morgan during this tenure. I know that I am going to produce great results with hard work because of my determination,” Ronaldo said. 
                        </p>

                        <p>
                        The Board, the Management, and Staff of the SVCBL extends best wishes and continued success to Ronaldo Andrews as he embarks on this new chapter. 
                        </p>
                     </div>         
                </div>

            </div>
            <Footer/>
        </div>
    )
}

export default NewsPage;