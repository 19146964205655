import React, { useEffect } from 'react';
import '../style/index.css';
import '../style/typography.css';

import '../style/advert.css';
import '../style/promo.css';

import AOS from 'aos';
import 'aos/dist/aos.css';

import Note from '../components/note.js';
import Header from '../components/header.js';
import Login from '../components/login.js';
import Footer from '../components/footer.js';
import News from '../components/news';

// COMPONENTS
import Button from '@material-ui/core/Button';

// ICONS
import NavigateNextIcon from '@material-ui/icons/NavigateNext';

import SavingsIcon from '../assets/icons/savings.svg';
import FixedDepositIcon from '../assets/icons/fixedDeposit.svg';
import PiggyBankIcon from '../assets/icons/piggyBank.svg';

import ResidentialLoans from '../assets/icons/residential_loans.svg';
import CommercialLoans from '../assets/icons/commercial_loans.svg';
import OtherLoans from '../assets/icons/other_loans.svg';

import CardIcon from '../assets/icons/card.svg';

import MobileIcon from '../assets/icons/mobile.svg';

import PhoneIcon from '../assets/icons/phone.svg';

import CardImg from '../assets/card.png';

import image from '../assets/slider/image1.jpeg';

import MobileBanking from '../assets/artwork/mobile_banking.jpg';
import ATMServices from '../assets/artwork/atm_services.jpg';
import Scholarship_Artwork from '../assets/artwork/scholarship_bursaries.png';
import BackToSchool_Artwork from '../assets/artwork/back_to_school.png';

import Scholarship_bursaries from '../assets/artwork/scholarship_bursaries_2023.png';

import Back_To_School_Loan from '../assets/artwork/back_to_school_loan_2023.png'
import dreamland from '../assets/artwork/dreamland.png'
import holly_jolly_christmas_2023 from '../assets/artwork/holly_jolly_christmas_2023.png'

import iosImg from '../assets/download_ios.png';
import androidImg from '../assets/download_android.png';

import { Link } from 'react-router-dom';

import Alert from '../components/alert'

// import ReactGA from 'react-ga';
// ReactGA.initialize('UA-000000-01');
// ReactGA.pageview(window.location.pathname + window.location.search);

export default function Index() {
   useEffect(() => {
      AOS.init({ duration: 1000 });
      window.scrollTo(0, 0);
   }, []);

   return(
       <div class="body">
            {/* <Note /> */}
            <Header />
            <Alert/>
            <div class="main">
               <div class="container">
                  <div class="content">
                     <div class="wrap" >
                        <div class="container-spacing"></div>
                        {/* <img src={MobileIcon}/> */}

                        {/* <label class="h-3 fancy" style={{fontWeight: 800, fontSize: 60, textTransform: 'uppercase'}}>Banking with us <i style={{padding: '1px', backgroundColor: '#105ba5', color: '#fff', borderRadius: 10}}>is EASY</i></label> */}
                        <label class="h-3 fancy" style={{fontWeight: 800, fontSize: 45, textTransform: 'uppercase'}}>Banking with us is EASY</label>

                        <br/>
                        {/* <span class="body-1" style={{fontSize: 20, color: '#006699', fontWeight: 'bold'}}>The penny bank and <b style={{color: '#af743d', fontWeight: 800, fontSize: 30}}>SO MUCH MORE</b></span><br/><br/>
                        <br/><br/> */}
                        <span class="body-1" style={{fontSize: 30, color: '#006699', fontWeight: 'bold'}}>Sign up to get started</span><br/><br/>
                        <div class="row">
                           <Link to="/apply/loans" style={{backgroundColor: '#105ba5'}} className="link">
                              Apply For A Loan
                           </Link>

                           <Link to="/apply/savings" className="link" style={{marginLeft: '8px', backgroundColor: '#af743d'}}>
                              Start Saving
                           </Link>

                           {/* <Link to="/apply/onlineBanking" style={{backgroundColor: '#af743d',marginLeft: '8px'}} className="link">
                              Online Banking
                           </Link> */}
                           
                        </div>
                        
                     </div>
                     
                  </div>
                  <div class="image"></div>
               </div>
            </div>
            {/* <Login /> */}

            <br/><br/>

            {/* <a href="/apply/loans" target="_blank" class="promo-list">

               <div class="top_advert" data-aos="fade-up">
                  <img src={holly_jolly_christmas_2023}/>
               </div>
            </a> */}

            <br/><br/>
            
            
            {/* <div class="content-spacing"></div> */}
            <div class="services">
               <div data-aos="fade-right" class="service">
                  <label>Savings Services</label>
                  <ul>
                     <Link data-aos="fade-down-right" to="/services/savings#regular">
                        <li>
                           <img src={SavingsIcon} class="blue-filter"/>
                           <span>Regular Savings</span>
                           <NavigateNextIcon/>
                        </li>
                     </Link>
                     <Link data-aos="fade-down-right" to="/services/savings#fixed">
                        <li>
                           <img src={FixedDepositIcon} class="blue-filter"/>
                           <span>Fixed Deposit</span>
                           <NavigateNextIcon/>
                        </li>
                     </Link>
                     <Link data-aos="fade-down-right" to="/services/savings#penny">
                        <li>
                           <img src={PiggyBankIcon} class="blue-filter"/>
                           <span>Penny Savers</span>
                           <NavigateNextIcon/>
                        </li>
                     </Link>
                  </ul>
               </div>
               <div data-aos="fade-left" class="service">
                  <label>Loans Services</label>
                  <ul>
                    <Link data-aos="fade-down-left" to="/services/loans#residential">
                        <li>
                           <img src={ResidentialLoans} class="brown-filter"/>
                           <span>Residential Loans</span>
                           <NavigateNextIcon/>
                        </li>
                     </Link>
                     <Link data-aos="fade-down-left" to="/services/loans#commercial">
                        <li>
                           <img src={CommercialLoans} class="brown-filter"/>
                           <span>Commercial Loans</span>
                           <NavigateNextIcon/>
                        </li>
                     </Link>
                     <Link data-aos="fade-down-left" to="/services/loans">
                        <li>
                           <img src={OtherLoans} class="brown-filter"/>
                           <span>So much more</span>
                           <NavigateNextIcon/>
                        </li>
                     </Link>

                  </ul>
               </div>
            </div>
            <br/><br/>
            <div class="atm">
              <div data-aos="fade-up" class="image">
                <img src={ATMServices}/>
              </div>
              <div data-aos="zoom-in" class="content">
                 {/* <div class="icon">
                    <img src={CardIcon}/>
                 </div><br/> */}
                 <br/><br/>
                 <span class="span">Why Wait in Line?</span>
                 <label class="label">ATM Cards Available</label>
                 <p>
                    ATM withdrawals are facilitated in partnership with Bank of SVG Ltd. Customers are able to access their funds at any BOSVG owned ATM throughout St. Vincent and the Grenadines.
                 </p>
                 <Link data-aos="zoom-in-left" to="/apply/atm" style={{width: '150px'}} className="link">
                   Get Onboard
                 </Link>
              </div>
            </div>
            <div class="mobile">
                   <div data-aos="zoom-in" class="content">
                     {/* <div class="icon">
                       <img src={PhoneIcon}/>
                     </div><br/> */}
                     <br/><br/>
                     {/* <span class="span">Accessibility?</span> */}
                     <label class="label">Convenience at your finger tips</label>
                     <p>
                        Sign up for online banking and take control of your banking experience
                     </p>
                     <div class="btns">
                        <a data-aos="zoom-in-up" href="https://play.google.com/store/apps/details?id=com.mobilearth.svcb.mobibanking" target="_blank" color="primary">
                          <img src={androidImg} />
                        </a>
                        
                        <a data-aos="zoom-in-up" href="https://apps.apple.com/vc/app/svcb/id1514230676" target="_blank" color="primary">
                          <img src={iosImg} />
                        </a>
                     </div>
                   </div>
                   <div data-aos="fade-up" class="image">
                      <img src={MobileBanking}/>
                   </div>
            </div>

            {/* <br/><br/>
            <div class="atm">
              <div data-aos="fade-up" class="image">
                <img src={Back_To_School_Loan}/>
              </div>
              <div data-aos="zoom-in" class="content">
                 
                 <br/><br/>
                 <span class="span" style={{fontSize: 30, color: '#154396'}}>BACK TO SCHOOL</span>
                 <label class="label" style={{fontSize: 55, marginTop: 10, lineHeight: 1, color: '#a50735'}}>LOANS</label>
                 <p style={{fontSize: 30}}>
                     August 1st to September 30th, 2023
                 </p>
                 <Link data-aos="zoom-in-left" to="/apply/loans" style={{width: '150px'}} className="link">
                   Apply Now
                 </Link>
              </div>
            </div> */}

            <News/>
            <Footer/>
       </div>
   )
}