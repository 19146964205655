import React, { useEffect } from 'react';
import '../style/services.css';

import Button from '@material-ui/core/Button';

import AOS from 'aos';
import 'aos/dist/aos.css';

import Note from '../components/note.js';
import Header from '../components/header.js';
import Footer from '../components/footer';

import Slider from '../components/about_slider';

import ATMSignupAd from '../assets/artwork/ATM_signup_ad.jpg';

import ProjectsFundedBy from '../assets/img/project_funded_by.jpg';

import RealEstateIcon from '../assets/icons/house.svg';

import { Link } from 'react-router-dom';


export default function OtherServices(){
    window.scrollTo(0, 0);

    useEffect(() => {
        AOS.init({ duration: 1000 });
     }, []);

    return(
        <div>
            {/* <Note/> */}
            <Header/>
            <Slider/>
            <div className="page_title">
               <label>Other Services</label>
            </div>
            <div class="service_body">
                <div class="service">
                   <div data-aos="fade-right" class="image">
                       <img src={ProjectsFundedBy}/>
                   </div>
                   <div data-aos="fade-left" class="content">
                        <div class="icon">
                            <img src={RealEstateIcon}/>
                        </div><br/>
                        <label class="label">Real Estate Agent & Agency</label><br/><br/>

                        <b>Liberty Properties</b><br/>
                        <p>Mr Simon Kamara <br/> Prospect</p>
                        <a href="https://www.libertyproperties-svg.com/">Website</a>
                        <ul>
                            <li>493-7777</li>
                            <li>528-1616</li>
                            <li>456-9005</li>
                        </ul>

                        <b>Horizon Investments</b><br/>
                        <p>Frenches Gate, Kingstown</p>

                        <ul>
                            <li>457-2110</li>
                            <li>456-5118</li>
                            <li>532-9339</li>
                        </ul>

                        <b>A.I Real Estate</b><br/>
                        <p>Mr Don Providence, <br/>Grenville Street, Kingstown</p>

                        <ul>
                            <li>457-2087</li>
                            <li>457-2893</li>
                            <li>493-9431</li>
                        </ul>
                   </div>
                </div>
            </div>
            <Footer/>
        </div>
    )
}
