import React, { useEffect } from 'react';
import '../style/services.css';

import Button from '@material-ui/core/Button';

import AOS from 'aos';
import 'aos/dist/aos.css';

import Note from '../components/note.js';
import Header from '../components/header.js';
import Footer from '../components/footer';

import Slider from '../components/about_slider';

import cardImg from '../assets/card.png';

import infoIcon from '../assets/icons/info.svg';

import img1 from '../assets/slider/image2.jpeg';

import { Link } from 'react-router-dom';

import PageTop from '../components/pageTop';

import ResidentialLoans from '../assets/img/residential.jpg';

import CommercialLoans from '../assets/img/commercial_loans.jpg';

import AutoLoans from '../assets/img/auto_loans.jpg';

import LandLoans from '../assets/img/land_loans.jpg';

export default function Loans(){
    window.scrollTo(0, 0);

    useEffect(() => {
        AOS.init({ duration: 1000 });
        window.scrollTo(0, 0);
        const hash = window.location.hash.substr(1);
        try{
            const yOffset = -120; 
            const element = document.getElementById(hash);
            const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;

            window.scrollTo({top: y, behavior: 'smooth'});
        }
        catch(e){}
     }, []);

    return(
        <div>
            {/* <Note/> */}
            <Header/>
            <PageTop/>
            <div className="page_title">
               <label className='fancy'>Loan Services</label>
               
               <br/><br/>
                <Link to="/apply/loans" className="link">
                    Apply Now
                </Link>
            </div>
            <br/>
            <div class="service_body" style={{marginTop: '-150px'}}>
                <div class="service" id="residential">
                   <div data-aos="fade-right" class="image">
                       <img src={ResidentialLoans}/>
                   </div>
                   <div data-aos="fade-left" class="content">
                        <div class="icon">
                            <img src={infoIcon}/>
                        </div><br/>
                        <label class="label">Residential Loans</label>

                        <p>
                           If you thought owning your own home was difficult to achieve, then come to The St. Vincent Co-operative Bank and we will show you how you can realize this goal very easily.
                        </p>
                        <p>
                           Need to do an extension or renovation to your home, to better accommodate your changing needs or growing family. The St. Vincent Co-operative Bank can facilitate you.
                        </p>

                        <b>General Requirements:</b>
                        <ul>
                            <li>Deed of Conveyance</li>
                            <li>Approved Plan</li>
                            <li>Detailed Estimate / Sale Agreement</li>
                            <li>Job Letter / Salary Slip (must not be in excess of 2 months old)</li>
                            <li>Proof of Address e.g. a utility bill, a credit card Statement, an existing Bank account statement from another financial institution, or hire purchase receipt bearing the address. (These must not be in excess of 2 months old)</li>
                            <li>Two valid pieces of identification (e.g. National ID Card, Passport or Drivers License)</li>
                        </ul>
                        <p>
                          In addition to the above, overseas customers are required to provide three (3) pieces of photo identification, Bank Statements, Income Tax Returns (last 2 yrs), Credit Bureau report, Bank reference letter. All documents from overseas customers must be notarized by a Notary Public.
                        </p>

                        
                   </div>
                </div>

                <div class="service left" id="land">
                   <div data-aos="fade-right" class="content">
                        <div class="icon">
                            <img src={infoIcon}/>
                        </div><br/>
                        <label class="label">Land Loans</label>

                        <p>
                            The St. Vincent Co-operative Bank Limited offers mortgage loans for purchasing land. Our interest rates on loans are highly competitive and our application process guarantees a hassle-free experience with unmatched personalized service.
                        </p>
                        <b>General Requirements:</b>
                        <ul>
                            <li>Deed of Conveyance</li>
                            <li>Sale agreement</li>
                            <li>Survey Plan</li>
                            <li>Job Letter / Salary Slip (must not be in excess of 2 months old)</li>
                            <li>Proof of Address e.g a uutility bill, a credit card Statement, an existing Bank account statement from another financial institution, or hire purchase receipt bearing the address. (These must not be in excess of 2 months old)</li>
                            <li>Two pieces of identification (e.g. National ID Card, Passport or Drivers License)</li>
                        </ul>
                        <p>
                           In addition to the above, overseas customers are required to provide three (3) pieces of photo identification, Bank statements, Income Tax Returns (last 2 yrs), Credit Bureau report, Bank reference letter. All documents from overseas customers must be notarized by a Notary Public.
                        </p>
                        <br/>
                        {/* <Link to="/apply/savings" className="link">
                            Book Appointment
                        </Link> */}
                   </div>
                   <div data-aos="fade-left" class="image">
                       <img src={LandLoans}/>
                   </div>
                </div>

                <div class="service" id="commercial">
                   <div data-aos="fade-right" class="image">
                       <img src={CommercialLoans}/>
                   </div>
                   <div data-aos="fade-left" class="content">
                        <div class="icon">
                            <img src={infoIcon}/>
                        </div><br/>
                        <label class="label">Commercial Loans</label>

                        <p>
                          You’ve found the right property, but how do you find the right loan to buy it?  The St. Vincent Co-operative Bank can walk you through and assist with your real estate loans.
                        </p>

                        <b>General Requirements:</b>
                        <ul>
                            <li>Business Plan</li>
                            <li>Audited Financial Statements (2 years)</li>
                            <li>Bank Statements</li>
                            <li>Corporate documents</li>
                            <li>Trade Registration Documents</li>
                            <li>Deed of Conveyance</li>
                            <li>Survey Plan</li>
                            <li>Approved Plan (if applicable)</li>
                            <li>Detailed Estimate / Sale Agreement</li>
                            <li>Two valid pieces of identification for directors (e.g. National ID Card, Passport or Drivers License)</li>
                        </ul>
                        <br/>
                        {/* <Link to="/apply/savings" className="link">
                            Book Appointment
                        </Link> */}
                   </div>
                </div>

                <div class="service left" id="auto">
                   <div data-aos="fade-right" class="content">
                        <div class="icon">
                            <img src={infoIcon}/>
                        </div><br/>
                        <label class="label">Auto Loans</label>

                        <p>
                          SVCB offers new and used vehicle loans to customers at the best rates available in SVG. We pride ourselves on our swift decision-making and loan approval could be obtained in as few as two days.
                        </p>
                        <b>General Requirements:</b>
                        <ul>
                            <li>Invoice, pro-forma invoice / sale agreement</li>
                            <li>Valuation Report*</li>
                            <li>Registration Certificate</li>
                            <li>Maximum term of 60 months for used vehicles</li>
                            <li>Maximum term of 84 months for new vehicles</li>
                            <li>Proof of Address e.g a uutility bill, a credit card Statement, an existing Bank account statement from another financial institution, or hire purchase receipt bearing the address. (These must not be in excess of 2 months old)</li>
                            <li>Job Letter / Salary Slip (must not be in excess of 2 months old)</li>
                            <li>Two valid pieces of identification (e.g. National ID Card, Passport or Drivers License)</li>
                        </ul>
                        <p>
                          *Valuations reports must come from Dennis Joyette or Ashford Cole who are SVCB’s approved vehicle valuators.                              
                        </p>
                        <br/>
                        {/* <Link to="/apply/savings" className="link">
                            Book Appointment
                        </Link> */}
                   </div>
                   <div data-aos="fade-left" class="image">
                       <img src={AutoLoans}/>
                   </div>
                </div>

                <div class="service" id="personal">
                   <div data-aos="fade-right" class="image">
                       <img src={img1}/>
                   </div>
                   <div data-aos="fade-left" class="content">
                        <div class="icon">
                            <img src={infoIcon}/>
                        </div><br/>
                        <label class="label">Personal Loans</label>

                        <p>
                            Put your money where your dreams are. Loans for the life you live. There are a host of reasons you may want access to a loan. A consumer loan from The St. Vincent Co-operative Bank can help put your money to work for you right now.
                        </p>

                        <b>General Requirements:</b>
                        <ul>
                            <li>Purpose of loan</li>
                            <li>Invoices (goods purchases)</li>
                            <li>Travel Itinerary</li>
                            <li>Job Letter / Salary Slip (must not be in excess of 2 months old)</li>
                            <li>Two valid pieces of identification (e.g. National ID Card, Passport or Drivers License)</li>
                            <li>Proof of Address e.g a utility bill, a credit card Statement, an existing Bank account statement from another financial institution, or hire purchase receipt bearing the address. (These must not be in excess of 2 months old)</li>
                        </ul>
                        <br/>
                        {/* <Link to="/apply/savings" className="link">
                            Book Appointment
                        </Link> */}
                   </div>
                </div>
             </div>
            <Footer/>
        </div>
    )
}
