import React from 'react';
import { Switch, Route } from 'react-router-dom';

import Index from '../pages/index';
import About from '../pages/about';
import Privacy from '../pages/privacy';

import Contact from '../pages/contact';

import FATCA from '../pages/fatca';
import Guidelines from '../pages/guidelines';

// Services
import ATM from '../pages/atm';
import Savings from '../pages/savings';
import Loans from '../pages/loans';
import OtherServices from '../pages/otherService';
import NotFound from '../pages/notFound';

import Success from '../pages/success';

// online applications
import SavingsApp from '../application/savings';
import ATMApp from '../application/atm';
import OnlineBanking from '../application/onlineBanking';
import LoansApp from '../application/loan';

// Planned Promotions
// import Scholarship_Bursary from '../pages/scholarship'; 2021 Promotion
import Scholarship_Bursary from '../pages/marketing/scholarship-bursaries';
import Back_to_School from '../pages/marketing/back-to-school-2022';

import Sustainable_Development from '../pages/marketing/sustainable-development';
import FeelTheMagic from '../pages/marketing/feel-the-magic';

import Christmas2022 from '../pages/marketing/christmas-2022';

import Download_Mobile from '../pages/download_app';

// News articles

import Renaldo_andrews_feature from '../pages/news/renaldo_andrew_feature';
import Children_fun_day from '../pages/news/children_fun_day';
import PlayazYouthAcademy from '../pages/news/playaz_youth_academy';

export default function Routes(){
    return(
        <Switch>
            <Route path="/" exact component={Index}/>
            <Route path="/about" component={About}/>
            <Route path="/contact" component={Contact}/>
            <Route path="/services/atm" component={ATM}/>
            <Route path="/services/savings" component={Savings}/>
            <Route path="/services/loans" component={Loans}/>
            <Route path="/services/other" component={OtherServices}/>

            <Route path="/fatca" component={FATCA}/>
            <Route path="/guidelines" component={Guidelines}/>
            <Route path="/policies/privacy" component={Privacy}/>

            <Route path="/loans" component={Loans}/>

            <Route path="/apply/savings" component={SavingsApp}/>
            <Route path="/apply/success" component={Success}/>

            {/* <Route path="/apply/savings" component={SavingsApp}/>  */}
            {/* <Route path="/beta/apply/savings" component={SavingsApp}/>  */}

            <Route path="/apply/success" component={Success}/>

            <Route path="/apply/atm" component={ATMApp}/>
            <Route path="/apply/onlinebanking" component={OnlineBanking}/>
            <Route path="/apply/loans" component={LoansApp}/>

            {/* <Route path="/beta/apply/atm" component={ATMApp}/>
            <Route path="/beta/apply/onlinebanking" component={OnlineBanking}/> */}

            <Route path="/promotions/scholarship_bursary" component={Scholarship_Bursary}/>
            <Route path="/sustainable-development" component={Sustainable_Development}/>
            <Route path="/promo/feel-the-magic" component={FeelTheMagic}/>
            <Route path='/mobile/download' component={Download_Mobile}/>
            <Route path="/promotions/back-to-school" component={Back_to_School}/>
            <Route path="/promotion/Christmas2022" component={Christmas2022}/>


            <Route path='/news/Ronaldo_Andrews' component={Renaldo_andrews_feature}/>
            <Route path='/news/Children_Fun_Day' component={Children_fun_day}/>
            <Route path='/news/Playaz-Youth-Academy' component={PlayazYouthAcademy}/>

            <Route component={NotFound}/>
        </Switch>
    )
}