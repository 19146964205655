import React from 'react';

import logo from '../SVCB_without_slogan.svg';
import '../style/header.css';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';

import IconButton from '@material-ui/core/IconButton';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';

import MenuIcon from '@material-ui/icons/Menu';

import { withStyles } from '@material-ui/core/styles';

import SearchIcon from '@material-ui/icons/Search';
import CloseIcon from '@material-ui/icons/Close';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import InfoIcon from '@material-ui/icons/Info';
import PhoneIcon from '@material-ui/icons/Phone';

import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';

import savingImg from '../assets/slider/image1.jpeg';
import loanImg from '../assets/residental_loans_small.jpg';
import atmImg from '../assets/artwork/atm_short.jpg';

import Onlinebanking from '../assets/artwork/mobile_banking_short.jpg';


import logoImg from '../SVCB_without_slogan.svg';

import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import MailIcon from '@material-ui/icons/Mail';

import MoreHorizIcon from '@material-ui/icons/MoreHoriz';

import { Link } from 'react-router-dom';

function Header(props){
    const toggleDrawer = (anchor, open) => (event) => {
      if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
        return;
      }

      setState({ ...state, [anchor]: open });
    };

    const [state, setState] = React.useState({
        checkedA: true,
        checkedB: true,
        checkedC: true,
      });
      
    const handleChange = (event) => {
     setState({ ...state, [event.target.name]: event.target.checked });
    };

    const [searchHeight, setSearchHeight] = React.useState(0);
    const [startHeight, setStartHeight] = React.useState(0);
    const [psHeight, setPsHeight] = React.useState(0);

    const [searchIcon, setSearchIcon] = React.useState(<SearchIcon />);
    const [startIcon, setStartIcon] = React.useState(<KeyboardArrowDownIcon />);
    const [psIcon, setPsIcon] = React.useState(<KeyboardArrowDownIcon/>);
    
    const showSearch = () => {
      if(searchHeight == 0){
        setSearchHeight(100);
        setSearchIcon(<CloseIcon/>);
      }else{
        setSearchHeight(0);
        setSearchIcon(<SearchIcon/>);
      }
    }

    const showStart = () => {
      if(startHeight == 0){
        setStartHeight(150);
        setStartIcon(<CloseIcon/>);

        setPsHeight(0);
        setPsIcon(<KeyboardArrowDownIcon/>);
      }else{
        setStartHeight(0);
        setStartIcon(<KeyboardArrowDownIcon/>);
      }
    }

    const showPs = () => {
      if(psHeight == 0){
        setPsHeight(200);
        setPsIcon(<CloseIcon/>);

        setStartHeight(0);
        setStartIcon(<KeyboardArrowDownIcon/>);
      }else{
        setPsHeight(0);
        setPsIcon(<KeyboardArrowDownIcon/>);
      }
    }

    return(
        <>
          <header className={props.class+" header"} >
              <div class="wrap">
                  <Link class="branding" to="/">
                      <img src={logo}/>
                  </Link>
                  <div class="links">
                    <div class="dropable startBtn">
                      <Button className="tab" color="black">
                        Apply Now <ExpandMoreIcon/>
                      </Button>
                    </div>

                      <div class="dropable psBtn">
                        <Button className="tab" color="black">
                          Products & Services <ExpandMoreIcon/>
                        </Button>
                      </div>

                      <div class="drop">
                        <div class="start">
                            <div class="wrap">
                              <Link to="/apply/savings">
                                  <div class="dropItem gs">
                                    <img src={savingImg}/>
                                    <label>Savings</label>
                                  </div>
                              </Link>
                              <Link to="/apply/loans">
                                  <div class="dropItem gs">
                                    <img src={loanImg}/>
                                    <label>Loans</label>
                                  </div>
                              </Link>
                              <Link to="/apply/onlinebanking">
                                  <div class="dropItem gs">
                                    <img src={Onlinebanking}/>
                                    <label>Online Banking</label>
                                  </div>
                              </Link>
                              <Link to="/apply/atm">
                                  <div class="dropItem gs">
                                    <img src={atmImg}/>
                                    <label>ATM Card</label>
                                  </div>
                              </Link>  
                            </div>
                        </div>
                        
                        <div class="products_services">
                            <div class="wrap">
                              <Link to="/services/savings">
                                <div class="dropItem ps">
                                  <img src={savingImg}/>
                                  <label>Savings</label>
                                </div>
                              </Link>
                              <Link to="/services/loans">
                                <div class="dropItem ps">
                                  <img src={loanImg}/>
                                  <label>Loans</label>
                                </div>
                              </Link>
                              <Link to="/services/atm">
                                <div class="dropItem ps">
                                  <img src={atmImg}/>
                                  <label>ATM Services</label>
                                </div>
                              </Link>
                              <Link to="/services/other">
                                <div class="dropItem ps">
                                  <MoreHorizIcon className="ps_icon"/>
                                  <label>Our Partners</label>
                                </div>
                              </Link>
                            </div>
                         </div>
                      </div>

                      <Link to="/about" className="reg" color="black">
                        About Us
                      </Link>

                      <Link to="/contact" className="reg" color="black">
                        Contact Us
                      </Link>
                      <a href="https://secure.svcooperativebank.com/#/" target="blank">
                        <Button className="signInBtn" variant="contained" color="primary">
                          Sign In
                       </Button>
                      </a>
                  </div>
                  
                  <div class="extra">
                      {/* <div>
                        <IconButton className="searchBtn" color="grey" component="span" onClick={() => showSearch()}>
                          {searchIcon}
                        </IconButton>
                      </div> */}
                      <div>
                        <IconButton className="menuBtn" color="black" component="span" onClick={toggleDrawer('left', true)}>
                          <MenuIcon />
                        </IconButton>
                      </div> 
                  </div>
                  {/* <div class="search_drop" style={{height: searchHeight}}>
                      <form noValidate autoComplete="off" className="search_form">
                         <TextField className="input" id="outlined-basic" label="Search for more...." variant="filled" />
                      </form>
                  </div> */}
              </div>
          </header>

          <SwipeableDrawer
            anchor='left'
            open={state['left']}
            className="drawer"
            onClose={toggleDrawer('left', false)}
            onOpen={toggleDrawer('left', true)}>
              <img class="drawer_logo" src={logoImg}/>
            <List>

              <Link to="/" className="mLink">
                <ListItem button>
                  <ListItemIcon><InboxIcon/></ListItemIcon>
                  <ListItemText primary="Home" />
                </ListItem>
              </Link>

              <ListItem button onClick={() => showStart()} className="dropBtn">
                <ListItemIcon>{startIcon}</ListItemIcon>
                <ListItemText primary="Get Started" />
              </ListItem>

              <List className="drawer_drop" style={{height: startHeight}}>
                <Link to="/apply/savings">
                    <ListItem button>
                      <ListItemIcon><NavigateNextIcon/></ListItemIcon>
                      <ListItemText primary="Savings" />
                    </ListItem>
                </Link>
                <Link to="/apply/loans">
                    <ListItem button>
                      <ListItemIcon><NavigateNextIcon/></ListItemIcon>
                      <ListItemText primary="Loans" />
                    </ListItem>
                </Link>
                <Link to="/apply/onlineBanking">
                    <ListItem button>
                      <ListItemIcon><NavigateNextIcon/></ListItemIcon>
                      <ListItemText primary="Online Banking" />
                    </ListItem>
                </Link>
                <Link to="/apply/atm">
                    <ListItem button>
                      <ListItemIcon><NavigateNextIcon/></ListItemIcon>
                      <ListItemText primary="ATM Card" />
                    </ListItem>
                </Link>
              </List>

              <ListItem button onClick={() => showPs()} className="dropBtn">
                <ListItemIcon>{psIcon}</ListItemIcon>
                <ListItemText primary="Products & Services" />
              </ListItem>

              <List className="drawer_drop" style={{height: psHeight}}>
                <Link to="/services/savings">
                    <ListItem button>
                      <ListItemIcon><NavigateNextIcon/></ListItemIcon>
                      <ListItemText primary="Savings" />
                    </ListItem>
                </Link>
                <Link to="/services/loans">
                    <ListItem button>
                      <ListItemIcon><NavigateNextIcon/></ListItemIcon>
                      <ListItemText primary="Loans" />
                    </ListItem>
                </Link>
                <Link to="/services/atm">
                    <ListItem button>
                      <ListItemIcon><NavigateNextIcon/></ListItemIcon>
                      <ListItemText primary="ATM Services" />
                    </ListItem>
                </Link>
                <Link to="/services/other">
                    <ListItem button>
                      <ListItemIcon><NavigateNextIcon/></ListItemIcon>
                      <ListItemText primary="Our Partners" />
                    </ListItem>
                </Link>
              </List>
              
              <Link to="/about" className="mLink">
                <ListItem button>
                  <ListItemIcon><InfoIcon/></ListItemIcon>
                  <ListItemText primary="About Us" />
                </ListItem>
              </Link>

              <Link to="/contact" className="mLink">
                  <ListItem button>
                    <ListItemIcon><PhoneIcon/></ListItemIcon>
                    <ListItemText primary="Contact Us" />
                  </ListItem>
              </Link>
            </List>
          </SwipeableDrawer>
        </>
    )
}

export default Header;